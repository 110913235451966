import React, { useState, useEffect } from 'react'
import AdiTable from 'components/AdiTable'
import Loading from 'components/Loading'
import { ActionButton, DisplayEmptyText, FlexWrapper } from 'statics/styles/StyledComponents'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import Input from 'components/Input'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import { TaskAPI } from '../../apis'
import { message } from 'antd'
import { TABLE_CELL_CONFIG, TABLE_ACTION_WIDTH, DATE_TIME_FORMAT } from 'utilities/CONSTANTS'
import { CreateCompany } from '..'
import { BusinessType } from '../../statics/types'
import dueTask from '../../statics/images/DueTask.svg'
import moment from 'moment'
import { checkWindowSelectedText, includeColumn } from '../../utilities/functions'
import { formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions'
import { useAuthStore } from 'zustand-stores'
import { AuthStore } from 'zustand-stores/useAuthStore'


type Props = {
  data: any,
  actions: { convert: any, edit: any, delete: any },
  onRowClick: Function;
  selectedTableColumns: any[]
  isLoading: boolean
}

const DueTaskTable = (props: Props) => {
  const { data, actions, onRowClick, isLoading } = props
  const isCrmReadonly = useAuthStore((state: AuthStore) => state.moduleAccess.crm.isReadOnly)
  const [tableData, setTableData] = useState<any[]>([...data])


  const fixedColumns = [
    {
      title: formatTitle('Task Title'),
      dataIndex: 'subject',
      key: 'subject',
      ...TABLE_CELL_CONFIG,
      sorter: (a: any, b: any) => a.subject?.localeCompare(b.subject),
      render: initialCellValue
    },
    {
      title: formatTitle('Assigned to'),
      dataIndex: 'assigned_to_name',
      key: 'assigned_to_name',
      ...TABLE_CELL_CONFIG,
      sorter: (a: any, b: any) => a.assigned_to_name?.localeCompare(b.assigned_to_name),
      render: initialCellValue,
    },
    {
      title: formatTitle('Associated Company'),
      dataIndex: 'business_ID',
      key: 'business_ID',
      ...TABLE_CELL_CONFIG,
      sorter: (a: any, b: any) => a.business_ID.bus_name?.localeCompare(b.business_ID.bus_name),
      render: (text: any) => <p>{text.bus_name}</p>
    },
  ]

  const actionColumn = isCrmReadonly ? [] : [
    // {
    //   title: formatTitle('Actions'),
    //   dataIndex: 'actions',
    //   key: 'actions',
    //   fixed: 'right',
    //   width: 150,
    //   align: 'center',
    //   render: (text: any, record: any, index: number) => (
    //     <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'center' }}>
    //       <ActionButton
    //         title=""
    //         onClick={() => actions.convert(record)}
    //       >
    //         <img style={{ marginTop: '-5px' }} src={dueTask} />
    //       </ActionButton>
    //       <ActionButton
    //         title="Edit"
    //         onClick={(e) => {
    //           e.stopPropagation()
    //           e.preventDefault()
    //           actions.edit(record)
    //         }}
    //       >
    //         <EditOutlined style={{ fontSize: '15px' }} />
    //       </ActionButton>
    //       <ActionButton
    //         title="Delete"
    //         onClick={(e) => {
    //           e.stopPropagation()
    //           actions.delete(record)
    //         }}
    //       >
    //         <DeleteOutlined style={{ fontSize: '15px' }} />
    //       </ActionButton>
    //     </div>
    //   ),
    // },
    {
      title: formatTitle('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (text: any, record: any) => {
        const items = [
          {
            label: 'Convert',
            icon: <img src={dueTask} alt="Convert" />,
            onClick: () => actions.convert(record),
          },
          {
            label: 'Edit',
            icon: <EditOutlined />,
            onClick: () => actions.edit(record),
          },
          {
            label: 'Delete',
            icon: <DeleteOutlined />,
            onClick: () => actions.delete(record),
          },
        ];

        return <AdiTable.ActionDropdown menuItems={items} />;
      },
    },
  ]

  const flexibleColumns = [

    {
      title: formatTitle('Task Type'),
      dataIndex: 'task_type',
      key: 'task_type',
      ...TABLE_CELL_CONFIG,
      filters: [
        { text: 'Message', value: 'Message' },
        { text: 'Call', value: 'Call' },
        { text: 'Email', value: 'Email' },
        { text: 'Meeting', value: 'Meeting' },
      ],
      onFilter: (value: string | number | boolean, record: any) => record.task_type.includes(value),
      render: initialCellValue
    },
    {
      title: formatTitle('Due Date & Time'),
      dataIndex: 'due_date',
      key: 'due_date',
      ...TABLE_CELL_CONFIG,
      sorter: (a: any, b: any) => a.due_date?.localeCompare(b.due_date),
      render: (due_date: string) => moment(due_date).format(DATE_TIME_FORMAT)
    },
    {
      title: formatTitle('Priority'),
      dataIndex: 'priority',
      key: 'priority',
      ...TABLE_CELL_CONFIG,
      filters: [
        { text: 'Low', value: 'Low' },
        { text: 'Medium', value: 'Medium' },
        { text: 'High', value: 'High' },
      ],
      onFilter: (value: string | number | boolean, record: any) => record.priority.includes(value),
      render: initialCellValue

    },

  ]



  const newColArray = flexibleColumns.filter((item: any) => {
    if (includeColumn(props.selectedTableColumns, item.dataIndex)) {
      return item
    }
  })

  const finalTableColumns = [
    ...fixedColumns,
    ...newColArray,
    ...actionColumn
  ]

  const convertToTableData = (data: any) => {
    const tableData = data
    // add key to each row
    tableData.forEach((row: any, index: number) => {
      row.key = index
    })

    // default sort - by due date, descending
    tableData.sort((a: any, b: any) => {
      return new Date(b.due_date).getTime() - new Date(a.due_date).getTime()
    })

    return tableData
  }

  useEffect(() => {
    const newTableData = convertToTableData(data)
    setTableData(newTableData)
  }, [data])



  return (
    !isLoading && data?.length === 0 ?
      <DisplayEmptyText>
        No Task to display.
      </DisplayEmptyText>
      :
      <AdiTable
        loading={isLoading}
        className=''
        columns={finalTableColumns}
        tableData={tableData}
        fullWidth
        marginTop='20px'
        scroll={{ x: '100%', y: '70vh' }}
        onRowClick={(record: any, rowIndex: any, event: any) => {
          // prevent row click if trying to select text
          if (checkWindowSelectedText()) return
          onRowClick(record)
        }}
        extraPaginationPadding
      // sticky
      />


  )
}

export default DueTaskTable