import React, { useState, MouseEvent, useEffect, useMemo } from 'react'
import FilterHeader from '../components/Header/FilterHeader'
import { formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions'
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS'
import moment from 'moment'
import AdiTable from 'components/AdiTable'
import { ActionButton, FlexWrapper } from 'statics/styles/StyledComponents'
import { Col, Divider, Form, Row, message } from 'antd'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import Input from 'components/Input'
import VALIDATION_RULES from 'utilities/inputValidation'
import DragFileInput from 'components/Input/DragFileInput'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { ADI_CONFIRM, checkWindowSelectedText, getBizCode, getUploadToS3Promise } from 'utilities/Functions/GlobalHelperFunctions'
import DisplayStats from 'components/Statistics/DisplayStats'
import GlobalDrawer from 'components/GlobalModal/GlobalDrawer'
import { useAuthStore, useGeneralStore } from 'zustand-stores'
import { AuthStore } from 'zustand-stores/useAuthStore'
import { ComplianceAPI, ValueChainAPI } from '../apis'
import { useModalDataHandler } from './index';


import { DOCUMENT_TYPES, ECOSYSTEM_PARTNERS } from './RequiredDocs';
import { useFileUpload } from 'utilities/hooks'
import { RcFile } from 'antd/es/upload/interface'
import { GeneralStore } from 'zustand-stores/useGeneralStore'
import styled from 'styled-components'
import TableCard from 'components/AdiTable/TableCard'
import { Analytics } from 'components/Analytics';
import OtherIcon from '../../../../assets/asset-management/other.svg';


const { ModalLayout } = GlobalDrawer;


const SubmittedDocs = () => {

  const isVctReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.vct.isReadOnly);
  const [documentForm] = Form.useForm();
  const [file, setFile] = useState<any>(null);

  const dataHandler = useDataHandler();

  const [fileHandler] = useFileUpload();

  const modalActionHandler = useModalDataHandler();

  const [filteredData, setFilteredData] = useState<any>([])

  const tableData = useMemo(() => {
    const data = dataHandler.submittedDoc?.data?.map((item: any) => {
      return {
        ...item,
      }
    })
    return data || []


  }, [dataHandler.submittedDoc.data])

  useEffect(() => {
    setFilteredData([...tableData])

  }, [tableData])

  const handleSearch = (value: string) => {
    if (!value) return setFilteredData([...tableData])
    const filteredData = tableData?.filter((item: any) => {
      return item.certificate_Type.toLowerCase().includes(value.toLowerCase())
    })
    setFilteredData(filteredData)
  };

  const filterInputConfig = [
    {
      type: 'search',
      placeholder: 'Search by Owning Organization',
      onChange: (e: any) => {
        const value = e.target.value
        handleSearch(value)
      },
      allowClear: true,
      inputStyle: { width: '300px' }
    },
  ]
  const ButtonConfig = [
    {
      text: 'Add Document',
      onClick: () => {
        modalActionHandler.handleOpenModal()
      }
    }
  ]

  const statsData = [
    {
      text: 'Total Documents',
      number: tableData?.length,
    },
    {
      text: 'Expiry Soon',
      number: dataHandler.getExpirySoonCount(tableData),
    },
    {
      text: 'Expired',
      number: dataHandler.getExpiredCount(tableData),
    },
  ];


  const analyticsInfo = [
    {
      icon: <img src={OtherIcon} alt="AllGridIcon" />,
      title: 'Total Documents',
      value: tableData?.length
    },
    {
      icon: <img src={OtherIcon} alt="AllGridIcon" />,
      title: 'Expiry Soon',
      value: dataHandler.getExpirySoonCount(tableData)
    },
    {
      icon: <img src={OtherIcon} alt="AllGridIcon" />,
      title: 'Expired',
      value: dataHandler.getExpiredCount(tableData)
    },
  ];

  const actionColumn = isVctReadOnly ? [] : [
    // {
    //   title: formatTitle('Action'),
    //   dataIndex: 'action',
    //   align: 'center',
    //   key: 'action',
    //   fixed: 'right',
    //   ...TABLE_CELL_CONFIG,
    //   width: 150,
    //   render: (text: any, record: any) => (
    //     <div style={{ display: 'flex', justifyContent: 'center' }}>
    //       <ActionButton
    //         title="Edit"
    //         onClick={(e) => {
    //           e.preventDefault()
    //           e.stopPropagation()
    //           modalActionHandler.handleOpenEditModal(record)
    //         }}
    //       >
    //         <EditOutlined style={{ fontSize: '15px' }} />
    //       </ActionButton>
    //       <ActionButton
    //         title="Delete"
    //         onClick={(e: MouseEvent) => {
    //           e.preventDefault()
    //           e.stopPropagation()
    //           ADI_CONFIRM({
    //             isdelete: true,
    //             actionTitle: 'delete this Requirement',
    //             onConfirm: () => {
    //               handleDelete(record)
    //             }
    //           })
    //         }}
    //       >
    //         <DeleteOutlined style={{ fontSize: '15px' }} />
    //       </ActionButton>
    //     </div>
    //   ),
    // },
    {
      title: formatTitle('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (text: any, record: any) => {

        const items = [
          {
            label: "Edit",
            icon: <EditOutlined />,
            onClick: () => {
              modalActionHandler.handleOpenEditModal(record)
            },
          },
          {
            label: "Delete",
            icon: <DeleteOutlined />,
            onClick: () => {
              ADI_CONFIRM({
                actionTitle: 'delete this Requirement',
                isdelete: true,
                onConfirm: () => {
                  console.log('Delete')
                  handleDelete(record)
                }
              })
            },
          }
        ];

        return <AdiTable.ActionDropdown menuItems={items} />;
      },

    },
  ];

  const columns: any = [
    {
      title: formatTitle('Cte Code'),
      dataIndex: 'cte_code',
      key: 'cte_code',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle('Owning Organization'),
      dataIndex: 'owning_organization',
      key: 'owning_organization',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle('Ecosystem Partner'),
      dataIndex: 'partner_name',
      key: 'partner_name',
      ...TABLE_CELL_CONFIG,
      filters: ECOSYSTEM_PARTNERS.map(item => ({ text: item, value: item })),
      onFilter: (value: any, record: any) => record.partner_name === value,
      render: initialCellValue,
    },
    {
      title: formatTitle('Document Type'),
      dataIndex: 'certificate_Type',
      key: 'certificate_Type',
      ...TABLE_CELL_CONFIG,
      filters: DOCUMENT_TYPES.map(item => ({ text: item, value: item })),
      onFilter: (value: any, record: any) => record.certificate_Type === value,
      render: initialCellValue,
    },
    {
      title: formatTitle('Document No.'),
      dataIndex: 'certificate_Number',
      key: 'certificate_Number',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle('Issued On'),
      dataIndex: 'issuance_date',
      key: 'issuance_date',
      ...TABLE_CELL_CONFIG,
      render: (text: any) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text)
    },
    {
      title: formatTitle('Expiry Date'),
      dataIndex: 'expiry_date',
      key: 'expiry_date',
      ...TABLE_CELL_CONFIG,
      render: (text: any) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text)
    },
    ...actionColumn
  ];

  const handleDelete = (record: any) => {
    dataHandler.deleteSubmittedDocs(record)
  }

  const closeModal = () => {
    modalActionHandler.handleCloseModal()
    documentForm.resetFields();
    setFile(null);
  };

  const handleConfirmed = async (values: any) => {
    try {
      await documentForm.validateFields();

      if (!values.file_name ||
        (typeof values.file_name === 'object' &&
          ((values.file_name.status === 'removed') ||
            (!values.file_name.url && !(values.file_name instanceof File))))) {
        message.error('Please upload a file');
        return;
      }

      await modalActionHandler.modalActions.isEdit ?
        dataHandler.updateSubmittedModal(values, modalActionHandler.modalActions.data) :
        dataHandler.addSubmittedModal(values);

      closeModal();
    } catch (error) {
      console.error('Validation failed:', error);
    }
  }


  useEffect(() => {

    initializeEditorViewForm();

  }, [modalActionHandler.modalActions.isEdit, modalActionHandler.modalActions.viewOnly]);

  const initializeEditorViewForm = async () => {

    if (modalActionHandler.modalActions.isEdit) {
      await dataHandler.getEventsListing(modalActionHandler.modalActions?.data?.cte_code)
      await dataHandler.getCertificateDocs(modalActionHandler.modalActions?.data?.cte_code)
    }

    documentForm.setFieldsValue({
      ...modalActionHandler.modalActions.data,
    });

    if (modalActionHandler.modalActions?.data?.file_name && typeof modalActionHandler.modalActions?.data?.file_name === 'string') {

      const src = fileHandler.generatePresignedUrl(modalActionHandler.modalActions?.data?.file_name)
      const fileObj = {
        uid: '-1',
        name: fileHandler.getFileNameFromS3Key(modalActionHandler.modalActions?.data?.file_name),
        url: src,
      }

      setFile(fileObj);
    }

  };

  const beforeUpload = (file: RcFile) => {
    const isPdf = file.type === 'application/pdf';

    if (!isPdf) {
      message.error('You can only upload PDF file!');
      return false;
    }

    setFile(file);

    return false;
  };

  const [issuanceDate, setIssuanceDate] = useState<any>(undefined)
  const [expireyDate, setexpireyeDate] = useState<any>(undefined)

  const onRemove = () => {
    setFile(null);
    documentForm.setFields([
      {
        name: 'file_name',
        value: undefined,
        errors: ['Please upload a File']
      }
    ]);
  };

  return (
    <>
      {/* <div style={{ marginBottom: '20px' }}>
        <DisplayStats data={statsData} />
      </div> */}
      <div style={{ marginBottom: '20px' }}>
        <Analytics items={analyticsInfo} />
      </div>
      {/* <StatsHeader statsConfig={statsHeaderConfig} horizontal /> */}
      <TableCard>
        <FilterHeader
          inputsConfig={filterInputConfig}
          buttonsConfig={ButtonConfig}
        />
        {/* {
        Array.isArray(tableData) && tableData.length > 0 ? */}
        <AdiTable
          tableData={filteredData}
          loading={dataHandler.submittedDoc.isLoading}
          columns={columns}
          fullWidth
          marginTop="20px"
          onRowClick={(record: any) => {
            if (checkWindowSelectedText()) return
            modalActionHandler.handleOpenViewModal(record)
          }}
          extraPaginationPadding
        />
      </TableCard>
      {/* :
          <DisplayEmptyTextPrimary style={{ marginTop: '200px' }}>
            Start managing your documents.
            <DisplayEmptyTextSecondary style={{ width: 'auto', marginTop: '30px' }}>
              Digitize business critical certificates and inspection documents to optimize efficiency for
              information management, certify provenance and
              ensure authenticity.
            </DisplayEmptyTextSecondary>
          </DisplayEmptyTextPrimary >
      } */}
      {/* // modal */}
      <GlobalDrawer
        open={modalActionHandler.modalActions.isOpen}
        onClose={closeModal}
        width={700}
        title={modalActionHandler.modalActions.isEdit ? 'Edit Document' : 'Add Document'}
        footer={
          <>
            <Row className='justify-content-end' style={{ marginRight: '30px' }}>

              <Form.Item style={{ marginBottom: '0' }}>
                <SimpleButton
                  text='Cancel'
                  id='modal-btn-width-regular'
                  onClick={() => {
                    closeModal()
                  }}
                  isCancel
                />

                <SimpleButton
                  id='modal-btn-width-regular'
                  form='document-form'
                  className="ms-3"
                  htmlType="submit"
                  text={modalActionHandler.modalActions.isEdit ? 'Save' : 'Submit'}
                />
              </Form.Item>

            </Row>
          </>
        }
      >
        <div style={{ marginLeft: '10px', marginRight: '30px' }}>
          <Form
            form={documentForm}
            name='document-form'
            onFinish={(values: any) => handleConfirmed(values)}
          >
            <ModalLayout>

              <ModalLayout.ModalSection title="Event Information">
                <Row gutter={[15, 0]}>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='cte_code'
                      rules={[
                        { required: true, message: 'Please select CTE Code' },
                      ]}
                      getValueFromEvent={
                        (e: any) => {
                          dataHandler.getEventsListing(e)
                          dataHandler.getCertificateDocs(e)
                          return e
                        }
                      }
                    >
                      <Input
                        label='CTE Code'
                        placeholder={`Select CTE Code`}
                        type="select"
                        options={dataHandler.cteCodeOptions}
                        required
                        disabled={modalActionHandler.modalActions.viewOnly}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='eventID'
                      rules={[
                        { required: true, message: 'Please select Event ID' },
                      ]}
                      shouldUpdate={(prev: any, cur: any) => {
                        // this logic here is to make sure that when cte_code field changed, the eventID will not displayed
                        if (prev?.cte_code && cur?.cte_code && prev.cte_code !== cur.cte_code) {
                          documentForm.resetFields(["eventID"])
                        }
                        return true
                      }}
                    >
                      <Input
                        label='Event ID'
                        placeholder={`Select Event ID`}
                        type="select"
                        options={dataHandler.eventsIDOptions}
                        required
                        disabled={modalActionHandler.modalActions.viewOnly}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </ModalLayout.ModalSection>

            </ModalLayout>
            <ModalLayout>

              <ModalLayout.ModalSection title="Document Information">
                <Row gutter={[15, 0]}>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='certificate_Type'
                      rules={[
                        { required: true, message: 'Please select Certificate Type' },
                      ]}
                    >
                      <Input
                        label='Certificate Type'
                        placeholder={`Select Certificate Type`}
                        type="select"
                        options={dataHandler.renderCertificateTypes()}
                        required
                        disabled={modalActionHandler.modalActions.viewOnly}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='certificate_Number'
                      rules={[
                        { required: true, message: 'Please enter Certificate No.' }
                      ]}
                    >
                      <Input
                        label='Certificate No.'
                        placeholder={`Enter Certificate No.`}
                        type="text"
                        required
                        disabled={modalActionHandler.modalActions.viewOnly}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='issuance_date'
                      rules={[
                        { required: true, message: 'Please select Date of Issuance' },
                      ]}
                      shouldUpdate={(prev, curr) => {
                        console.log("insideeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", prev, curr);
                        const currObj = curr as { expiry_date?: string };
                        setexpireyeDate(currObj.expiry_date);
                        return true
                      }}
                    >
                      <Input
                        label='Date of Issuance'
                        placeholder={`Date of Issuance`}
                        type="date"
                        required
                        disabled={modalActionHandler.modalActions.viewOnly}
                        disabledDate={(current: any) => {
                          if (expireyDate) {
                            return current && current > moment().endOf('days') && current > expireyDate
                          }
                          return current && current > moment().endOf('days')
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      shouldUpdate={(_prev, curr) => {
                        const currObj = curr as { issuance_date?: string };
                        setIssuanceDate(currObj.issuance_date);
                        return true
                      }}
                      name='expiry_date'
                    >
                      <Input
                        label='Date of Expiry'
                        placeholder={`Date of Expiry`}
                        type="date"
                        disabled={modalActionHandler.modalActions.viewOnly || !issuanceDate}
                        disabledDate={(current: any) => {
                          return current && current < issuanceDate
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='issuing_organization'
                    >
                      <Input
                        label='Issuing Organization'
                        placeholder={`Enter Issuing Organization`}
                        type="text"
                        disabled={modalActionHandler.modalActions.viewOnly}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='owning_organization'
                    >
                      <Input
                        label='Owning Organization'
                        placeholder={`Enter Owning Organization`}
                        type="text"
                        disabled={modalActionHandler.modalActions.viewOnly}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </ModalLayout.ModalSection>

            </ModalLayout>
            <ModalLayout>

              <ModalLayout.ModalSection title="Ecosystem Partner Details">
                <Row gutter={[15, 0]}>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='partner_name'
                    >
                      <Input
                        label='Partner Name'
                        placeholder={`Enter Partner Name`}
                        type="text"
                        disabled={modalActionHandler.modalActions.viewOnly}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='address'
                    >
                      <Input
                        label='Address'
                        placeholder={`Enter Partner Address`}
                        type="text"
                        disabled={modalActionHandler.modalActions.viewOnly}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='contact_person'
                    >
                      <Input
                        label='Contact Person'
                        placeholder={`Contact Person`}
                        type="text"
                        disabled={modalActionHandler.modalActions.viewOnly}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='contact_email'
                      rules={[VALIDATION_RULES.TYPE.EMAIL]}
                    >
                      <Input
                        label='Contact Email'
                        placeholder={`Enter Email`}
                        type="email"
                        disabled={modalActionHandler.modalActions.viewOnly}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[15, 0]} style={{ marginTop: '10px' }}>
                  <Col md={{ span: '24' }} sm={{ span: '24' }} style={{ marginBottom: '15px' }}>
                    <DragFileInput
                      accept='.pdf'
                      useFormItem
                      disabledMode={modalActionHandler.modalActions.viewOnly}
                      label="Upload Document"
                      required
                      formItemProps={{
                        style: { marginBottom: 0 },
                        name: 'file_name',
                        rules: [{
                          required: true,
                          message: 'Please upload a File',
                          validator: (_: any, value: any) => {
                            if (!value ||
                              (typeof value === 'object' && !value.url && !(value instanceof File))) {
                              return Promise.reject('Please upload a File');
                            }
                            return Promise.resolve();
                          }
                        }]
                      }}
                      beforeUpload={beforeUpload}
                      fileList={file ? [file] : []}
                      onRemove={onRemove}
                    />
                  </Col>
                </Row>
                <Form.Item
                  name='notes'
                >
                  <Input style={{ resize: 'none' }}
                    label='Notes'
                    placeholder={`Add Notes`}
                    type="textarea"
                    disabled={modalActionHandler.modalActions.viewOnly}
                  />
                </Form.Item>
              </ModalLayout.ModalSection>

            </ModalLayout>
          </Form>
        </div>
      </GlobalDrawer>
    </>
  )
}

export default SubmittedDocs

const useDataHandler = () => {
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const [submittedDoc, setSubmittedDoc] = useState<any>({
    data: [],
    isLoading: false,
  });
  const [cteCodeOptions, setCteCodeOptions] = useState<any[]>([])
  const [eventsIDOptions, setEventsIDOptions] = useState<any[]>([])
  const [certificateOptions, setCertificateOptions] = useState<any[]>([])

  const clientCode = getBizCode();

  const [fileHandler] = useFileUpload();

  useEffect(() => {
    getCTECodes();
    getSubmittedDocs();

  }, []);

  const getCTECodes = async () => {
    const res = await ValueChainAPI.getAllCTECodes()
    if (!res) return setCteCodeOptions([])
    const updatedRes = res?.map((item: any) => {
      return {
        value: item,
        label: item,
      }
    });
    setCteCodeOptions(updatedRes)

  };

  const getEventsListing = async (cteCode: string) => {
    setIsGlobalLoading(true)
    try {
      const res = await ValueChainAPI.getEventsListByCteCode(cteCode)
      if (!res) return setEventsIDOptions([])

      // sort data by intEventID

      const sortedData = res?.sort((a: any, b: any) => {
        return b?.intEventID.localeCompare(a?.intEventID)
      });

      const updatedRes = sortedData.map((item: any) => {
        return {
          value: item?.eventID,
          label: item?.intEventID,
        }
      });
      setEventsIDOptions(updatedRes)
    } catch (e) {
      message.error('Error getting events listing')

    } finally {
      setIsGlobalLoading(false)
    }

  };
  const getCertificateDocs = async (cteCode: string) => {
    setIsGlobalLoading(true)
    try {
      const res = await ComplianceAPI.getRequiredDocsByCteCode(cteCode)
      if (!res) return setCertificateOptions([])

      // sort data by id

      const sortedData = res?.sort((a: any, b: any) => {
        return a?.id.localeCompare(b?.id)
      });

      const updatedRes = sortedData.map((item: any) => {
        return {
          value: item?.document_type,
          label: item?.document_type,
        }
      });
      setCertificateOptions(updatedRes)
    } catch (e) {
      message.error('Error getting certificate type listing')


    } finally {
      setIsGlobalLoading(false)
    }

  };

  const getSubmittedDocs = async () => {
    try {
      setSubmittedDoc((prev: any) => ({
        ...prev,
        isLoading: true,
      }));

      const res = await ComplianceAPI.getSubmittedDocs();

      const filteredDocs = res.map((doc: any) => {
        if (typeof doc.file_name === 'object' && doc.file_name?.status === 'removed') {
          return {
            ...doc,
            file_name: undefined
          };
        }
        return doc;
      });

      setSubmittedDoc((prev: any) => ({
        ...prev,
        data: filteredDocs,
      }));
    } catch (e) {
      console.error('Error fetching docs:', e);
    } finally {
      setSubmittedDoc((prev: any) => ({
        ...prev,
        isLoading: false,
      }));
    }
  };

  const processPayload = async (payload: any, record?: any, type?: string) => {
    const finalPayload = payload;

    if (!payload?.file_name) {
      return finalPayload;
    }

    const promList = [];

    if (payload?.file_name instanceof File) {
      try {
        setIsGlobalLoading(true);

        if (type === 'isEdit' && record?.file_name) {
          const fileKey = typeof record.file_name === 'string'
            ? record.file_name
            : record.file_name.url?.split('?')[0].split('.com/')[1];

          if (fileKey) {
            await fileHandler.deleteFromS3(fileKey);
          }
        }

        const curKey = `${clientCode}/vct/compliance_docs/submitted_docs/${payload.file_name.name}`;
        const fileProm = await getUploadToS3Promise(payload.file_name, curKey);
        promList.push(fileProm);

        const result = await Promise.all(promList);

        if (result) {
          return {
            ...finalPayload,
            file_name: curKey,
          };
        }
      } catch (error) {
        console.error('Error processing file:', error);
        message.error('Error processing file. Please try again.');
        throw error;
      } finally {
        setIsGlobalLoading(false);
      }
    }

    return {
      ...finalPayload,
      file_name: payload.file_name,
    };
  };

  const addSubmittedModal = async (values: any) => {

    const payload = {
      ...values,
    }

    const processedPayload = await processPayload(payload)

    setIsGlobalLoading(true)
    try {
      await ComplianceAPI.createSubmittedDoc(processedPayload)

      message.success('Document submitted Successfully')

      await getSubmittedDocs();
    } catch (e) {
      // console.log('e', e)
    } finally {
      setIsGlobalLoading(false)
    }

  };

  const updateSubmittedModal = async (values: any, record: any) => {

    const payload = {
      ...record,
      ...values,
    }

    const processedPayload = await processPayload(payload, record, 'isEdit')

    setIsGlobalLoading(true)
    try {
      await ComplianceAPI.updateSubmittedDoc(processedPayload)

      message.success('Document updated Successfully')

      await getSubmittedDocs();
    } catch (e) {
      // console.log('e', e)
    } finally {
      setIsGlobalLoading(false)
    }


  };

  const deleteSubmittedDocs = async (record: any) => {

    setIsGlobalLoading(true)
    try {
      await ComplianceAPI.deleteSubmDocs(record?.id)

      message.success('Document deleted Successfully')

      await getSubmittedDocs();
    } catch (e) {
      // console.log('e', e)
    } finally {
      setIsGlobalLoading(false)
    }

  };

  const getExpirySoonCount = (data: any[]) => {
    if (getBizCode() === "acc" || getBizCode() === "acg") {
      return 0;
    }
    const today = moment().startOf('day'); // Get the current date without time
    const expirySoon = data?.filter((item: any) => {
      const expiryDate = moment(item?.expiry_date).startOf('day'); // Get the expiry date without time
      const diffInDays = expiryDate.diff(today, 'days');
      return diffInDays > 0 && diffInDays <= 30;
    });

    return expirySoon?.length || 0;
  };

  const getExpiredCount = (data: any[]) => {
    if (getBizCode() === "acc" || getBizCode() === "acg") {
      return 0;
    }
    const today = moment().startOf('day'); // Get the current date without time
    const expired = data?.filter((item: any) => {
      const expiryDate = moment(item?.expiry_date).startOf('day'); // Get the expiry date without time
      const diffInDays = expiryDate.diff(today, 'days');
      return diffInDays <= 0;
    });

    return expired?.length || 0;
  };

  const renderCertificateTypes = () => {
    // check certificateOptions is empty the display all certificate types from DOCUMENT_TYPES
    if (certificateOptions.length === 0) {
      return DOCUMENT_TYPES.map(item => ({ label: item, value: item }))
    } else {
      return certificateOptions
    }

  };



  return {
    submittedDoc,
    cteCodeOptions,
    getSubmittedDocs,
    addSubmittedModal,
    getEventsListing,
    eventsIDOptions,
    certificateOptions,
    getExpirySoonCount,
    getExpiredCount,
    updateSubmittedModal,
    deleteSubmittedDocs,
    getCertificateDocs,
    renderCertificateTypes,
  }
};
