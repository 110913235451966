import React from 'react';
import { AnalyticsItem } from '../../utils/types';
import { ListGridType } from 'antd/lib/list';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

type Props = {
  items: AnalyticsItem[];
  removeIcon?: boolean;
  grid?: ListGridType; // Handle responsiveness
};

interface StyledCardProps {
  highlight: boolean;
}

const Analytics = (props: Props) => {
  const { items, removeIcon } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false); // New state for left arrow

  const handleClick = (item: AnalyticsItem) => {
    if (item.onClick) {
      item.onClick();
    } else if (item.routeTo) {
      navigate(item.routeTo);
    }
  };

  const lowerCaseAndCapitalizeFirst = (text: string) => {
    if (!text) return '';
    return text
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const needHighLight = (item: AnalyticsItem) => {
    // const routeToList = items
    //   .map((i) => {
    //     return i.routeTo?.replaceAll('..', '').replaceAll('.', '').replaceAll("//", "/")
    //   })
    //   .filter((i) => {
    //     const afterManipulate = item?.routeTo?.replaceAll('..', '').replaceAll("//", "/")
    //     return item.routeTo && i?.includes(afterManipulate as string);
    //   })
    //   .filter((i) => {
    //     return i && pathname.includes(i)
    //   });
    // if (routeToList.length > 1) {
    //   return false;
    // }
    // return (
    //   item.routeTo !== undefined &&
    //   pathname.includes(item.routeTo.replaceAll('..', '').replaceAll('.', '').replaceAll("//", "/"))
    // );
    return false
  };

  // Scroll Right
  const scrollRight = () => {
    const container = scrollContainerRef.current;
    if (container) {
      container.scrollBy({ left: 150, behavior: 'smooth' });
    }
  };

  // Scroll Left
  const scrollLeft = () => {
    const container = scrollContainerRef.current;
    if (container) {
      container.scrollBy({ left: -150, behavior: 'smooth' });
    }
  };

  // Check if scrolling is needed and update arrow visibility
  const checkIfScrollNeeded = () => {
    const container = scrollContainerRef.current;
    if (container) {
      setShowRightArrow(container.scrollWidth > container.clientWidth);
      setShowLeftArrow(container.scrollLeft > 0); // Left arrow visibility
    }
  };

  // Check on mount and window resize
  useEffect(() => {
    checkIfScrollNeeded();
    window.addEventListener('resize', checkIfScrollNeeded);
    return () => {
      window.removeEventListener('resize', checkIfScrollNeeded);
    };
  }, [items]);

  // Check left arrow visibility when user scrolls
  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      const handleScroll = () => {
        setShowLeftArrow(container.scrollLeft > 0);
        setShowRightArrow(container.scrollLeft + container.clientWidth < container.scrollWidth);
      };
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <ScrollWrapper>
      {showLeftArrow && <LeftArrow onClick={scrollLeft}>&larr;</LeftArrow>}
      <ScrollContainer
        ref={scrollContainerRef}
        showLeftArrow={showLeftArrow}
        showRightArrow={showRightArrow}
      >
        <ItemRow>
          {items.map((item) => (
            <StyledCard
              key={item.title}
              highlight={needHighLight(item)}
              onClick={() => handleClick(item)}
            >
              <StyledContent>
                {!removeIcon && <StyledIcon>{item?.icon}</StyledIcon>}
                <StyledTitle>{item?.title && lowerCaseAndCapitalizeFirst(item?.title)}</StyledTitle>
                <StyledNumber>{item?.value}</StyledNumber>
              </StyledContent>
            </StyledCard>
          ))}
        </ItemRow>
      </ScrollContainer>
      {showRightArrow && <RightArrow onClick={scrollRight}>&rarr;</RightArrow>}
    </ScrollWrapper>
  );
};

export default Analytics;

// CSS Section

// ScrollWrapper stays the same
const ScrollWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

// Adjusted ScrollContainer for both arrows
const ScrollContainer = styled.div<{
  showLeftArrow: boolean;
  showRightArrow: boolean;
}>`
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  padding: 10px 0;
  margin-left: ${({ showLeftArrow }) =>
    showLeftArrow ? '50px' : '0'}; /* Add margin only if left arrow is visible */
  margin-right: ${({ showRightArrow }) =>
    showRightArrow ? '50px' : '0'}; /* Add margin only if right arrow is visible */
  &::-webkit-scrollbar {
    display: none;
  }
`;

// Left arrow button
const LeftArrow = styled.button`
  background-color: #ff8217;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  left: 0; /* Left side */
  z-index: 1; /* Ensure the arrow is above the list */
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e67313;
  }
`;

// Right arrow button (unchanged)
const RightArrow = styled.button`
  background-color: #ff8217;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 0; /* Right side */
  z-index: 1; /* Ensure the arrow is above the list */
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e67313;
  }
`;

const ItemRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 15px;
`;

const StyledCard = styled.div<StyledCardProps>`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: ${(props) => (props.highlight ? '#ff82171f' : '#2b2b2b')};
  border-radius: 50px;
  cursor: pointer;
  min-width: 150px;
  // max-width: 400px;
  max-width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 0;
  transition: background-color 0.3s ease;
  border: 1px solid ${(props) => (props.highlight ? '#ff8217' : '#2b2b2b')};

  &:hover {
    background-color: #ff82171f;
  }
`;

const StyledContent = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 10px;
  overflow: hidden;
`;

const StyledTitle = styled.div`
  font-size: 16px;
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
`;

const StyledNumber = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #fff;
`;

const StyledIcon = styled.div`
  display: flex;
  font-size: 24px;
  color: #fff;
`;
