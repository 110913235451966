/**
 * This is the ConsumerEngagement page
 * It shows scan summary, the latest scan, new Scans, and old scans
 *
 * NOTE: The map recenter only when data changes
 * (it will not recenter if data are then same
 * even if you change mode or selected a different range)
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { Component, useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import GoogleMap from 'google-map-react';
import SuperCluster, { AnyProps } from 'supercluster';
import { Feature, Point, BBox } from 'geojson';
import Loading from '../../../components/Loading';
import { FormatRealTimeMapData } from '../../../utilities/Functions/FormatSocketData';
import { compareScanData, compareScanTime } from '../../../utilities/Functions/SortFunctions';
import { ReactComponent as Marker } from '../../../statics/images/map-marker.svg';
import { RealTimeDataPayload, RealTimeMap, subItem, api } from '../../../utilities/types';
import styles from '../../../statics/styles/GoogleMapThemes/GoogleMapStylesPurple.json';
import {
  ScanDetails,
  ScanHeader,
  Cluster,
  Dot,
  DotMarker,
  Summary,
  MapHeader,
  ControlPanel,
  Live,
  Body,
  MapBody,
} from './styles';
import {
  PageWrapper,
  WhiteButton as MyButton,
  Grid,
  ShowHeader,
  PageHeader,
} from '../../../statics/styles/StyledComponents';
import countryData from './Countries';
import totalScan from '../../../statics/images/totalScans.svg';
import newScan from '../../../statics/images/newScan.svg';
import styled from 'styled-components';
import DateRange from '../../../components/DateRange';
import { isMobile } from 'react-device-detect';
import Header from '../../Headers/DesktopHeader';
import { getBizCode, getSessionStorage } from 'utilities/Functions/GlobalHelperFunctions';
import DisplayStats from 'components/Statistics/DisplayStats';
import { Descriptions } from 'antd';
import { useCEScanStore } from 'zustand-stores';
import { CEScanStore } from 'zustand-stores/useCEScanStore';
import ConsumerEngagementCore from './ComsumerEngagementCore';
import { SESSION_STORAGE_KEYS } from 'utilities/CONSTANTS';
import TableCard from 'components/AdiTable/TableCard';
import NewScansIcon from '../../../assets/consumer-engagement/new-scans.svg';
import TotalScansIcon from '../../../assets/consumer-engagement/total-scans.svg';
import { Analytics } from 'components/Analytics';

const initialData: RealTimeDataPayload = {
  newData: [],
  oldData: [],
};


// let dataMemory: RealTimeDataPayload = {
//   newData: [],
//   oldData: [],
// };


type Props = {
  apis: api[];
};

const ConsumerEngagement = (props: Props) => {
  const { apis } = props;
  const [loading, setLoading] = useState(false);
  const [realtime, setRealtime] = useState(true);
  const [mode, setMode] = useState<'live' | 'history'>('live');
  const [dataHistory, setDataHistory] = useState<RealTimeDataPayload>({
    ...initialData,
  });
  const [latestScan, setLatestScan] = useState<RealTimeMap>();
  const [refreshButton, setRefreshButton] = useState(false);
  const BIZ_CODE = getBizCode();

  const { realtimeData, scanStatistics, setCeSocketData: initSocketData } = useCEScanStore((state: CEScanStore) => state)


  console.log('ConsumerEngagement websocket', realtimeData, scanStatistics)

  const analyticsData = [
    {
      bgOne: '#2ABAD3',
      bgTwo: '#3CEAA3',
      icons: totalScan,
      title: 'Total Scans',
      value: scanStatistics.totalScan,
    },
    {
      bgOne: '#6B8DEC',
      bgTwo: '#49C4F2',
      icons: newScan,
      title: 'New Scans',
      value: scanStatistics.newScan,
    },
  ];

  // Initialize data
  useEffect(() => {
    const initializeData = () => {
      setLoading(true);
      const url = `${apis[0].url}?scan_init=all&customer=${BIZ_CODE}`;

      axios({
        method: 'get',
        url,
        headers: {
          'Content-Type': 'application/json',
          Authorization: getSessionStorage(SESSION_STORAGE_KEYS.IDTOKEN, true)?.jwtToken
        },
      })
        .then((res) => {
          console.log('initializeData', res)
          if (res.data) {
            initSocketData(res.data)
          }

        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    initializeData()
  }, [])

  useEffect(() => {
    const latest = realtimeData.newData?.sort(compareScanTime)?.[0];
    setLatestScan(latest);
  }, [realtimeData])



  const getHistoryData = (a: number, b: number) => {
    // Convert timestamp to date
    const start = moment.unix(a).startOf('day').toISOString()
    const end = moment.unix(b).endOf('day').toISOString()

    console.log('getHistoryData', a, b, start, end)

    const url = `${apis[0].url}?start=${start}&end=${end}&customer=${BIZ_CODE}`;

    setLoading(true);
    axios({
      method: 'get',
      url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: getSessionStorage(SESSION_STORAGE_KEYS.IDTOKEN, true)?.jwtToken
      },
    })
      .then((response) => {
        const { Res } = response.data;
        setDataHistory({
          newData: [] as RealTimeMap[],
          oldData: Array.isArray(Res)
            ? Res.map((item: RealTimeMap): RealTimeMap => FormatRealTimeMapData(item, false))
            : ([] as RealTimeMap[]),
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };





  console.log('realtime', realtime, realtimeData);
  console.log('scanStatistics', scanStatistics);

  const analyticsInfo = [
    {
      icon: <img src={TotalScansIcon} />,
      title: 'Total scans',
      value: scanStatistics?.totalScan || 0,
      routeTo: '',
    },
    {
      icon: <img src={NewScansIcon} />,
      title: 'New scans',
      value: scanStatistics?.newScan || 0,
      routeTo: '',
    },
  ];

  return (
    <PageWrapper>
      {!isMobile && <Header pageTitle="Consumer Engagement" />}
      <Loading show={loading} />
      {isMobile ? (
        <PageHeader>
          <ShowHeader style={{ width: '255px' }}>
            <DateRange
              onApply={(a: any, b: any) => {
                getHistoryData(a, b);
                setRealtime(false);
                setMode('history');
              }}
              refereshClicked={() => {
                setRefreshButton(true);
                setRealtime(true);
              }}
              refreshButton={refreshButton}
            />
          </ShowHeader>
        </PageHeader>
      ) : (
        null
        // <PageHeader>
        //   <ShowHeader style={{ marginTop: '15px', marginBottom: '5px', width: '255px' }}>
        //     <DateRange
        //       onApply={(a: any, b: any) => {
        //         getHistoryData(a, b);
        //         setRealtime(false);
        //         setMode('history');
        //       }}
        //       refereshClicked={() => {
        //         setRefreshButton(true);
        //         setRealtime(true);
        //       }}
        //       refreshButton={refreshButton}
        //     />
        //   </ShowHeader>
        // </PageHeader>
      )}
      <div style={{ overflowY: 'scroll', maxHeight: '82vh' }}>
        <Body>
          {/* <DisplayStats data={analyticsData.map((rec) => ({ text: rec.title, number: rec.value }))} />
          <div style={{ marginBottom: '20px' }}></div> */}
          <div style={{ marginBottom: '20px' }}>
            <Analytics items={analyticsInfo} />
          </div>
          <TableCard style={{
            border: '1px solid #626262',
            borderRadius: '4px',
          }}
            padding='16px 10px 10px 10px'>
            {isMobile ?
              (
                <MapHeader>
                  <ControlPanel style={{ marginLeft: '10px' }}>
                    <WhiteButton style={{ textAlign: 'center' }}
                      type="button"
                      onClick={() => {
                        setRealtime(true);
                        setMode('live');
                      }}
                    >
                      {/* <Live realtime={realtime}>LIVE</Live> */}
                      <Live realtime={realtime}>Live Scans</Live>
                    </WhiteButton>
                  </ControlPanel>
                </MapHeader>
              ) : (
                <FilterDiv style={{
                  flexDirection: 'column',
                  gap: '15px',
                }}>
                  <ControlPanel style={{ marginLeft: '10px' }}>
                    <WhiteButton
                      type="button"
                      style={{ marginRight: '0px', width: '8rem' }}
                      onClick={() => {
                        setRealtime(true);
                        setMode('live');
                      }}
                    >
                      <Live realtime={realtime}>Live Scans</Live>
                    </WhiteButton>
                  </ControlPanel>
                  <ShowHeader style={{ marginRight: '0px', marginBottom: '5px', width: '295px', marginLeft: '0px' }}>
                    <DateRange
                      onApply={(a: any, b: any) => {
                        getHistoryData(a, b);
                        setRealtime(false);
                        setMode('history');
                      }}
                      refereshClicked={() => {
                        setRefreshButton(true);
                        setRealtime(true);
                      }}
                      refreshButton={refreshButton}
                    />
                  </ShowHeader>
                  {/* <ControlPanel style={{ marginLeft: '10px' }}>
                    <WhiteButton
                      type="button"
                      style={{ marginRight: '0px' }}
                      onClick={() => {
                        setRealtime(true);
                        setMode('live');
                      }}
                    >
                      <Live realtime={realtime}>Live Scans</Live>
                    </WhiteButton>
                  </ControlPanel> */}
                </FilterDiv>
              )}

            <MapBody style={{ margin: '0' }}>

              <ConsumerEngagementCore
                data={realtime ? realtimeData : dataHistory}
                mode={mode}
                latestScan={latestScan}
              />

            </MapBody>
          </TableCard>
        </Body>
      </div>
    </PageWrapper >
  );
};

export default ConsumerEngagement;

const WhiteButton = styled(MyButton)`
  // background:#191919
  // background:#1C1C28
  background:transparent;
  font-family: var(--font-normal);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  text-align: end;
  padding: 0.5rem 0.1rem;
`;


const FilterDiv = styled.div`
display: flex;
justify-content: space-between;
margin-bottom: 8px;
`;



