import { Col, Form, Row, message } from 'antd';
import AdiTable from 'components/AdiTable'
import Loading from 'components/Loading'
import React, { useCallback, useEffect, useState } from 'react'
import { TrackFlexTwo, TrackGridFour, TrackGridThree, TrackGridTwo } from 'statics/styles/StyledComponents';
import { ASSET_MANAGEMENT_API } from 'utilities/AdiApi';
import { DisposalReasonOptions, TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import { formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions';
import { convertDateAndTime, getBizCode, getSelectorOptions } from 'utilities/Functions/GlobalHelperFunctions';
import Input from 'components/Input';
import GlobalModal from 'components/GlobalModal';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import moment from 'moment';


import set from 'lodash/set';
import DisposalAPI from 'containers/Pages/AssetManagement/apis/DisposalAPI';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';

type Props = {
    selectedAssets: any[];
    open: boolean;
    onCancel: Function;
    initialize?: Function;
    setDoSearch?: Function;
    doSearch?: boolean;
};

type DisposalData = {
    disposal_ID: string;
    disposed_on: string;
    asset_name: string;
    asset_ID: string;
    asset_category: string;
    asset_type: string;
    asset_price: string;
    customer_ID: string;
    home_location: string;
    sensor_ID: string;
    reason_for_disposal: string;
    comments: string;
}

const DisposalAsset = (props: Props) => {
    const { selectedAssets, open, onCancel, doSearch } = props;

    const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

    const defaultDisposalData = {
        disposal_ID: '',
        disposed_on: '',
        asset_name: '',
        asset_ID: '',
        asset_category: '',
        asset_type: '',
        asset_price: '',
        customer_ID: '',
        home_location: '',
        sensor_ID: '',
        reason_for_disposal: '',
        comments: '',
    }

    const [form] = Form.useForm();
    const [disposalData, setDisposalData] = useState<DisposalData>({ ...defaultDisposalData });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const BIZ_CODE = getBizCode();

    useEffect(() => {
        const getDisposalID = () => {
            // get disposal id
            setIsGlobalLoading(true)
            const url = `${BIZ_CODE}/disposals_ID`
            ASSET_MANAGEMENT_API.get(url).then(res => {
                console.log('disposals_ID res==>', res.data)
                const disposal_report_ID = res.data
                const finalData = {
                    ...selectedAssets[0],
                    disposal_ID: disposal_report_ID
                }
                console.log('final data after getting id ', finalData)

                setDisposalData(finalData)
                form.setFieldsValue({ ...finalData })
                setIsModalOpen(true)
                setIsGlobalLoading(false)
            }).catch(err => {
                console.log('err', err)
                setIsGlobalLoading(false)
            })
        }
        if (open) {
            getDisposalID()
        }
    }, [selectedAssets, open])

    const renderOptions = (type: string) => {
        if (type == 'reason') {
            return getSelectorOptions(DisposalReasonOptions, 'Reason For Disposal', 'value', 'label')
        } else {
            return getSelectorOptions([], 'Select')
        }
    }

    const handleConfirm = async (values: any) => {
        console.log('handleConfirm values', values)
        console.log('handleConfirm selectedAssets', selectedAssets)
        // if any of the value is undefined then return ''
        Object.keys(values).forEach(key => {
            if (values[key] == undefined) {
                values[key] = ''
            }
        })
        const sigleAssetSelected = selectedAssets[0]
        const master_refID = sigleAssetSelected['master_refID']
        const { disposal_ID, disposed_on, asset_name, asset_ID, asset_category, asset_type,
            asset_price, customer_ID, sensor_ID, home_location, reason_for_disposal, comments } = values

        const payload: any = {
            disposal_ID,
            disposed_on: convertDateAndTime(disposed_on, 'datetime'),
            asset_data: [{
                asset_name,
                asset_ID,
                asset_category,
                asset_type,
                asset_price,
                customer_ID,
                sensor_ID,
                home_location,
                reason_for_disposal,
                comments,
                master_refID
            }]
        }
        console.log('disposal payload', payload)
        // return
        try {
            await DisposalAPI.disposeAsset(payload)
            message.success({
                content: `Disposal Report added successfully!`,
                onClose: () => {
                    handleCancel()
                },
                duration: 1,
            });
            // update summary
            props.initialize && props.initialize()
            // update data
            props.setDoSearch && props.setDoSearch(!doSearch)
        } catch (err) {
            console.log('Disposal submit fail...', err)
            message.error({
                content: 'Failed to add Disposal Report.',
                duration: 1,
            });

        } finally {

            setIsGlobalLoading(false)
        }


        // const BIZ_CODE = getBizCode();
        // const url = `${BIZ_CODE}/disposals`
        // setIsGlobalLoading(true)
        // ASSET_MANAGEMENT_API({
        //     url,
        //     method: 'POST',
        //     data: payload
        // }).then(res => {
        //     setIsGlobalLoading(false)
        //     message.success({
        //         content: `Disposal Report added successfully!`,
        //         onClose: () => {
        //             handleCancel()
        //         },
        //         duration: 1,
        //     });
        //     props.initialize && props.initialize()
        // }).catch(err => {
        //     console.log('Disposal submit fail...', err)
        //     setIsGlobalLoading(false)
        //     message.error({
        //         content: 'Failed to add Disposal Report.',
        //         duration: 1,
        //     });
        // })
    }

    const handleCancel = () => {
        onCancel()
        setIsModalOpen(false)
        form.resetFields()
        form.setFieldsValue({ ...defaultDisposalData })
    }

    const renderFooter = () => (
        <Row key={'1'} className='justify-content-end'>
            <>
                <Form.Item style={{ marginBottom: '0' }}>
                    <SimpleButton
                        text='Cancel'
                        id='modal-btn-width-small'
                        onClick={() => {
                            handleCancel()
                        }}
                        isCancel
                    />

                    <SimpleButton
                        id='modal-btn-width-regular'
                        form='form-single'
                        className="ms-3"
                        htmlType="submit"
                        text="Create"
                    />
                </Form.Item>
            </>
        </Row>
    )

    return (
        <GlobalModal
            title='Disposal Report'
            open={isModalOpen}
            onCancel={handleCancel}
            footer={[renderFooter()]}
        >
            <Form
                form={form}
                name='form-single'
                onFinish={(values: any) => handleConfirm(values)}
                initialValues={{ ...disposalData }}
            >
                <>
                    <Row gutter={[20, 0]}>
                        <Col sm={{ span: '24' }} md={{ span: '12' }}>
                            <Form.Item
                                name="disposal_ID"
                            >
                                <Input
                                    label="Disposal Report ID"
                                    name="disposal_ID"
                                    type="text"
                                    placeholder="Disposal Report ID"
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col sm={{ span: '24' }} md={{ span: '12' }} >
                            <Form.Item
                                name="disposed_on"
                                rules={[{ required: true, message: 'Please select a Date' }]}
                            >
                                <Input
                                    label="Disposed On"
                                    name="disposed_on"
                                    type="date"
                                    placeholder="Select Date"
                                    required={true}
                                />
                            </Form.Item>
                        </Col>

                        <Col sm={{ span: '24' }} md={{ span: '12' }}>
                            <Form.Item
                                name="asset_name"
                            >
                                <Input
                                    label="Asset Name"
                                    name="asset_name"
                                    type="text"
                                    placeholder="Asset Name"
                                    disabled
                                />
                            </Form.Item>
                        </Col>

                        <Col sm={{ span: '24' }} md={{ span: '12' }}>
                            <Form.Item
                                name="asset_ID"
                            >
                                <Input
                                    label="Asset ID"
                                    name="asset_ID"
                                    type="text"
                                    placeholder="Asset ID"
                                    disabled
                                />
                            </Form.Item>
                        </Col>

                        <Col sm={{ span: '24' }} md={{ span: '12' }}>
                            <Form.Item
                                name="asset_category"
                            >
                                <Input
                                    label="Asset Category"
                                    name="asset_category"
                                    type="text"
                                    placeholder="Asset Category"
                                    disabled
                                />
                            </Form.Item>
                        </Col>

                        <Col sm={{ span: '24' }} md={{ span: '12' }}>
                            <Form.Item
                                name="asset_type"
                            >
                                <Input
                                    label="Asset Type"
                                    name="asset_type"
                                    type="text"
                                    placeholder="Asset Type"
                                    disabled
                                />
                            </Form.Item>
                        </Col >

                        <Col sm={{ span: '24' }} md={{ span: '12' }}>
                            <Form.Item
                                name="asset_price"
                            >
                                <Input
                                    label="Asset Value"
                                    name="asset_price"
                                    type="text"
                                    placeholder="Asset Value"
                                    disabled
                                />
                            </Form.Item>
                        </Col >

                        <Col sm={{ span: '24' }} md={{ span: '12' }}>
                            <Form.Item
                                name="home_location"
                            >
                                <Input
                                    label="Home Location"
                                    name="home_location"
                                    type="text"
                                    placeholder="Home Location"
                                    disabled
                                />
                            </Form.Item>
                        </Col >

                        <Col sm={{ span: '24' }} md={{ span: '12' }}>
                            <Form.Item
                                name="customer_ID"
                            >
                                <Input
                                    label="Customer ID"
                                    name="customer_ID"
                                    type="text"
                                    placeholder="Customer ID"
                                    disabled
                                />
                            </Form.Item>
                        </Col >

                        <Col sm={{ span: '24' }} md={{ span: '12' }}>
                            <Form.Item
                                name="sensor_ID"
                            >
                                <Input
                                    label="Sensor ID/Device ID"
                                    name="sensor_ID"
                                    type="text"
                                    placeholder="Sensor ID"
                                    disabled
                                />
                            </Form.Item>
                        </Col >

                        <Col sm={{ span: '24' }} md={{ span: '12' }}>
                            <Form.Item
                                name="reason_for_disposal"
                                rules={[
                                    { required: true, message: 'Please select the Reason For Disposal.' },
                                ]}
                            >
                                <Input
                                    label="Reason For Disposal"
                                    name="reason_for_disposal"
                                    type="select"
                                    placeholder="Reason For Disposal"
                                    options={renderOptions('reason')}
                                    onChange={(curValue: any) => {
                                        console.log('e.target.value', curValue)
                                    }}
                                    required
                                />
                            </Form.Item>
                        </Col >
                    </Row >
                    <Row>
                        <Form.Item style={{ width: '100%' }}
                            name="comments"
                        >
                            <Input
                                label="Additional Comment"
                                name="comments"
                                type="textarea"
                                placeholder="Add Comment"
                            />
                        </Form.Item>
                    </Row>
                </>
            </Form >
        </GlobalModal >
    )
}

export default DisposalAsset