import { logError } from "utilities/Functions/GlobalHelperFunctions";
import { VALUE_CHAIN_API } from "utilities/AdiApi";
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";

const CACHE_KEY = 'internal_events_cache';
const CACHE_DURATION = 2 * 60 * 60 * 1000;

const EventAPI = {
  getInternalEvents: async () => {
    const bizCode = getBizCode()
    // const bizCode = 'papl'
    try {
      const cachedData = localStorage.getItem(CACHE_KEY);
      if (cachedData) {
        const { data, timestamp } = JSON.parse(cachedData);
        const now = new Date().getTime();

        if (now - timestamp < CACHE_DURATION) {
          return data;
        }
        localStorage.removeItem(CACHE_KEY);
      }

      const res = await VALUE_CHAIN_API.get(`valuechain_events/${bizCode}?get_internal_events`);
      const responseData = res.data.body;

      const cacheData = {
        data: responseData,
        timestamp: new Date().getTime()
      };
      localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));

      return responseData;
    } catch (e) {
      logError(e)
      throw e
    }
  }
}

export default EventAPI