/**
 * Here define the constants for portal.itrazokey.com
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

/* static customers  */

export const STATIC_CUSTOMERS: any = {
  ORGANICOLIVEOIL: "organicoliveoil",
  COSTAGROUP: "costagroup",
  THECHOSENONES: "tco",
  OTHER: "ce",
};

/* user federation roles for access control */

export const USER_ROLES = {
  ADMIN: "admin",
  OPERATIONAL: "operational",
  MANAGER: "manager",
  MARKETING: "marketing",
  READ_ONLY: "readonly",
};

/* keys for extracting session storage */

export const SESSION_STORAGE_KEYS = {
  IDTOKEN: "ITRAZOKEY_PORTAL_ID_TOKEN",
  USER_NAME: "USER_NAME",
  USER_STORAGE: "USER_STORAGE",
  ACCESS_LEVEL: "ACCESS_LEVEL",
  BIZ_CODE: "BIZ_CODE",
  ACCESS_KEY_ID: "AccessKeyId",
  ACCESS_KEY_SEC: "AccessKeySec",
  SESSION_TOKEN: "sessionToken",
};

/* different sign-in results amplify responds */

export const CHALLENGE_NAMES = {
  SMS_MFA: "SMS_MFA",
  SOFTWARE_TOKEN_MFA: "SOFTWARE_TOKEN_MFA",
  NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
  MFA_SETUP: "MFA_SETUP",
};

/* different sign-in errors amplify responds */

export const AMPLIFY_ERROR_CODE = {
  UserNotConfirmedException: "UserNotConfirmedException",
  PasswordResetRequiredException: "PasswordResetRequiredException",
  NotAuthorizedException: "NotAuthorizedException",
  UserNotFoundException: "UserNotFoundException",
};

/* types of socket message received */

export const SOCKET_MESSAGE_TYPE = {
  // INIT: "INIT",
  // ALERT: "ALERT",
  // CREATE_KEY: "createkey",
  // CREATE_KEY_RESULT: "createkey_result",
  // ACTIVATE_KEY: "activatekey",
  // ACTIVATE_KEY_RESULT: "activatekey_result",
  SCAN: "SCAN",
};

/* types of messages to send to socket */

// export const SOCKET_ACTION_TYPE = {
//   INITIAL: "INIT",
//   HEART_BEAT: "HEART_BEAT",
//   ALERT: "ALERT",
//   KEY_CREATE: "createkey",
//   KEY_ACTIVATE: "activatekey",
// };

/* keyboard key codes */

export const KEYBOARD_CODES = {
  ENTER: 13,
  EXIT: 27,
};

export const DATE_FORMAT_LIST = ["DD/MM/YYYY", "DD/MM/YY"];
// export const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss";
export const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm";
export const DATE_TIME_FORMAT_FOR_S3 = "DDMMYYYYHHmmss";
export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_FORMAT_DASH = "YYYY-MM-DD";
export const TIME_FORMAT = "HH:mm:ss";
export const DATE_FORMAT_FOR_DB = "YYYY-MM-DD";
export const DATE_TIME_FORMAT_ZERO_TIME_OFFSET = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";
export const FIXED_TABLE_HEIGHT = "50vh";

export const ADDRESS_TYPES: { [key: string]: string } = {
  DELIVERY: "Delivery",
  BILLING: "Billing",
  LOCATION: "Location",
};
export const ACTIVE_INACTIVE_STATUS: { [key: string]: boolean } = {
  ACTIVE: true,
  INACTIVE: false,
};

export const BACKGROUND_SETS = {
  ORANGE: { bgOne: "#E2382E", bgTwo: "#EF7F48" },
  DARK_ORANGE: { bgOne: "#9d5f10", bgTwo: "#e1d2907d" },
  YELLOW: { bgOne: "#EE931E", bgTwo: "#FBD224" },
  BLUE: { bgOne: "#2ABAD3", bgTwo: "#3CEAA3" },
  DARK_BLUE: { bgOne: "#5b85c", bgTwo: "#489790" },
  BROWN: { bgOne: "#9d5f10", bgTwo: "#e1d2907d" },
  GREEN: { bgOne: "#66c55b", bgTwo: "#878d58" },
};

//  ------------ Value chain constants start -------------------
export const ALERTS_TYPES = [
  "INCORRECT SUPPLY CHAIN EVENT",
  "TEMPERATURE BREACH",
  "LOCATION DIVERT",
];

export const ALERT_BACKGROUNDS_SETS = {
  [ALERTS_TYPES[0]]: { ...BACKGROUND_SETS.ORANGE },
  [ALERTS_TYPES[1]]: { ...BACKGROUND_SETS.YELLOW },
  [ALERTS_TYPES[2]]: { ...BACKGROUND_SETS.BLUE },
};

export const PRIORITY_TYPES = ["High", "Medium", "Low"];

export const ISSUE_REASON = [
  "Cross Contamination",
  "Biosecurity",
  "Operational Discrepancy",
  "Temperature Discrepancy",
  "Incorrect Labelling",
  "Incorrect Ingredient",
  "Other Reason",
];
export const RECALL_REASON = [
  "Biosecurity",
  "Contaminated Product",
  "Expired Product",
  "Incorrect Labelling",
  "Incorrect Ingredient",
  "Food Safety",
];
export const ISSUE_STATUS = ["Pending", "Resolved", "Recalled"];
export const TRACK_LEVELS = [
  { label: "Product ID", value: "product" },
  { label: "Batch ID", value: "batch" },
];

//  ------------ Value chain constants end -------------------

//  ------------ Asset management constants start ------------

export const ASSET_TYPES_AND_CATEGORIE = [
  {
    type: "Logistics",
    cate: ["Crate", "Pallet", "Container", "Trailor", "Truck"],
  },
  { type: "Agriculture", cate: ["Livestock", "Machinery"] },
];

export const ASSET_ALERTS_CATEGORY = {
  NUMERIC: "NUMERIC",
  GEOFENCE: "GEOFENCE",
  SHIPMENT: "SHIPMENT",
};
export const SENSOR_TYPES: { [key: string]: string } = {
  NUMERIC: "NUMERIC",
  GEOFENCE: "GEOFENCE",
  SHIPMENT: "SHIPMENT",
};
export const ALLOCATION_REQUEST_TYPES: { [key: string]: string } = {
  REQUEST: "Order New Asset(s)",
  RETRIEVAL: "Retrieve Existing Asset(s)",
};
export const ALLOCATION_REQUEST_STATUS: { [key: string]: string } = {
  OUTSTANDING: "Outstanding",
  "IN-TRANSIT": "In-transit",
  COMPLETE: "Complete",
};
export const BOOL_STATUS: { [key: string]: boolean } = {
  Yes: true,
  No: false,
};

export const SENSOR_STATUS: { [key: string]: string } = {
  ON: "ON",
  OFF: "OFF",
};

export const ASSET_STATUS = {
  Unallocated: "Unallocated",
  "Under Maintenance": "Under Maintenance",
  Allocated: "Allocated",
  "For Pickup": "For Pickup",
  "For Disposal": "For Disposal",
  // Disposal: "Disposal",
  Disposed: "Disposed",
};
export const ASSET_ALERTS_TYPES = {
  // change to lower cases
  "LOCATION DIVERT": "LOCATION DIVERT",
  TEMPERATURE: "TEMPERATURE",
  "GEOFENCE BREACH": "GEOFENCE BREACH",
  HUMIDITY: "HUMIDITY",
  OTHER: "OTHER",
};

type ASSET_MAINTENANCE_TYPES_KEYS = "Overdue" | "Upcoming" | "Completed";

export const ASSET_MAINTENANCE_TYPES: { [key: string]: string } = {
  // OVERDUE: "OVERDUE",
  // UPCOMING: "UPCOMING",
  DUE: "due",
  COMPLETED: "completed",
};
export const ASSET_FIELD_TYPES: { [key: string]: string } = {
  INTEGER: "int",
  STRING: "str",
  DATETIME: "datetime",
  BOOLEAN: "bool",
  DOC: "doc",
};
export const ASSET_ALERT_RESPONSE_TYPES: { [key: string]: string } = {
  email: "Email",
  SMS: "SMS",
};
// export const ASSET_ALERTS_TYPES = [
//   "LOCATION DIVERT",
//   "TEMPERATURE",
//   "GEOFENCE BREACH",
//   "HUMIDITY",
//   "OTHER",
// ];
export const ASSET_MAINTENANCE_BACKGROUNDS_SETS = {
  // [ASSET_MAINTENANCE_TYPES>]: { ...BACKGROUND_SETS.BLUE },
  // [ASSET_MAINTENANCE_TYPES["UPCOMING"]]: { ...BACKGROUND_SETS.YELLOW },
  // [ASSET_MAINTENANCE_TYPES["COMPLETED"]]: { ...BACKGROUND_SETS.ORANGE },
  [ASSET_MAINTENANCE_TYPES.DUE]: { ...BACKGROUND_SETS.BLUE },
  [ASSET_MAINTENANCE_TYPES.COMPLETED]: { ...BACKGROUND_SETS.ORANGE },
};

export const ASSET_ALERT_BACKGROUNDS_SETS = {
  [ASSET_ALERTS_TYPES["LOCATION DIVERT"]]: { ...BACKGROUND_SETS.BLUE },
  [ASSET_ALERTS_TYPES["TEMPERATURE"]]: { ...BACKGROUND_SETS.YELLOW },
  [ASSET_ALERTS_TYPES["GEOFENCE BREACH"]]: { ...BACKGROUND_SETS.ORANGE },
  [ASSET_ALERTS_TYPES["HUMIDITY"]]: { ...BACKGROUND_SETS.GREEN },
  [ASSET_ALERTS_TYPES["OTHER"]]: { ...BACKGROUND_SETS.BROWN },
};
export const ALLOCATION_TYPES = {
  TOTAL: "TOTAL",
  ALLOCATED: "ALLOCATED",
  "ASSETS AVAILABLE": "ASSETS AVAILABLE",
  "IN MAINTENANCE": "IN MAINTENANCE",
};

export const ASSET_SUMMARY_TYPES: { [key: string]: string } = {
  TOTAL: "total",
  ALLOCATED: "allocated",
  UNALLOCATED: "unallocated",
  IN_MAINTENANCE: "in_maintenance",
};
// export const ALLOCATION_TYPES = [
//   "ASSET AVAILABLE",
//   "ALLOCATED",
//   "IN MAINTENANCE",
//   "TOTAL",
// ];

export const ALLOCATION_BACKGROUNDS_SETS = {
  [ALLOCATION_TYPES["ASSETS AVAILABLE"]]: { ...BACKGROUND_SETS.BLUE },
  [ALLOCATION_TYPES["ALLOCATED"]]: { ...BACKGROUND_SETS.YELLOW },
  [ALLOCATION_TYPES["IN MAINTENANCE"]]: { ...BACKGROUND_SETS.ORANGE },
  [ALLOCATION_TYPES["TOTAL"]]: { ...BACKGROUND_SETS.GREEN },
};
export const ALLOCATION_STATICS = {
  [ALLOCATION_TYPES["ASSETS AVAILABLE"]]: 30,
  [ALLOCATION_TYPES["ALLOCATED"]]: 12,
  [ALLOCATION_TYPES["IN MAINTENANCE"]]: 5,
  [ALLOCATION_TYPES["TOTAL"]]: 47,
};
export const ASSET_CATEGORY = ["Logistics", "Farm Assets"];
export const ASSETS_OF_CATEGORY = {
  Logistics: ["Truck", "Container", "Pallet"],
  "Farm Assets": ["Livestock", "Tractor", "Silos", "Machinery"],
};

export const DEFAULT_ASSET_MAP_ZOOM_LEVEL = 18;

export const ASSET_MAINTENANCE_TYPE = [
  "COMPLIANCE",
  "MAINTENANCE",
  "LIFECYCLE",
];
export const ASSET_MAINTENANCE_FREQUENCY = ["20 (days)", "ON-RECEIVAL"];
export const ASSET_MAINTENANCE_REMINDER = ["TRUE", "FALSE"];
export const ASSET_MAINTENANCE_STATIC = {
  ADI: {
    frequencyDropdown: ["Days", "Weeks", "Months", "On Receival"],
    reminder: ["TRUE", "FALSE"],
    pmType: ["COMPLIANCE", "MAINTENANCE"],
  },
};

//  ------------ Asset management constants end ------------

// should be replaced with the key in env
export const ENCRYPTION_KEY =
  process.env.REACT_APP_ENCRYPTION_KEY ||
  "lalsd$$$al.althisioooooohh非常安全的KEY!";

export const NO_DEVICE_OR_DATE = "NA";

export const VALIDATE_MESSAGES = {
  required: "${label} is required.",
  types: {
    email: "${label} is not a valid email.",
    number: "${label} is not a valid number.",
  },
  number: {
    range: "${label} must be between ${min} and ${max}.",
  },
};

export const TABLE_CELL_WIDTH_200 = 200;
export const TABLE_CELL_WIDTH = 240;
export const TABLE_CELL_WIDTH_250 = 250;
export const TABLE_ACTION_WIDTH = 120;
export const TABLE_PHONE_WIDTH = 160;
export const TABLE_DATE_WIDTH = 150;
export const TABLE_ACTIVE_STATUS_WIDTH = 150;

export const TABLE_CELL_CONFIG = {
  width: TABLE_CELL_WIDTH,
  ellipsis: true,
};
export const DisposalReasonOptions = [
  { label: "End of Life", value: "end_of_life" },
  { label: "Damaged", value: "damaged" },
];

/// Value chain constants
export const RECALLTYPEOPTIONS = [
  { label: "Class I Recall", value: "Class I Recall" },
  { label: "Class II Recall", value: "Class II Recall" },
  { label: "Class III Recall", value: "Class III Recall" },
  { label: "Contamination Recall", value: "Contamination Recall" },
  { label: "Quality Defect Recall", value: "Quality Defect Recall" },
  { label: "Packaging Defect Recall", value: "Packaging Defect Recall" },
  {
    label: "Undeclared Ingredient Recall",
    value: "Undeclared Ingredient Recall",
  },
  { label: "Mislabeling Recall", value: "Mislabeling Recall" },
];

export const TRACEIDOPTIONS = [
  { label: "EVE0001", value: "EVE0001" },
  { label: "EVE0002", value: "EVE0002" },
  { label: "EVE0003", value: "EVE0003" },
];
export const TRACE_DIRECTION_OPTIONS = [
  { label: "Forward", value: "Forward" },
  { label: "Backwards", value: "Backward" },
  { label: "All", value: "All" },
];

export const RECALLEPCIDOPTIONS = [{ label: "140258", value: "140258" }];

export const RECALLSTATUSOPTIONS = [
  { label: "Active Recall", text: "Active Recall", value: "active" },
  { label: "Completed Recall", text: "Completed Recall", value: "completed" },
  { label: "Suspended Recall", text: "Suspended Recall", value: "suspended" },
];

export const ISSUESTATUSOPTIONS = [
  { label: "Open", value: "open" },
  { label: "Resolved", value: "resolved" },
];

export const ASSETPRESET_FLOW_STEPS = [
  {
    step: 1,
    label: "Login as Admin",
  },
  {
    step: 2,
    label: "Create Asset Preset",
  },
  {
    step: 3,
    label: "Add Asset Details",
  },
  {
    step: 4,
    label: "Add Maintenance Rules",
  },
  {
    step: 5,
    label: "Add Alert Rules",
  },
];

export const PM_PRESET_FLOW_STEPS = [
  {
    step: 1,
    label: "Login as Admin",
  },
  {
    step: 2,
    label: "Create PM Preset",
  },
  {
    step: 3,
    label: "Add Maintenance Rules",
  },
  {
    step: 4,
    label: "Assign to Alert Preset",
  },
];

export const ALERT_PRESET_FLOW_STEPS = [
  {
    step: 1,
    label: "Login as Admin",
  },
  {
    step: 2,
    label: "Create Alert Preset",
  },
  {
    step: 3,
    label: "Add Alert Rules",
  },
  {
    step: 4,
    label: "Add Alert Response",
  },
  {
    step: 5,
    label: "Assign to Sensor(s)",
  },
];

export const CATEGORY_TYPE_STEPS = [
  {
    step: 1,
    label: "Login as Admin",
  },
  {
    step: 2,
    label: "Add Category",
  },
  {
    step: 3,
    label: "Add Types to a Category",
  },
  {
    step: 4,
    label: "Update Category & Type",
  },
];

export const LOCATION_STEPS = [
  {
    step: 1,
    label: "Login as Admin",
  },
  {
    step: 2,
    label: "Add Location",
  },
  {
    step: 3,
    label: "Add Lat & Long Values",
  },
  {
    step: 4,
    label: "Update Location",
  },
];

export const STORES_STEPS = [
  {
    step: 1,
    label: "Login as Admin",
  },
  {
    step: 2,
    label: "Add Store",
  },
  {
    step: 3,
    label: "Select Customer",
  },
  {
    step: 4,
    label: "Add Store Details",
  },
  {
    step: 5,
    label: "Add Primary Contact",
  },
];

export const PROCUREMENT_FLOW_STEPS = [
  {
    step: 1,
    label: "Create Supplier Order",
  },
  {
    step: 2,
    label: "Approve Internally",
  },
  {
    step: 3,
    label: "Send to Supplier",
  },
  {
    step: 4,
    label: "Receive Order",
  },
  {
    step: 5,
    label: "Assign Batch/Lot No.",
  },
  {
    step: 6,
    label: "Quality Assurance Check",
  },
  {
    step: 7,
    label: "Add to Inventory",
  },
];

export const PRODUCT_FLOW_STEPS = [
  {
    step: 1,
    label: "Create Product",
  },
  {
    step: 2,
    label: "Add Category & Subcategory",
  },
  {
    step: 3,
    label: "Add Product Details",
  },
  {
    step: 4,
    label: "Add Inventory Info",
  },
  {
    step: 5,
    label: "Assign Customer(s)/Supplier(s)",
  },
];

export const QA_CHECK_FLOW_STEPS = [
  {
    step: 1,
    label: "Perform QA Test",
  },
  {
    step: 2,
    label: "Create Quality Report",
  },
  {
    step: 3,
    label: "Approve/Reject",
  },
  {
    step: 4,
    label: "Add to Inventory",
  },
];

export const INCIDENT_REPORT_FLOW_STEPS = [
  {
    step: 1,
    label: "Create Incident Report",
  },
  {
    step: 2,
    label: "Processing Batch Info",
  },
  {
    step: 3,
    label: "Product Details",
  },
  {
    step: 4,
    label: "Add Incident Info",
  },
  {
    step: 5,
    label: "Upload Relevant Files",
  },
];

export const STOCK_FLOW_STEPS = [
  {
    step: 1,
    label: "Add Inventory",
  },
  {
    step: 2,
    label: "Add Supplier Details",
  },
  {
    step: 3,
    label: "Add Product Details",
  },
  {
    step: 4,
    label: "Inventory Details",
  },
  {
    step: 5,
    label: "Print Label",
  },
];

export const BATCH_FLOW_STEPS = [
  {
    step: 1,
    label: "View Product by Batch/Lot No.",
  },
  {
    step: 2,
    label: "Update Location",
  },
  {
    step: 3,
    label: "View Stock Status",
  },
  {
    step: 5,
    label: "Print Label",
  },
];

// flow diagrams for value chain
export const ISSUE_FLOW_STEPS = [
  {
    step: 1,
    label: "Click ‘Log Event Issue’",
  },
  {
    step: 2,
    label: "Add Issue Details",
  },
  {
    step: 3,
    label: "Add Event Details",
  },
  {
    step: 4,
    label: "Add EPC Details",
  },
  {
    step: 5,
    label: "Add Affected Events",
  },
];

export const RECALL_FLOW_STEPS = [
  {
    step: 1,
    label: `Click ‘New Recall’`,
  },
  {
    step: 2,
    label: "Add Product Details",
  },
  {
    step: 3,
    label: "Add Recall Reason",
  },
  {
    step: 4,
    label: "Add Resolution",
  },
  {
    step: 5,
    label: "Add Contact Information",
  },
  {
    step: 6,
    label: "Inform Supply Chain Partners",
  },
  {
    step: 7,
    label: "Issue Press Release",
  },
];

// flow diagrams for User managment
export const USER_MANAGEMENT_FLOW_STEPS = [
  {
    step: 1,
    label: "Login as Admin",
  },
  {
    step: 2,
    label: "Add User",
  },
  {
    step: 3,
    label: "Add Modules",
  },
  {
    step: 4,
    label: "Assign Roles",
  },
];

export const USER_MANAGEMENT_FLOW_STEPS_UPDATED = [
  "Login as Admin","Add User" ,"Add Modules","Assign Roles"];

// flow diagrams for Manage Licenses
export const MANAGE_LICENSES_FLOW_STEPS = [
  {
    step: 1,
    label: "Purchase License",
  },
  {
    step: 2,
    label: "Select Subscription Type",
  },
  {
    step: 3,
    label: "Add Modules",
  },
  {
    step: 4,
    label: "Add Quantity",
  },
  {
    step: 5,
    label: "Make Payment",
  },
];

export const AM_MODULE_ROLE = [
  {
    value: "am_admin",
    label: "Admin",
  },
  {
    value: "am_ops_manager",
    label: "Operations Manager",
  },
  {
    value: "am_business_user",
    label: "Business User",
  },
  {
    value: "am_customer",
    label: "Customer",
  },
  {
    value: "am_readonly",
    label: "Visitor",
  },
];
export const CRM_MODULE_ROLE = [
  {
    value: "crm_admin",
    label: "Admin",
  },
  {
    value: "crm_ops_manager",
    label: "Operations Manager",
  },
  {
    value: "crm_business_user",
    label: "Business User",
  },
  {
    value: "crm_customer",
    label: "Customer",
  },
  {
    value: "crm_readonly",
    label: "Visitor",
  },
];
export const OSM_MODULE_ROLE = [
  {
    value: "osm_admin",
    label: "Admin",
  },
  {
    value: "osm_ops_manager",
    label: "Operations Manager",
  },
  {
    value: "osm_business_user",
    label: "Business User",
  },
  {
    value: "osm_supplier",
    label: "Supplier",
  },
  {
    value: "osm_customer",
    label: "Customer",
  },
  {
    value: "osm_readonly",
    label: "Visitor",
  },
];
export const VCT_MODULE_ROLE = [
  {
    value: "vct_admin",
    label: "Admin",
  },
  {
    value: "vct_ops_manager",
    label: "Operations Manager",
  },
  {
    value: "vct_business_user",
    label: "Business User",
  },
  {
    value: "vct_readonly",
    label: "Visitor",
  },
];
export const CE_MODULE_ROLE = [
  {
    value: "ce_admin",
    label: "Admin",
  },
  {
    value: "ce_ops_manager",
    label: "Operations Manager",
  },
  {
    value: "ce_business_user",
    label: "Business User",
  },
  {
    value: "ce_readonly",
    label: "Visitor",
  },
];

export const INDUSTRY_OPTIONS = [
  { value: "Agriculture", label: "Agriculture" },
  { value: "Automotive", label: "Automotive" },
  { value: "Banking and finance", label: "Banking and finance" },
  { value: "Construction", label: "Construction" },
  {
    value: "Consumer goods and services",
    label: "Consumer goods and services",
  },
  { value: "Education", label: "Education" },
  { value: "Utilities", label: "Utilities" },
  { value: "Healthcare", label: "Healthcare" },
  { value: "Hospitality and tourism", label: "Hospitality and tourism" },
  { value: "Information technology", label: "Information technology" },
  { value: "Insurance", label: "Insurance" },
  { value: "Manufacturing", label: "Manufacturing" },
  { value: "Media and entertainment", label: "Media and entertainment" },
  { value: "Mining", label: "Mining" },
  { value: "Pharmaceuticals", label: "Pharmaceuticals" },
  { value: "Real estate", label: "Real estate" },
  { value: "Retail", label: "Retail" },
  { value: "Telecommunications", label: "Telecommunications" },
  {
    value: "Transportation and logistics",
    label: "Transportation and logistics",
  },
  { value: "Wholesale trade", label: "Wholesale trade" },
];

export const VIEW_ONLY = "(View Only)";

// for admin user management
export const INDUSTRY_OPTIONS_LIST: any = {
  SEAFOOD: "Seafood",
  AGRICULTURE: "Agriculture",
  PHARMACEUTICAL: "Pharmaceutical",
  BUILDING_AND_CONSTRUCTION: "Building and Construction",
  HEALTH: "Health",
};

export const INDUSTRY_BASED_CATEGORY = {
  [INDUSTRY_OPTIONS_LIST.SEAFOOD]: [
    { label: "Farmed", value: "Farmed" },
    { label: "Wild Caught", value: "Wild Caught" },
  ],
  [INDUSTRY_OPTIONS_LIST.AGRICULTURE]: [
    { label: "Red Meat", value: "Red Meat" },
    { label: "Poultry", value: "Poultry" },
    { label: "Fresh Produce (Organic)", value: "Fresh Produce Organic" },
    {
      label: "Fresh Produce (Non-Organic)",
      value: "Fresh Produce Non-Organic",
    },
  ],
  [INDUSTRY_OPTIONS_LIST.PHARMACEUTICAL]: [
    { label: "Prescription Medication", value: "Prescription Medication" },
    { label: "Medicinal Cannabis", value: "Medicinal Cannabis" },
  ],
  [INDUSTRY_OPTIONS_LIST.BUILDING_AND_CONSTRUCTION]: [
    { label: "Wood", value: "Wood" },
    { label: "Concrete", value: "Concrete" },
    { label: "Steel", value: "Steel" },
  ],
  [INDUSTRY_OPTIONS_LIST.HEALTH]: [
    { label: "Prosthesis", value: "Prosthesis" },
  ],
};

export const EXPECTED_LIFE_PERIOD = [
  { label: "Day(s)", value: "Days" },
  { label: "Week(s)", value: "Weeks" },
  { label: "Month(s)", value: "Months" },
  { label: "Year(s)", value: "Years" },
];

export default null;
