/**
 * @description Contact table view page, displays the table of companies
 * @version 1.0.0
 * @author Bruce Zhu 
 */

import React, { useState, useEffect, useMemo } from 'react';

import { FilterHeader, ContactPageTable, CreateContact } from '../../components';
import { message } from 'antd'
import { useTablePageFilters } from '../../hooks';
import { FilterType } from '../../hooks/useTablePageFilters'
import { ADI_CONFIRM } from 'utilities/Functions/GlobalHelperFunctions';

import { ContactAPI } from '../../apis'
import DisplayStats from 'components/Statistics/DisplayStats';
import FlowInfoPanel from 'components/FlowInfoPanel';
import { CONTACT_FLOW_STEPS } from '../../statics/constants';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { CRM_ROUTES } from '../../CRMRoutes';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';
import TableCard from 'components/AdiTable/TableCard';
import ActiveAssetIcon from '../../../../../assets/active_assets.svg';
import { Analytics } from 'components/Analytics';

const columnDefaultOptions = {
  contacts: [
    {
      text: 'Phone Number',
      valueKey: 'phone'
    },
    {
      text: 'Job Title',
      valueKey: 'job_title'
    },
    {
      text: 'Company Name',
      valueKey: 'bus_name'
    },
    {
      text: 'Date Created',
      valueKey: 'creation_date'
    },
    {
      text: 'Status',
      valueKey: 'active'
    }
  ]
}

const defaultTablesColumns = {
  contact_table: columnDefaultOptions.contacts.map(item => item.valueKey)
}


const Contact = () => {
  const navigate = useNavigate(); // Use useNavigate
  const [modals, setModals] = useState({
    open: false,
    isEdit: false,
    contactToEdit: null,
  })
  // custom hook that handles all the filter logic
  const [filterState, filterDispatch] = useTablePageFilters('contact')

  const [showTableColumn, setShowTableColumn] = useState<{ contact_table: any[] }>(defaultTablesColumns);
  const [isLoading, setIsLoading] = useState(false)

  // handles api requests
  const api = useAPI()

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const [selectedFilter, setSelectedFilter] = useState<string>('all');
  const [filteredData, setFilteredData] = useState<any>(filterState.filteredData);

  // Load contact data
  useEffect(() => {
    getAllContacts()

  }, [])

  const getAllContacts = async () => {
    try {
      setIsLoading(true)
      const data = await api.getAllContacts()
      filterDispatch({ type: FilterType.APPLY_ALL, payload: data })
    } catch (e) {
      message.error('Failed to get contacts data.')
    } finally {
      setIsLoading(false)
    }

  }


  const deleteContact = (record: any) => {
    const id = record.contact_ID
    if (!id) {
      message.error({ content: 'Failed to delete contact: No contact id' })
      return;
    }

    // Show different title if this contact is the primary contact
    let confirmTitle = `Are you sure to delete ${record?.full_name || ''}?`
    if (record.primary_contact) {
      const companyName = record.bus_name || ''
      confirmTitle = `This contact is the primary contact for company ${companyName}, are you sure to delete?`
    }

    ADI_CONFIRM({
      customizeTitle: confirmTitle,
      isdelete: true,
      onConfirm: () => {
        setIsGlobalLoading(true)
        api.deleteContact(id).then(res => {
          message.success({ content: 'Contact deleted successfully!' })
          getAllContacts()
        }).catch(err => {
          message.error({ content: 'Failed to delete contact.' })
        }).finally(() => {
          setIsGlobalLoading(false)
        })
      }
    })
  }


  const allContactTableActions = {
    edit: (record: any) => setModals({ ...modals, open: true, isEdit: true, contactToEdit: record }),
    delete: (record: any) => deleteContact(record)
  }


  const switchToDetailsPage = (contact: any) => {
    // upate page view in redux and pass data back to parent
    // reduxDispatch(actions.setPageView(PageView.DetailsView))
    // setSelectedContact(contact)

    navigate(`${CRM_ROUTES.CONTACT_DETAILS}/${contact?.contact_ID}?objectType=contact`, {
      state: { contact }
    });
  }

  const handleColumnSelect = (e: any, type: 'contact_table') => {
    setShowTableColumn({
      [type]: e
    })

  }

  const statsData = useMemo(() => {
    const assignedNum = filterState.contactsData?.filter((contact: any) => contact.business_ID)?.length || 0
    const totalNum = filterState.contactsData?.length || 0
    const unassignedNum = totalNum - assignedNum

    return [
      {
        text: 'Total Contacts',
        number: totalNum,
      },
      {
        text: 'Assigned',
        number: assignedNum,
      },
      {
        text: 'Unassigned',
        number: unassignedNum,
      },

    ]
  }, [filterState.contactsData])

  const getValueByTitle = (title: string) => {
    const item = statsData?.find((item: any) => item.text === title);
    return item ? item.number : 0;
  };

  const handleAnalyticsClick = (status: string) => {

    setSelectedFilter(status);
  };

  useEffect(() => {
    if (selectedFilter) {
      console.log('selectedFilter **********************', selectedFilter)

      if (selectedFilter === 'all') {
        setFilteredData(filterState.filteredData);
      } else if (selectedFilter === 'Assigned') {
        const assignedList = filterState.contactsData?.filter((contact: any) => contact.business_ID)
        setFilteredData(assignedList);
      } else if (selectedFilter === 'Unassigned') {
        const assignedList = filterState.contactsData?.filter((contact: any) => !contact.business_ID)
        setFilteredData(assignedList);
      }
    } else { setFilteredData(filterState.filteredData); }
  }, [selectedFilter, filterState.filteredData]);

  const analyticsInfo = [
    {
      icon: <img src={ActiveAssetIcon} alt="AllGridIcon" />,
      title: 'Total Contacts',
      value: getValueByTitle('Total Contacts'),
      onClick: () => handleAnalyticsClick('all'),
    },
    {
      icon: <img src={ActiveAssetIcon} alt="AllGridIcon" />,
      title: 'Assigned',
      value: getValueByTitle('Assigned'),
      onClick: () => handleAnalyticsClick('Assigned'),
    },
    {
      icon: <img src={ActiveAssetIcon} alt="AllGridIcon" />,
      title: 'Unassigned',
      value: getValueByTitle('Unassigned'),
      onClick: () => handleAnalyticsClick('Unassigned'),
    }
  ];

  return (
    <div>
      <div style={{ marginTop: '-20px', marginBottom: '40px' }}>
        <FlowInfoPanel
          items={[...CONTACT_FLOW_STEPS]}
          style={{ minWidth: '400px', maxWidth: '400px', marginLeft: '15px' }}
        />
        {/* <DisplayStats data={statsData} style={{ marginBottom: '20px' }} /> */}
        <div style={{ marginBottom: '20px' }}>
          <Analytics items={analyticsInfo} />
        </div>
      </div>
      <TableCard>
        <FilterHeader
          isContact={true}
          btnOnClick={() => setModals({ ...modals, open: true })}
          onTextSearch={(text: string) => filterDispatch({ type: FilterType.SEARCH_TEXT, payload: text })}
          onDateChange={(date: [string, string]) => filterDispatch({ type: FilterType.CREATION_DATE, payload: date })}
          selectedTablesColumns={showTableColumn}
          onTableColumnSelect={handleColumnSelect}
          columnSelectOptions={columnDefaultOptions}
        />
        <ContactPageTable
          isLoading={isLoading}
          selectedTableColumns={showTableColumn.contact_table}
          data={filteredData}
          actions={allContactTableActions}
          onRowClick={(record: any) => {
            switchToDetailsPage(record)
          }}
        />
      </TableCard>

      <CreateContact
        open={modals.open}
        isEdit={modals.isEdit}
        onCancel={() => setModals({ ...modals, open: false, isEdit: false })}
        selectedContact={modals.contactToEdit}
        setReload={getAllContacts}
      />

    </div>
  );
};

export default Contact;



// handles api requests for Company component above
const useAPI = () => {

  const getAllContacts = async () => {
    try {
      const data = await ContactAPI.getAllContacts()
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }

  }

  const deleteContact = async (contactID: string) => {
    try {
      const res = await ContactAPI.deleteContact(contactID)
      return Promise.resolve(res)
    } catch (e) {
      return Promise.reject(e)
    }

  }


  return {
    getAllContacts,
    deleteContact,
  }

}