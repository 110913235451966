import React, { useState, useEffect, MouseEvent, useMemo } from "react";
import styled from "styled-components";
import SimpleButton from "../../../../../components/GlobalButtons/SimpleButton";
import Input from "../../../../../components/Input";
import mapWhiteIcon from 'statics/images/mapIcon.svg';
import tableWhiteIcon from "statics/images/tableWhiteIcon.png";
import bulkAdd from 'statics/images/bulkAdd.svg';
import singleAdd from 'statics/images/singleAdd.svg';
// import assetManagementFlow from '../../../../statics/images/assetManagementFlow.svg';
import assetManagementFlow from 'statics/images/assetManagementFlow1.svg';

import {
  FlexWrapper,
  ToggleDiv,
  Toggle,
  TrackGridTwo,
} from "../../../../../statics/styles/StyledComponents";
import { ALL_ASSETS } from "statics/Asset Data";
import { ASSET_ALERTS_CATEGORY, ASSET_ALERT_RESPONSE_TYPES, ASSET_STATUS, ASSET_SUMMARY_TYPES, BOOL_STATUS } from "utilities/CONSTANTS";
import {
  message,
} from "antd";
import { getSelectorOptions } from "utilities/Functions/GlobalHelperFunctions";
import { Toaster } from "react-hot-toast";
import { formatNameString, formatTitle, initialCellValue } from "utilities/Functions/FormatFunctions";
import DisposalAsset from "../Disposal/DisposalAsset";
import MultipleDisposalModal from "../../components/MultipleDisposalModal";
import SingleAssetModal from "./SingleAssetModal";
import BulkAssetModal from "./BulkAssetModal";
import AssetDetailsModal from "./AssestsModal/AssetDetailsModal";
import AssignAlertPresetModal from "./AssignAlertPresetModal";
import QRModal from "../../../../../components/QRModal";


import DisplayStats from "components/Statistics/DisplayStats";
import PrefillAPI from "../../apis/PrefillAPI";
import MasterDataAPI from "../../apis/MasterDataAPI";
import DropdownAPI from "../../apis/DropdownAPI";
import { AssetAlertType } from "utilities/types";
import { useAuthStore, useGeneralStore } from "zustand-stores";
import { AuthStore } from "zustand-stores/useAuthStore";
import SummaryAPI from "../../apis/SummaryAPI";
import { useResponsive } from "utilities/hooks";
import AssetTable from "./AssetTable";
import AssetMap from "./AssetGoogleMap/AssetMap";
import { GeneralStore } from "zustand-stores/useGeneralStore";
import { useShowAddBtns } from "zustand-stores/useShowAddBtns";
import { useAssetsDropDownOpen } from "zustand-stores/useAssetsDropDownOpen";
import TableCard from "components/AdiTable/TableCard";
import InMaintenanceIcon from '../../../../../assets/asset-management/in-maintenance.svg';
import TotalIcon from '../../../../../assets/asset-management/total.svg';
import AllocatedIcon from '../../../../../assets/asset-management/allocated.svg';
import UnallocatedIcon from '../../../../../assets/asset-management/unallocated.svg';
import { Analytics } from 'components/Analytics';
// import AssetGoogleMap from "./AssetGoogleMap/AssetGoogleMap";
// import AssetGoogleMap from "./AssetGoogleMap/AssetGoogleMapApi";



// this data type include the form data feids and the asset data fields
interface SingleAssetDataType {
  // editable fields
  image_s3key: string;
  masterData_refID: string;
  asset_ID: string;
  asset_name: string;
  current_deviceID?: string;
  existing_asset_ID?: string;

  // for customer attributes
  // for form filling purpose
  [key: string]: any;

  // for hold the attributes input value
  custom_attributes?: {
    bool: string[],
    datetime: string[],
    doc: string[],
    int: string[],
    str: string[],
  }

  // // for hold the attributes types
  // custom_attributes: {
  //   [key: string]: any;
  // }


  // display fields  
  asset_category: string;
  asset_type: string;
  maintenance_rules: any[];
  alerts: any[];

  value?: string | number;
  home_location?: string;


}

// this data type include the form data feids and the asset data fields
interface BulkAssetDataType {
  // editable fields
  image_s3key: string;
  masterData_refID: string;
  quantity: string | number;

  existing_feilds: "TURE" | "FALSE";

  csv_s3url: string;
  email_results: string;


  // for customer attributes
  // for form filling purpose
  [key: string]: any;

  // for hold the attributes input value
  custom_attributes?: {
    bool: string[],
    datetime: string[],
    doc: string[],
    int: string[],
    str: string[],
  }

  // // for hold the attributes types
  // custom_attributes: {
  //   [key: string]: any;
  // }


  // display fields  
  asset_category: string;
  asset_type: string;
  maintenance_rules: any[];
  alerts: any[];

  // TODO: maybe needed in further development
  value?: string | number;
  // home_location?: string;




}

// alert config modal data
export const emptyAlertData: AssetAlertType = {
  refID: '',
  class: "ALERT",
  alert_preset_name: "",
  asset_IDs: [],
  alerts: [{
    alert_name: "",
    type: "",
    alert_response: [],
    notify: [],
    attribute: '',
    minRule: '',
    maxRule: '',
    geofenceName: '',
    shipmentID: '',
  }]

}

export const VIEWS: { [key: string]: string } = {
  MAP: "Map",
  TABLE: "Table",

};



export const emptyAssetData: SingleAssetDataType = {
  image_s3key: "",
  masterData_refID: "",
  asset_ID: "",
  asset_name: "",
  current_deviceID: "",
  existing_asset_ID: "",
  // new fields added
  asset_acquisition_date: "",
  customer_ID: "",
  home_location: "",
  predicted_end_of_life: "",
  status: "Unallocated",

  // display fields  
  asset_category: "",
  asset_type: "",
  maintenance_rules: [],
  alerts: [],

  // custom_attributes: {},
  custom_attributes: {
    bool: [],
    datetime: [],
    doc: [],
    int: [],
    str: [],
  }

}

export const emptyAssetDataBulk: BulkAssetDataType = {
  image_s3key: "",
  masterData_refID: "",
  quantity: "1",
  existing_feilds: "TURE",
  csv_s3url: "",
  email_results: "",

  // display fields  
  asset_category: "",
  asset_type: "",
  maintenance_rules: [],
  alerts: [],

  // custom_attributes: {},
  custom_attributes: {
    bool: [],
    datetime: [],
    doc: [],
    int: [],
    str: [],
  }

}



const AssetsTrack = () => {

  const isAmReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.am.isReadOnly)

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const { open, setOpen, setClose } = useShowAddBtns();
  const { close } = useAssetsDropDownOpen();
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const tabsData = Object.keys(VIEWS).map((k: string) => {
    return ({
      key: VIEWS[k], label: formatNameString(VIEWS[k]), img: VIEWS[k] === VIEWS.MAP ? mapWhiteIcon : tableWhiteIcon
    })
  })


  // const [showAddBtns, setShowAddBtns] = useState<boolean>(false)

  const [filters, setFilters] = useState<{ [key: string]: string }>({
    asset_type: '',
    asset_category: '',
    search: '',
    status: '',
    customer: '',

  });
  const [searchText, setSearchText] = useState<string>('');
  const [doSearch, setDoSearch] = useState<boolean>(true); // this value is declare for when search happen or need to refresh the page from child component



  // const [searchText, setSearchText] = useState<string>('');
  const [cateTypesList, setCateTypesList] = useState<any[]>([]);
  const [customerList, setCustomerList] = useState<any[]>([]);
  const [view, setView] = useState(VIEWS.MAP);

  // disposal modal state

  const [isSingleDisposalModalOpen, setIsSingleDisposalModalOpen] = useState<boolean>(false);
  const [isMultipleDisposalModalOpen, setIsMultipleDisposalModalOpen] = useState<boolean>(false);
  const [selectedAssets, setSelectedAssets] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);


  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [assetData, setAssetData] = useState<SingleAssetDataType>(emptyAssetData);
  const [attachDoc, setAttachDoc] = useState<{ [key: string]: any }>({})



  // summary data
  const [cardsData, setCardsData] = useState<any>([]);

  const [isModalOpenBulk, setIsModalOpenBulk] = useState<boolean>(false);
  const [assetDataBulk, setAssetDataBulk] = useState<BulkAssetDataType>(emptyAssetDataBulk);

  // alert preset modal state
  const [alertModalData, setAlertModalData] = useState<AssetAlertType>(emptyAlertData);
  const [assignAlertModalOpen, setAssignAlertModalOpen] = useState<boolean>(false);
  const [allTypes, setAllTypes] = useState<string[]>([])

  // alert drop downs
  const [alertPresetList, setAlertPresetList] = useState<any[]>([])
  const [notifyList, setNotifyList] = useState<any[]>([])
  const [alertTypesList, setAlertTypesList] = useState<any[]>([])
  const [geofenceList, setGeofenceList] = useState<any[]>([])


  // single asset dropdowns
  const [assetPresetList, setAssetPresetList] = useState<any[]>([])
  const [sensorList, setSensorList] = useState<any[]>([])

  // QR modal state
  const [isQRModalOpen, setIsQRModalOpen] = useState<boolean>(false);

  const [selectedStatus, setSelectedStatus] = useState<string>('Total');

  // edit/view details modal state
  const [assetDetailsModal, setAssetDetailsModal] = useState<{ isOpen: boolean; viewOnly: boolean; modalData: any }>({
    modalData: {},
    isOpen: false,
    viewOnly: false,
  });
  // previously modalData was an individual state and then grouped into assetDetailsModal for better management
  // but here we keep the usage of variable "modalData" to avoid changing too much code
  const modalData = useMemo(() => assetDetailsModal.modalData, [assetDetailsModal.modalData]);


  const screen = useResponsive();

  const initialize = () => {
    getSummary()
    setDoSearch(!doSearch) // this will refresh the data for the page
  }

  const getSummary = async () => {

    try {
      const summaryResult = await SummaryAPI.getSummary('asset')
      if (summaryResult != '') {
        const newSummary = Object.keys(ASSET_SUMMARY_TYPES).map((item: string, index: number) => ({

          text: formatNameString(ASSET_SUMMARY_TYPES[item]),
          number: summaryResult[ASSET_SUMMARY_TYPES[item]],
        }))
        setCardsData(newSummary)
      }

    } catch (err) {
      console.log('summary asset fail...', err)

    }
  }

  const getValueByTitle = (title: string) => {
    const item = cardsData?.find((item: any) => item.text === title);
    return item ? item.number : 0;
  };

  const handleAnalyticsClick = (status: string) => {
    setSelectedStatus(status);
  };

  const analyticsInfo = [
    {
      icon: <img src={TotalIcon} alt="AllGridIcon" />,
      title: 'Total',
      value: getValueByTitle('Total'),
      onClick: () => handleAnalyticsClick('Total'),
    },
    {
      icon: <img src={AllocatedIcon} alt="AllGridIcon" />,
      title: 'Allocated',
      value: getValueByTitle('Allocated'),
      onClick: () => handleAnalyticsClick('allocated'),
    },
    {
      icon: <img src={UnallocatedIcon} alt="AllGridIcon" />,
      title: 'Unallocated',
      value: getValueByTitle('Unallocated'),
      onClick: () => handleAnalyticsClick('Unallocated'),
    },
    {
      icon: <img src={InMaintenanceIcon} alt="AllGridIcon" />,
      title: 'In Maintenance',
      value: getValueByTitle('In Maintenance'),
      onClick: () => handleAnalyticsClick('In Maintenance'),
    },
  ];

  const getDropdowns = async () => {

    try {
      const payload = {
        "categories_types": true,
        'customers': true,
      }
      const result = await DropdownAPI.getDropdowns(payload)

      const { categories_types, customers } = result
      setCateTypesList(categories_types)
      setCustomerList(customers)

    } catch (error) {
      console.log('get dropdown error maintenance', error)

    }

  }

  // the initialize will initialize map and table based on which view we are on
  useEffect(() => {
    initialize()
  }, [view]);

  useEffect(() => {
    getDropdowns()
  }, []);


  const openAddAssetPopUp = async (e: any, isBulk?: boolean) => {
    // setIsLoading(true)
    // setShowAddBtns(false)
    setClose();

    const payload = {
      "asset_presets": true,
      sensors: isBulk ? false : true // single asset need sensor list
    }

    try {
      const result = await DropdownAPI.getDropdowns(payload)

      const { asset_presets, sensors } = result

      if (Array.isArray(asset_presets)) {
        setAssetPresetList(asset_presets)
      } else {
        setAssetPresetList([])
      }

      if (isBulk) {
        setIsModalOpenBulk(true)
      }
      else {
        setIsModalOpen(true)

        if (Array.isArray(sensors)) {
          setSensorList(sensors)
        } else {
          setSensorList([])
        }
      }

    } catch (err) {
      console.log('handleSingleAssetPopUp getDropdowns fail...', err)
    }


  };


  const openDetailsModal = async (record: any, viewOnly: boolean) => {
    close();

    if (record && record.asset_ID) {
      // open details modal
      setIsGlobalLoading(true)

      try {
        const payload = {
          // "categories_types": true,
          sensors: true
        }
        const result = await DropdownAPI.getDropdowns(payload)
        const { sensors } = result
        // setCateTypesList(categories_types)
        setSensorList(sensors)

        // open details modal
        setAssetDetailsModal({
          ...assetDetailsModal,
          modalData: record,
          isOpen: true,
          viewOnly: viewOnly
        })
      } catch (err) {
        console.log('openDetailsModal getDropdowns fail...', err)

        message.error('Something went wrong.')
      } finally {
        setIsGlobalLoading(false)
      }

    }

  };

  const handleSelectAssetPreset = async (value: string, isBulk?: boolean) => {
    setIsGlobalLoading(true)

    try {


      const result = await MasterDataAPI.getMasterByIdAndType(value, 'ASSET')

      const { asset_type, asset_category, image_s3key, custom_attributes, refID, maintenance_rules, alerts } = result

      // bulk asset preset select
      if (isBulk) {
        console.log('bulk asset preset select')

        const finalData: any = {
          ...assetDataBulk,
          image_s3key,
          masterData_refID: refID,

          asset_type,
          asset_category,

          custom_attributes,

          alerts,
          maintenance_rules

        }

        console.log('finalData bulk res', finalData)

        setAssetDataBulk(finalData)

      }
      // single asset preset select
      else {
        try {

          // get the auto generated id and default asset name
          const idRes = await PrefillAPI.getAssetIdAndNameByMasterId(value)

          const { asset_ID, asset_name } = idRes

          const finalData: any = {
            ...assetData,
            image_s3key,
            masterData_refID: refID,

            asset_ID,
            asset_name,

            asset_type,
            asset_category,

            custom_attributes,

            alerts,
            maintenance_rules

          }

          console.log('finalData res', finalData)

          setAssetData(finalData)

        } catch (err) {

          console.log(' getPrefillIdUrl fail...', err)
          // setIsLoading(false)


        } finally {
          setIsGlobalLoading(false)
        }


      }

    } catch (err) {

      console.log(' getPremasterr fail...', err)
      // setIsLoading(false)


    } finally {
      setIsGlobalLoading(false)
    }

  }

  // item is the alert object
  const renderTableCell = (text: any, item: any, index: number, cellDataKey: string) => {
    if (cellDataKey == 'alert_response') {
      const result = []
      if (item.notification.email) {
        result.push(ASSET_ALERT_RESPONSE_TYPES.email)
      }
      if (item.notification.SMS) {
        result.push(ASSET_ALERT_RESPONSE_TYPES.SMS)
      }

      let finalStr = ''
      result.map((str: string, iddx: number) => {
        if (iddx == 0) {
          if (result.length != 1) {
            finalStr = `[ ${str}, `
          } else {
            finalStr = `[ ${str} ]`
          }

        } else if (iddx == result.length - 1) {
          finalStr = finalStr + `${str} ]`
        } else {
          finalStr = finalStr + `${str}, `
        }

      })
      return (
        <>
          {finalStr.length > 0 ? finalStr : '---'}
        </>
      )

    } else if (cellDataKey == 'notify') {
      if (item.notification.recipients) {
        const recipientsArr = item.notification.recipients
        let finalStr = ''
        recipientsArr.map((itemm: any, iddx: number) => {
          if (iddx == 0) {
            if (recipientsArr.length != 1) {
              finalStr = `[ ${itemm}, `
            } else {
              finalStr = `[ ${itemm} ]`
            }

          } else if (iddx == recipientsArr.length - 1) {
            finalStr = finalStr + `${itemm} ]`
          } else {
            finalStr = finalStr + `${itemm}, `
          }

        })

        return (
          <>
            {finalStr.length > 0 ? finalStr : '---'}
          </>
        )

      }

    } else if (cellDataKey == 'measurement') {
      // console.log('records item alers', item, cellDataKey)
      let finalStr = ''
      if (item.type == ASSET_ALERTS_CATEGORY.GEOFENCE) {
        finalStr = item.geofenceName

      } else if (item.type == ASSET_ALERTS_CATEGORY.SHIPMENT) {
        finalStr = item.shipmentID
      } else {
        finalStr = `${item.attribute}`
      }

      return (
        <>
          {finalStr.length > 0 ? finalStr : '---'}
        </>
      )

    } else if (cellDataKey == 'value') {
      let finalStr = ''
      if (item.type == ASSET_ALERTS_CATEGORY.GEOFENCE) {
        finalStr = item.geofenceName

      } else if (item.type == ASSET_ALERTS_CATEGORY.SHIPMENT) {
        finalStr = item.shipmentID
      } else {
        finalStr = `Min: ${item.minRule}, Max: ${item.maxRule}`
      }

      return (
        <>
          {finalStr.length > 0 ? finalStr : '---'}
        </>
      )

    } else {
      // console.log('alert name', item)
      return (
        <>
          {`${item[cellDataKey]}`}
        </>
      );
    }


  }

  const alertPresetColumns: any = [

    {
      title: formatTitle("No"),
      dataIndex: "alert_ID",
      width: 200,
      key: "alert_ID",
      render: (text: any, record: any, index: number) => (<>{index + 1}</>)
    },

    {
      title: formatTitle("Rule"),
      dataIndex: "alerts",
      width: 200,
      key: "alerts",
      render: (text: any, record: any, index: number) => {
        return renderTableCell(text, record, index, 'alert_name')

      },
    },

    {
      title: formatTitle("Measurement Type"),
      dataIndex: "placeholder",
      width: 200,
      key: "placeholder",
      // render: (text: any, record: any, index: number) => {
      //   return renderTableCell(text, record, index, 'measurement')

      // },
    },
    {
      title: formatTitle("Value"),
      dataIndex: "alerts",
      width: 200,
      key: "alerts",
      render: (text: any, record: any, index: number) => {

        return renderTableCell(text, record, index, 'value')
      },

    },
    {
      title: formatTitle("Alert Response"),
      dataIndex: "alerts",
      width: 200,
      key: "alerts",
      render: (text: any, record: any, index: number) => {

        return renderTableCell(text, record, index, 'alert_response')
      },
    },
    {
      title: formatTitle("Notify"),
      dataIndex: "alerts",
      width: 200,
      key: "alerts",
      render: (text: any, record: any, index: number) => {

        return renderTableCell(text, record, index, 'notify')
      },

    },


  ];

  const maintenanceRulesColumns: any = [

    {
      title: formatTitle("No"),
      dataIndex: "alert_ID",
      width: 200,
      key: "alert_ID",
      render: (text: any, record: any, index: number) => (<>{index + 1}</>)
    },

    {
      title: formatTitle("Name"),
      dataIndex: "name",
      width: 200,
      key: "name",
      render: initialCellValue

    },

    {
      title: formatTitle("PM Type"),
      dataIndex: "type",
      width: 200,
      key: "type",
      render: initialCellValue
    },
    {
      title: formatTitle("Frequency"),
      dataIndex: "frequency",
      width: 200,
      key: "frequency",
      render: initialCellValue

    },
    {
      title: formatTitle("Reminder"),
      dataIndex: "reminder",
      width: 200,
      key: "reminder",
      render: initialCellValue
    },
    {
      title: formatTitle("Comment"),
      dataIndex: "comments",
      width: 200,
      key: "comments",
      render: initialCellValue
    },

  ];

  const openQR = (e: MouseEvent) => {
    e.preventDefault();
    setIsQRModalOpen(true);

  };

  const validateMessages = {
    required: 'Please enter ${label}.',
    types: {
      email: 'Please provide a valid email.',
      number: 'Please provide a valid number.',
    },
    number: {
      range: '${label} must be between ${min} and ${max}.',
    },
  };

  const renderOption = (type: string, editAssetModal?: boolean) => {
    // console.log("renderOption modalData ", modalData);

    if (type == 'category') {

      return getSelectorOptions(cateTypesList, 'Select Asset Category', 'asset_category',)

    } else if (type == 'type') {
      // edit asset modal category types
      if (editAssetModal) {
        if (modalData.asset_category !== '') {
          const curTypeItem = cateTypesList.filter((item: any) => item.asset_category == modalData.asset_category)[0]
          if (curTypeItem) {
            const curTypeList = curTypeItem.asset_types
            // console.log('curTypeList', curTypeList)
            return getSelectorOptions(curTypeList, "Select Asset Type")
          }

        }
      }
      // asset filters changes
      else {
        if (filters.asset_category !== '') {
          const curTypeItem = cateTypesList.filter((item: any) => item.asset_category == filters.asset_category)[0]
          if (curTypeItem) {
            const curTypeList = curTypeItem.asset_types
            // console.log('curTypeList', curTypeList)
            return getSelectorOptions(curTypeList, "Select Asset Type")
          }

        }
      }


    } else if (type == 'customer') {

      return getSelectorOptions(customerList, 'Select Customer', 'customer_ID', 'bus_name')

    } else if (type == 'status') {

      const status = Object.keys(ASSET_STATUS)
      return getSelectorOptions(status, 'Select Status')

    } else if (type == 'alert_response') {
      const keys: any = Object.keys(ASSET_ALERT_RESPONSE_TYPES).map((item: string) => ({ label: ASSET_ALERT_RESPONSE_TYPES[item], value: item }))
      return getSelectorOptions(keys, "Select Response Type", 'value', 'label')

    } else if (type == 'notify') {
      const optArr = notifyList.map((item: any,) => ({
        notify_id: `${item.name}#${item.email}#${item.number}`,
        notify_name: item.name
      }))
      return getSelectorOptions(optArr, 'Select Notify', 'notify_id', 'notify_name')

    } else if (type == 'alert_types') {
      const optArr = alertTypesList.map((item: any,) => ({
        value: `${item.type}#${item.attribute}#${item.placeholder}`,
        label: item.placeholder
      }))
      return getSelectorOptions(optArr, 'Select Measurement Type', 'value', 'label')


    } else if (type == 'geofence') {
      const optArr = geofenceList.map((item: any,) => item.location_name)
      return getSelectorOptions(optArr, 'Select Geofence')

    } else if (type == 'alert_presets') {
      return getSelectorOptions(alertPresetList, 'Select Alert Preset', 'preset_refID', 'preset_name')

    } else if (type == 'asset_presets') {
      return getSelectorOptions(assetPresetList, 'Select Asset Preset', 'preset_refID', 'preset_name')



    } else if (type == 'sensors') {
      return getSelectorOptions(sensorList, 'Select Sensor', 'deviceID')

    } else if (type == 'bool') {

      const status = Object.keys(BOOL_STATUS).map((item: string) => ({ value: BOOL_STATUS[item], label: item }))
      return getSelectorOptions(status, 'Select', 'value', 'label')

    }





    return getSelectorOptions([], 'Select')

  }

  const handleSearch = (latestFilters?: any) => {
    setDoSearch(!doSearch)
  }

  const handleDisposalModalOpen = (oneRecord: any = null) => {
    // if open from the row, the record will be passed in 
    if (oneRecord) {
      setIsSingleDisposalModalOpen(true)
    } else {
      if (selectedAssets.length == 1) {
        setIsSingleDisposalModalOpen(true)
      } else if (selectedAssets.length > 1) {
        setIsMultipleDisposalModalOpen(true)
      } else {
        message.warning('Please select at least one asset to dispose')
      }

    }

  }

  const closeDisposalModal = () => {
    setIsSingleDisposalModalOpen(false)
    setIsMultipleDisposalModalOpen(false)
    setSelectedAssets([])
    setSelectedRowKeys([])
  }

  const renderSwitchViewPages = (): JSX.Element => {
    switch (view) {
      case VIEWS.MAP:
        return <AssetMap
          searchText={searchText}
          doSearch={doSearch}
          openModal={openDetailsModal}
        />;
      default:
        return <AssetTable
          searchText={searchText}
          doSearch={doSearch}
          isLoading={isLoading}
          setModalData={(record: any, viewOnly: boolean) => setAssetDetailsModal({ ...assetDetailsModal, modalData: record, viewOnly })}
          openModal={openDetailsModal}
          initialize={initialize}
          selectedAssets={selectedAssets}
          setSelectedAssets={setSelectedAssets}
          setSelectedRowKeys={setSelectedRowKeys}
          selectedRowKeys={selectedRowKeys}
          statusToFilter={selectedStatus}
          handleDisposalModalOpen={handleDisposalModalOpen}
        />;
    }
  };

  const flexOption = {
    column: !screen.md,
    flexBetween: !screen.md && true,
    alignStart: !screen.md && true,
    gap: screen.md ? "" : "15px"
  }


  return (
    <>

      <Toaster />
      <ImgDiv2>
        <img src={assetManagementFlow} alt='assetManagementFlow' style={{ width: '100%' }} />
      </ImgDiv2>
      {/* <div style={{ marginBottom: '20px' }}>
        <DisplayStats data={cardsData} />
      </div> */}
      <div style={{ marginBottom: '20px' }}>
        <Analytics items={analyticsInfo} />
      </div>
      <TableCard>
        <TrackGridTwo style={{ marginRight: 0, padding: '0 10px' }}>
          <FlexWrapper flexStart {...flexOption} >
            <ToggleDiv style={{ width: '200px' }}>
              {/* <Icon border={false}> */}
              {tabsData.map((item: any) => {
                return (
                  <>
                    <Toggle border={view === item.key ? true : false}
                      onClick={() => {
                        if (!assetDetailsModal.isOpen) {
                          setView(item.key);
                          // setShowAddBtns(false)
                          setClose();
                        }
                      }}
                    >
                      <span style={{ marginRight: '5px' }}><img src={item.img} /></span>  {item.label}
                    </Toggle>
                  </>
                );
              })}
              {/* </Icon> */}
            </ToggleDiv>
            <div style={{ marginLeft: screen.md ? '20px' : "0", width: "400px" }}>
              <Input
                placeholder="Search by Asset Name or ID"
                name="search"
                type="search"
                value={filters.asset_ID}
                onChange={(e: any) => {
                  setSearchText(e.target.value)
                  // onSelectType(e.target.value, 'search')
                }}
                onSearch={() => handleSearch()}
                allowClear
              />
            </div>
          </FlexWrapper>
          {
            !isAmReadOnly &&
            <FlexWrapper
              flexEnd={screen.md}
              flexStart={!screen.md}
              style={{
                // width: view == VIEWS.TABLE ? '95%' : 'unset',
                position: 'relative',
                marginTop: screen.md ? "" : "10px",
              }}>
              {" "}
              {/* {view == VIEWS.MAP ? <SimpleButton text="Reset Map" onClick={handleResetMap} style={{ marginRight: '1rem' }} /> : null}
          <SimpleButton text="Add an Asset" onClick={handleSingleAssetPopUp} /> */}
              <SimpleButton
                outlined
                text="Dispose"
                // onClick={() => setIsDisposalModalOpen(true)} 
                onClick={(e: MouseEvent) => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleDisposalModalOpen()
                }}
                style={{ marginRight: '1rem' }}
                disabled={selectedAssets.length == 0}
              />
              {/* <div onBlur={() => setShowAddBtns(!showAddBtns)}> */}
              <SimpleButton text="+ Add an Asset" onClick={(e: React.SyntheticEvent) => {
                e.stopPropagation();
                open ? setClose() : setOpen()
              }} />
              {open && (
                <ButtonsPanel onClick={(e: React.SyntheticEvent) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}>
                  <SingleBulkButton onClick={openAddAssetPopUp} >
                    <img style={{ marginRight: '1rem' }} src={singleAdd} /> Add a Single Asset
                  </SingleBulkButton>
                  <SingleBulkButton onClick={(e: MouseEvent) => openAddAssetPopUp(e, true)}>
                    <img style={{ marginRight: '1rem' }} src={bulkAdd} /> Add Bulk Assets
                  </SingleBulkButton>
                </ButtonsPanel>
              )}
              {/* </div> */}
            </FlexWrapper>
          }

        </TrackGridTwo>
        <div style={{ marginTop: '10px' }}></div>
        <FlexWrapper>{renderSwitchViewPages()}</FlexWrapper>
      </TableCard>
      <DisposalAsset
        open={isSingleDisposalModalOpen}
        selectedAssets={selectedAssets}
        onCancel={closeDisposalModal}
        initialize={initialize}
        setDoSearch={setDoSearch}
        doSearch={doSearch}
      />
      <MultipleDisposalModal
        open={isMultipleDisposalModalOpen}
        selectedAssets={selectedAssets}
        onCancel={closeDisposalModal}
        initialize={initialize}
      />
      <SingleAssetModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        openModal={openModal}
        setOpenModal={setOpenModal}
        assetData={assetData}
        setAssetData={setAssetData}
        assetDetailsModal={assetDetailsModal}
        setAssetDetailsModal={setAssetDetailsModal}
        attachDoc={attachDoc}
        setAttachDoc={setAttachDoc}
        isQRModalOpen={isQRModalOpen}
        setIsQRModalOpen={setIsQRModalOpen}
        handleSelectAssetPreset={handleSelectAssetPreset}
        alertPresetColumns={alertPresetColumns}
        maintenanceRulesColumns={maintenanceRulesColumns}
        initialize={initialize}
        renderOption={renderOption}
      />
      <BulkAssetModal
        isModalOpenBulk={isModalOpenBulk}
        setIsModalOpenBulk={setIsModalOpenBulk}
        assetDataBulk={assetDataBulk}
        setAssetDataBulk={setAssetDataBulk}
        renderOption={renderOption}
        handleSelectAssetPreset={handleSelectAssetPreset}
        validateMessages={validateMessages}
        alertPresetColumns={alertPresetColumns}
        maintenanceRulesColumns={maintenanceRulesColumns}
        initialize={initialize}
      />

      <AssetDetailsModal
        open={assetDetailsModal.isOpen}
        modalData={modalData}
        setModalData={(record: any) => setAssetDetailsModal({ ...assetDetailsModal, modalData: record })}
        onClose={() => setAssetDetailsModal({ ...assetDetailsModal, modalData: {}, isOpen: false, viewOnly: false })}
        validateMessages={validateMessages}
        renderOption={renderOption}
        view={view}
        // isGraphOpen={isGraphOpen}
        // setIsGraphOpen={setIsGraphOpen}
        setGeofenceList={setGeofenceList}
        viewOnly={assetDetailsModal.viewOnly}
        assetDetailsModal={assetDetailsModal}
        setAssetDetailsModal={setAssetDetailsModal}
        setAlertPresetList={setAlertPresetList}
        setNotifyList={setNotifyList}
        setAlertTypesList={setAlertTypesList}
        setAssignAlertModalOpen={setAssignAlertModalOpen}
        openQR={openQR}
        assetData={assetData}
        initialize={initialize}
      />

      <AssignAlertPresetModal
        open={assignAlertModalOpen}
        alertModalData={alertModalData}
        setAlertModalData={setAlertModalData}
        renderOption={renderOption}
        allTypes={allTypes}
        setAllTypes={setAllTypes}
        modalData={modalData}
        setAssignAlertModalOpen={setAssignAlertModalOpen}
        assetDetailsModal={assetDetailsModal}
        setAssetDetailsModal={setAssetDetailsModal}
      />

      <QRModal
        open={isQRModalOpen}
        setIsQRModalOpen={setIsQRModalOpen}
        modalData={modalData}
      />

    </>
  );



};

export default AssetsTrack;

const ButtonsPanel = styled.div`
  position: absolute;
  top:100%;
  right:0;
  width: 20rem;
  z-index: 401;
`
const SingleBulkButton = styled.div`
  background-color: var(--dropdown-bg-color);
  color: white;
  font-size: 14px;
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const ImgDiv2 = styled.div`
  display: flex;
  // height: 68px;
  width: 565px;
  margin: 10px 0px 50px 5px;
`;


