import { ASSET_MANAGEMENT_API as AM_API } from "utilities/AdiApi";
import { logError } from '../../RelationshipManagement/utilities/functions'
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";

const GeofenceAPI = {

  getAllGeofence: async () => {

    try {
      const url = `${getBizCode()}/geofence`
      const res = await AM_API.get(url)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }

  },
  addGeofence: async (payload: any) => {

    try {
      const url = `${getBizCode()}/geofence`
      const res = await AM_API({
        url,
        method: "POST",
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  updateGeofence: async (payload: any) => {

    try {
      const url = `${getBizCode()}/geofence`
      const res = await AM_API({
        url,
        method: "PUT",
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  deleteGeofence: async (location_name: string) => {

    try {
      const url = `${getBizCode()}/geofence?location_name=${location_name}`
      const res = await AM_API.delete(url)
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },


}

export default GeofenceAPI