import AWS from 'aws-sdk'
import React from 'react'
import { getBizCode, getSessionStorage } from 'utilities/Functions/GlobalHelperFunctions'

enum S3_MODULE_CODE {
  am = 'am',
  crm = 'crm',
  osm = 'osm',
  cms = 'cms',
  vct = 'vct',  // value chain tracking
  ce = 'ce',    // customer engagement
}



/**
 * Features:
 * 1. Construct s3 key based on module and given path
 * 2. Upload a single file to s3 function
 * 3. Delete a single file or a directory from s3 function
 * 4. Get file name from s3 key
 * 5. Get download url from s3 key
 * 6. Get download file list to display files
 */
const useFileUpload = () => {
  const BIZ_CODE = getBizCode()

  const accessKeyId = getSessionStorage("AccessKeyId")
  const accessKey = getSessionStorage("AccessKeySec")
  const sessionToken = getSessionStorage("sessionToken")
  const bucketName = getSessionStorage("USER_STORAGE")

  AWS.config.update({
    accessKeyId: accessKeyId,
    secretAccessKey: accessKey,
    sessionToken: sessionToken,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: bucketName },
    region: "ap-southeast-2",
  });

  // key used when uploading to s3
  const constructS3Key = (moduleCode: S3_MODULE_CODE, path: string): string => {
    return `${BIZ_CODE}/${moduleCode}/${path}`
  }

  // s3 download url
  const constructS3Url = (s3Key: string) => {
    const cdn = process.env.REACT_APP_CDN
    return `${cdn}${s3Key}`
  }

  // remove cdn from url
  const getS3KeyFromUrl = (url: string) => {
    const cdn = process.env.REACT_APP_CDN || ''
    return url.replace(cdn, '')
  }

  const getFileNameFromS3Key = (s3Key: string) => {
    let fName = "No File Name";
    if (s3Key.length > 0) {
      const lst = s3Key.split("/");
      fName = lst[lst.length - 1];
    }
    return fName;
  };

  // Get download file list to display files
  const getDownloadFileList = (s3Keys: string[]) => {
    const downloadFiles = s3Keys.map((s3Key, index) => {
      return {
        uid: s3Key,
        name: getFileNameFromS3Key(s3Key),
        url: constructS3Url(s3Key),
      };
    });
    return downloadFiles;
  }





  // Upload a file to s3
  const uploadFileToS3 = async (selectedFile: File, key: string): Promise<any> => {

    const params: AWS.S3.PutObjectRequest = {
      ACL: "bucket-owner-full-control",
      Body: selectedFile,
      Bucket: bucketName, // replace with session storage current user s3 bucket
      Key: key,
    };

    return new Promise((resolve, reject) => {
      myBucket
        .putObject(params)
        .on("httpUploadProgress", (evt, response) => {
          // console.log(Math.round((evt.loaded / evt.total) * 100));
          // console.log(response);
        })
        .send((err, data) => {
          if (err) {
            console.log("Upload to S3 err", err);
            return reject(err);
          } else {
            // console.log("Uploaded to s3 data:=====>", data);
            return resolve(data);
          }
        });
    });
  }

  // Delete a file or a directory from s3
  const deleteFromS3 = async (key: string, deleteDir?: boolean) => {

    // delete the whole directory
    if (deleteDir) {
      let listParams: AWS.S3.ListObjectsV2Request = {
        Bucket: bucketName,
        Prefix: key,
      }
      const listedObjects = await myBucket.listObjectsV2(listParams).promise();

      if (listedObjects.Contents?.length === 0) {
        // deletion completed if there are nested directories

        return Promise.resolve();
      }

      const deleteParams: AWS.S3.DeleteObjectsRequest = {
        Bucket: bucketName,
        Delete: { Objects: [] },
      };

      // get files that need to be deleted under the dir
      for (let i = 0; i < listedObjects.Contents!.length; i++) {
        let key = listedObjects.Contents![i].Key!
        deleteParams.Delete.Objects.push({ Key: key });
      }
      // This gives error
      // listedObjects?.Contents?.forEach(({ key }: any) => {
      //   deleteParams.Delete.Objects.push({ Key: key })
      // });

      myBucket.deleteObjects(deleteParams, function (err, data) {
        if (err) {
          console.log(err, err);
          // token expires
          // if (err.response.status === 400) {
          //   handleSessionExpire()
          //   return;
          // }
          // an error occurred
          return Promise.reject(err);
        } else {
          // successful response for deletion of one directory
          return Promise.resolve(data);
        }
      });

      // deleting recursively in case there are still keys (nested directories)
      if (listedObjects.IsTruncated)
        await deleteFromS3(key, deleteDir);
    } else {
      // delete an individual file
      let params = {
        Bucket: bucketName,
        Key: key,
      };
      try {
        myBucket.deleteObject(params, function (err, data) {
          if (err) {
            // error
            console.log(err);
            // token expires

            return Promise.reject(err);
          } else {
            // console.log('deleted Data', data); // deleted
            return Promise.resolve(data);
          }
        });
      } catch (e) {
        console.log("Error ", e);
        // token expires
        // if (e.response.status === 400) {
        //   handleSessionExpire()
        //   return;
        // }
        return Promise.reject(e);
      }
    }
  }

  const generatePresignedUrl = (key: string, expiresIn: number = 3600) => {
    const params = {
      Bucket: bucketName,
      Key: key,
      Expires: expiresIn,
    };

    return myBucket.getSignedUrl('getObject', params);
  };

  const fileHandler = {
    constructS3Key,
    uploadFileToS3,
    deleteFromS3,
    constructS3Url,
    getFileNameFromS3Key,
    getDownloadFileList,
    getS3KeyFromUrl,
    generatePresignedUrl,
    S3_MODULE_CODE,
  }

  return [fileHandler]
}

export default useFileUpload