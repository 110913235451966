import React from 'react'
import styled from 'styled-components';
import { Descriptions } from 'antd';
import 'antd/dist/antd.css';
import GlobalModal from 'components/GlobalModal';
import { initialDateTimeValue } from 'utilities/Functions/FormatFunctions';
import { isPetunaData } from '../../Utils/functions';
import { useAuthStore } from 'zustand-stores';


type OriginalProps = {
  open: boolean;
  onClose: Function;
  name: string;
  itemData?: any;
}

const EventFlowInfoModal = (props: OriginalProps) => {
  const { open, onClose, name, itemData } = props

  const isAcgUser = useAuthStore.getState().isAcgUser
  const isAccUser = useAuthStore.getState().isAccUser

  const toEPCInformationValues = (singleLotInfo: any): any[] => {

    console.log('toEPCInformationValues singleLotInfo', singleLotInfo)


    const PACK_TYPE = isPetunaData(isAcgUser, isAccUser) ? ['Pallet', 'Case', 'Pack', 'Batch'] : ['Animal', 'Livestock', 'Carcase', 'Primary Cut', 'Carton']

    let Values: any = []

    const renderLabel = (packType: string) => {
      let label: string = ''

      switch (packType) {
        case 'Pallet':
          label = `${packType} ID`
          break;
        case 'Case':
          label = `${packType} ID`
          break;
        case 'Pack':
          label = `${packType} ID`
          break;
        case 'Batch':
          label = `${packType} ID`
          break;
        case 'Animal':
          label = `Livestock ID`
          break;
        case 'Livestock':
          label = `Livestock ID`
          break;
        case 'Carcase':
          label = `${packType} ID`
          break;
        case 'Primary Cut':
          label = `${packType} ID`
          break;
        case 'Carton':
          label = `${packType} ID`
          break;
        default:
          break;
      }

      return label
    }


    const extraInfoForAnimal = [
      {
        label: 'Gender',
        value: singleLotInfo?.gender ? singleLotInfo?.gender : ''
      },
      {
        label: 'Ear Tag',
        value: singleLotInfo?.earTag ? singleLotInfo?.earTag : ''
      },
      {
        label: 'Establishment Number',
        value: singleLotInfo?.establishmentNumber || 'N/A'
      },
      {
        label: 'Barcode',
        value: singleLotInfo?.barcode ? singleLotInfo?.barcode : ''
      },
    ]

    if (PACK_TYPE.includes(singleLotInfo?.packType)) {
      Values = [
        {
          label: 'EPC',
          value: singleLotInfo?.epc
        },
        {
          label: 'Type',
          value: `${singleLotInfo?.packType}`
          // value: `${singleLotInfo?.packType === 'Animal' ? 'Livestock' : singleLotInfo?.packType}`
        },
        {
          label: renderLabel(singleLotInfo?.packType),
          value: singleLotInfo?.packID
        },
        {
          label: 'Batch/Lot Number',
          value: singleLotInfo?.productLot
        },
        {
          label: 'Quantity',
          value: singleLotInfo?.quantity
        },
        {
          label: 'Weight',
          value: singleLotInfo?.productWeight
        },
        {
          label: 'UoM',
          value: singleLotInfo?.uom
        },
        {
          label: 'Third Party Company Name',
          value: singleLotInfo?.involvedThirdPartyCompanyName
        },
        {
          label: 'Third Party Company Role',
          value: singleLotInfo?.involvedThirdPartyCompanyRole
        },

      ];

      Values = isPetunaData(isAcgUser, isAccUser) ? Values : [...Values, ...extraInfoForAnimal]

    } else {
      if (isPetunaData(isAcgUser, isAccUser)) {

        Values = [
          {
            label: 'EPC',
            value: singleLotInfo?.epc
          },
          {
            label: 'Fish Group',
            value: singleLotInfo?.fishGroup
          },
          {
            label: 'Species',
            value: singleLotInfo?.species
          },
          {
            label: 'Catch Method',
            value: singleLotInfo?.catchMethod
          },
          {
            label: 'Harvest ID',
            value: singleLotInfo?.harvestID
          },
          {
            label: 'Harvest Date',
            value: initialDateTimeValue(singleLotInfo?.harvestStartDate)
          }
        ];
      }
      else {

        Values = [

          {
            label: 'Animal ID',
            value: '3243546'
          },
          {
            label: 'Establishment Number',
            value: 'QKMM0474' || 'N/A'
          },
          {
            label: 'Gender',
            value: 'Male' || 'N/A'
          },
          {
            label: 'Ear Tag',
            value: 'N/A'
          },
          {
            label: 'Barcode',
            value: 'B#5462357'
          },
          {
            label: 'Regulatory Compliance',
            value: 'Brands Act, The Model Food Act, Food Act 2016' || 'N/A'
          }
        ];
      }
    }

    const filteredValues = Values?.filter((item: any) => item.value !== '' && item.value !== null)

    return filteredValues

  }

  const showDetail = (event: any) => {
    console.log('Event clicked ', event)

    let eventInfo;

    if (!isPetunaData(isAcgUser, isAccUser) && event?.type === 'TransformationEvent') {
      eventInfo = {
        ...event?.lot_info[1],
        barcode: event?.barcode,
        involvedThirdPartyCompanyName: event?.involvedThirdPartyCompanyName,
        involvedThirdPartyCompanyRole: event?.involvedThirdPartyCompanyRole
      }
    } else {
      eventInfo = {
        ...event?.lot_info[0],
        barcode: event?.barcode,
        involvedThirdPartyCompanyName: event?.involvedThirdPartyCompanyName,
        involvedThirdPartyCompanyRole: event?.involvedThirdPartyCompanyRole
      }
    }





    const data = toEPCInformationValues(eventInfo)?.map((data: any, index: any) => {
      return (
        <React.Fragment key={index}>
          <Descriptions.Item label={data.label}>{data.value}</Descriptions.Item>
        </React.Fragment>
      )
    })

    return data
    // if (event?.type.toLowerCase() == 'ObjectEvent'.toLowerCase()) {
    //   return printEpc(event.epcList, 'Epc List')
    // } else if (event.type.toLowerCase() == 'AggregationEvent'.toLowerCase()) {
    //   return printEpc(event.childEPCs, 'Child Epc List')
    // } else if (event.type.toLowerCase() == 'TransformationEvent'.toLowerCase()) {
    //   return <>{printEpc(event.inputEPCList, 'Input Epc List')}
    //     {printEpc(event.outputEPCList, 'Output Epc List')}</>
    // } else {
    //   return <Span>Not Sopported</Span>
    // }
  }

  // const printEpc = (epcs: any, tittle: string) => {
  //   return (<>
  //     <Descriptions.Item label={tittle}>{''}</Descriptions.Item>

  //     {epcs?.map((item: any, index: number) => <>
  //       <Descriptions.Item key={index} label={`${index + 1}.`}>
  //         <FlexWrapper flexStart gap='6px'>
  //           <Span>{item.epc}</Span>
  //           <img src={linkVctIcon} alt='Link Icon' />
  //         </FlexWrapper>
  //       </Descriptions.Item>
  //     </>)}
  //   </>)
  // }

  return (
    <GlobalModal
      open={open}
      onCancel={onClose}
      title={`${name}`}
      hideFooter
    >
      <div style={{
        border: '1px solid #383A56',
      }}>
        <StyledDescriptions
          column={1}
          layout="horizontal"
        >
          {itemData && showDetail(itemData.event)}
        </StyledDescriptions>

      </div>
    </GlobalModal>
  )
}


export default EventFlowInfoModal

export const StyledDescriptions = styled(Descriptions)`
  padding-bottom: 10px;
    .ant-descriptions-title {
      color: var(--white-text-color) !important;
    }
    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      background-color: transparent !important;
      padding: 6px 12px !important;
    }
    .ant-descriptions-bordered,
    .ant-descriptions-view {
      border: unset !important;
    }
    .ant-descriptions-bordered,
    .ant-descriptions-item-label {
      border-right: unset !important;
      color: var(--dark-dark-4, #C7C9D9) !important;
      font-size: 12px !important;
      padding: 6px 15px !important;
      width: 200px !important;
    }
    .ant-descriptions-bordered,
    .ant-descriptions-item-content {
      color: var(--light-light-2, #F2F2F5) !important;
      font-size: 12px !important;
    }
    .ant-descriptions-bordered,
     .ant-descriptions-row {
      border-bottom: 2px dashed #383A56 !important;
    }
  
   `;

// const Span = styled.span`
//    cursor: pointer;
//      &:hover {
//       color: var(--primary-color) !important;
//       text-decoration: underline !important;
//     }
//       `;