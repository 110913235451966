import React, { useReducer } from 'react'
import moment from 'moment';


export const FilterType = {
    SEARCH_TEXT: 'SEARCH_TEXT',
    ORDERED_DATE: 'ORDERED_DATE',
    SET_DATA: 'SET_DATA',
    APPLY_ALL: 'APPLY_ALL',
}

type FilterState = {
    filteredData: any,
    customerOrderData: any[],
    purchaseOrderData: any[],
    searchedText: string,
    dateRange: [string, string],
    pageType?: 'Procurement',
}

const FilterReducer = (state: FilterState, action: any) => {
    switch (action.type) {
        case FilterType.SET_DATA:
            return { ...state, ...action.payload }
        case FilterType.SEARCH_TEXT:
            state.searchedText = action.payload
            // handleSearchText(state, action.payload)
            applyAllFilters(state)
            return { ...state }
        case FilterType.ORDERED_DATE:
            state.dateRange = action.payload
            // filterByDate(state, action.payload)
            applyAllFilters(state)
            return { ...state }
        case FilterType.APPLY_ALL:
            applyAllFilters(state, action.payload)
            return { ...state }
        default:
            return { ...state }
    }
}

const useTableFilterss = (pageType?: 'Procurement') => {
    const [filterState, dispatch] = useReducer(FilterReducer, {
        pageType: pageType,
        searchedText: '', // empty string means global search. no limitation of searched result.
        dateRange: ['', ''],
        filteredData: [],
        customerOrderData: [],
        purchaseOrderData: [],
    })

    return [filterState, dispatch] as const
}

export default useTableFilterss

// Search in all tables in terms of order number
const handleSearchText = (state: FilterState, searchText: string, data?: any) => {
    const somPageTab = state.pageType

    let result: any = []
    if (somPageTab === 'Procurement') {
        const dataSource = data || state.purchaseOrderData
        const filtered = dataSource.filter((purchaseOrder: any) => {
            const orderNumber = purchaseOrder?.order_ID?.toLowerCase() || ''
            const supplierName = purchaseOrder?.supplier_name?.toLowerCase() || ''
            const search = searchText?.toLowerCase() || ''
            return supplierName.includes(search) || orderNumber.includes(search)
        })
        result = filtered
    } else {
        const dataSource = data || state.customerOrderData
        const filtered = dataSource.filter((customerOrder: any) => {
            const orderNumber = customerOrder?.order_ID?.toLowerCase() || ''
            const search = searchText?.toLowerCase() || ''
            return orderNumber.includes(search)
        })
        result = filtered
    }
    // state.filteredData = result
    return result
}

// filter by order date
const filterByDate = (state: FilterState, date: [string, string], data?: any) => {
    let result: any = {}
    const somPageTab = state.pageType

    // the date parameter should pass in two Australian format date string 'DD/MM/YYYYY'
    // thus convert them to 'YYYY-MM-DD' to compare with the backend 'YYYY-MM-DD' date

    const startDate: string = moment(date[0], 'DD/MM/YYYY').format('YYYY-MM-DD') || ''
    const endDate: string = moment(date[1], 'DD/MM/YYYY').format('YYYY-MM-DD') || ''

    if (somPageTab === 'Procurement') {
        const dataSource = data || state.purchaseOrderData
        const filtered = dataSource.filter((purchaseOrder: any) => {
            const creationDate: string = moment(purchaseOrder?.order_date).format('YYYY-MM-DD') || ''
            // compare dates
            return creationDate >= startDate && creationDate <= endDate
        })
        result = filtered
        // no filter, assign all contact
        if (date[0] === '' && date[1] === '') {
            result = dataSource
        }

    } else {
        const dataSource = data || state.customerOrderData
        const filtered = dataSource.filter((customerOrder: any) => {
            const creationDate: string = moment(customerOrder?.order_date).format('YYYY-MM-DD') || ''
            // compare dates
            return creationDate >= startDate && creationDate <= endDate
        })

        result = filtered
        // no filter, assign all contact
        if (date[0] === '' && date[1] === '') {
            result = dataSource
        }
    }
    // state.filteredData = result
    return result

}

// apply all filters
const applyAllFilters = (state: FilterState, data?: any) => {
    const somPageTab = state.pageType
    let result = []
    if (somPageTab === 'Procurement') {
        if (data) state.purchaseOrderData = data  // initialise data souce
        result = state.purchaseOrderData  // default data
    } else {
        if (data) state.customerOrderData = data
        result = state.customerOrderData
    }
    console.log('result==>', result)
    // apply all filters
    result = handleSearchText(state, state.searchedText, result)
    result = filterByDate(state, state.dateRange, result)
    state.filteredData = result
}
