/**
 * @description Company table view page, displays the table of companies
 * @version 1.0.0
 * @author Bruce Zhu
 */

import React, { useState, useEffect, useMemo } from "react";

import {
  FilterHeader,
  AllCompaniesTable,
  LeadTable,
  CreateCompany,
} from "../../components";
import { CompanyAPI, BusinessAPI, LeadAPI } from "../../apis";
import { message } from "antd";
import { useTablePageFilters } from "../../hooks";
import { FilterType } from "../../hooks/useTablePageFilters";
import { ADI_CONFIRM } from "utilities/Functions/GlobalHelperFunctions";

import DisplayStats from "components/Statistics/DisplayStats";
import FlowInfoPanel from "components/FlowInfoPanel";
import { COMPANY_FLOW_STEPS } from "../../statics/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { CRM_ROUTES } from "../../CRMRoutes";
import { getBusinessIdKey } from "../../utilities/functions";
import { useGeneralStore } from "zustand-stores";
import { GeneralStore } from "zustand-stores/useGeneralStore";
import TableCard from "components/AdiTable/TableCard";
import ActiveAssetIcon from '../../../../../assets/active_assets.svg';
import { Analytics } from 'components/Analytics';

const columnSelectOptions = {
  allCompanies: [
    {
      text: "Email",
      valueKey: "bus_email",
    },
    {
      text: "Phone Number",
      valueKey: "bus_phone",
    },
    {
      text: "Date Created",
      valueKey: "creation_date",
    },
    {
      text: "Status",
      valueKey: "active",
    },
  ],
  leads: [
    {
      text: "Stage",
      valueKey: "stage",
    },
    {
      text: "Country",
      valueKey: "country",
    },
    {
      text: "Assigned to",
      valueKey: "assigned_to_name",
    },
    {
      text: "Last contacted",
      valueKey: "last_contacted",
    },
    {
      text: "Status",
      valueKey: "active",
    },
  ],
};

const defaultTablesSelectColumns = {
  all_table: columnSelectOptions.allCompanies.map((item) => item.valueKey),
  lead_table: columnSelectOptions.leads.map((item) => item.valueKey),
};

const Company = () => {
  const navigate = useNavigate();
  const setIsGlobalLoading = useGeneralStore(
    (state: GeneralStore) => state.setIsGlobalLoading
  );
  const [modals, setModals] = useState({
    open: false,
    isEdit: false,
    companyToEdit: null,
  });
  const [updatePivot, forceUpdate] = React.useReducer((a) => a + 1, 0);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedTableColumns, setSelectedTableColumns] = useState<{
    all_table: any[];
    lead_table: any[];
  }>(defaultTablesSelectColumns);

  // custom hook that handles all the filter logic
  const [filterState, filterDispatch] = useTablePageFilters("company");
  // handles api requests
  const api = useAPI();
  const [selectedStatus, setSelectedStatus] = useState<string>('all');
  const [filteredData, setFilteredData] = useState<any>(filterState.filteredData);
  const [activeTab, setActiveTab] = useState<any>('All');

  // Load companies data
  useEffect(() => {
    getAllCompanies();
  }, [updatePivot]);

  const getAllCompanies = async () => {
    try {
      setIsLoading(true);
      const data = await api.getAllCompanies();
      filterDispatch({ type: FilterType.APPLY_ALL, payload: data });
    } catch (e) {
      message.error("Failed to get companies data.");
    } finally {
      setIsLoading(false);
    }
  };

  const deleteCompany = (record: any) => {
    ADI_CONFIRM({
      customizeTitle: `Are you sure to delete ${record?.bus_name || ""}?`,
      isdelete: true,
      onConfirm: () => {
        setIsGlobalLoading(true);
        api
          .deleteCompany(record)
          .then((res) => {
            message.success({ content: "Company deleted successfully!" });
            forceUpdate();
          })
          .catch((e) => {
            message.error({ content: "Failed to delete company." });
          })
          .finally(() => {
            setIsGlobalLoading(false);
          });
      },
    });
  };

  const allCompaniesTableActions = {
    onConvertSuccess: getAllCompanies,
    edit: (record: any) =>
      setModals({ ...modals, open: true, isEdit: true, companyToEdit: record }),
    delete: (record: any) => deleteCompany(record),
  };

  const leadTableActions = {
    onConvertSuccess: getAllCompanies,
    edit: (record: any) =>
      setModals({
        ...modals,
        open: true,
        isEdit: true,
        companyToEdit: { ...record, businessType: "lead" },
      }),
    delete: (record: any) => deleteCompany(record),
  };

  const switchToDetailsPage = (company: any) => {
    if (!company.businessType) {
      // Lead table does not have businessType field, set it manually
      company.businessType = "Lead";
    }
    const idKey = getBusinessIdKey(company.businessType.toLowerCase());

    const url = `${CRM_ROUTES.COMPANY_DETAILS}/${company[idKey]
      }?objectType=company&businessType=${company.businessType.toLowerCase()}`;

    navigate(url, {
      state: { company },
    });
  };

  const resetTableColumn = () => {
    setSelectedTableColumns(defaultTablesSelectColumns);
  };

  const handleColumnSelect = (e: any, type: "lead_table" | "all_table") => {
    setSelectedTableColumns({
      ...selectedTableColumns,
      [type]: e,
    });
  };

  const handleToggle = (toggleTo: string) => {
    filterDispatch({ type: FilterType.TOGGLE, payload: toggleTo });
    resetTableColumn();
  };

  const statsData = useMemo(() => {
    const unassignedNum = filterState.allCompaniesData?.Companies?.length || 0;
    const leadNum = filterState.allCompaniesData?.Leads?.length || 0;
    const customerNum = filterState.allCompaniesData?.Customers?.length || 0;
    const supplierNum = filterState.allCompaniesData?.Suppliers?.length || 0;
    const totalNum = unassignedNum + leadNum + customerNum + supplierNum;

    return [
      // {
      //   text: 'Summary'
      // },
      {
        text: "Total Companies",
        number: totalNum,
      },
      {
        text: "Leads",
        number: leadNum,
      },
      // {
      //   text: 'Unassigned',
      //   number: unassignedNum
      // },
      {
        text: "Customers",
        number: customerNum,
      },
      {
        text: "Suppliers",
        number: supplierNum,
      },
    ];
  }, [filterState.allCompaniesData]);

  console.log("filterState", filterState);

  const getValueByTitle = (title: string) => {
    const item = statsData?.find((item: any) => item.text === title);
    return item ? item.number : 0;
  };

  const handleAnalyticsClick = (status: string) => {
    setSelectedStatus(status);
  };

  useEffect(() => {
    if (selectedStatus) {
      if (selectedStatus === 'all') {
        setActiveTab('All');
        setFilteredData(filterState.filteredData);
      } else if (selectedStatus === 'Customers') {
        setActiveTab('All');
        const filtered = filterState.filteredData.Customers;
        setFilteredData({ 'Customers': filtered });
      } else if (selectedStatus === 'Suppliers') {
        setActiveTab('All');
        const filtered = filterState.filteredData.Suppliers;
        setFilteredData({ 'Suppliers': filtered });
      } else if (selectedStatus === 'Leads') {
        console.log('filterState.filteredData Leads', filterState.filteredData)
        const filtered = filterState.filteredData.Leads;
        setFilteredData({ 'Leads': filtered });
        setActiveTab('Leads');
      }
    } else { setFilteredData(filterState.filteredData); }
  }, [selectedStatus, filterState.filteredData]);

  useEffect(() => {
    if (filterState.companyToggle === 'All') {
      setActiveTab('All');
    }
    else {
      setActiveTab('Leads');
    }
  }, [filterState.companyToggle]);

  const analyticsInfo = [
    {
      icon: <img src={ActiveAssetIcon} alt="AllGridIcon" />,
      title: 'Total Companies',
      value: getValueByTitle('Total Companies'),
      onClick: () => handleAnalyticsClick('all'),
    },
    {
      icon: <img src={ActiveAssetIcon} alt="AllGridIcon" />,
      title: 'Leads',
      value: getValueByTitle('Leads'),
      onClick: () => handleAnalyticsClick('Leads'),
    },
    {
      icon: <img src={ActiveAssetIcon} alt="AllGridIcon" />,
      title: 'Customers',
      value: getValueByTitle('Customers'),
      onClick: () => handleAnalyticsClick('Customers'),
    },
    {
      icon: <img src={ActiveAssetIcon} alt="AllGridIcon" />,
      title: 'Suppliers',
      value: getValueByTitle('Suppliers'),
      onClick: () => handleAnalyticsClick('Suppliers'),
    },
  ];

  return (
    <div>
      <div style={{ marginTop: "-20px", marginBottom: "40px" }}>
        <FlowInfoPanel
          items={[...COMPANY_FLOW_STEPS]}
          style={{ minWidth: "600px", marginLeft: "15px" }}
        />
        {/* <DisplayStats data={statsData} /> */}
        <div style={{ marginBottom: '20px' }}>
          <Analytics items={analyticsInfo} />
        </div>
      </div>
      <TableCard>
        <FilterHeader
          btnOnClick={() => setModals({ ...modals, open: true })}
          handleToggle={handleToggle}
          onTextSearch={(text: string) =>
            filterDispatch({ type: FilterType.SEARCH_TEXT, payload: text })
          }
          onDateChange={(date: [string, string]) =>
            filterDispatch({ type: FilterType.CREATION_DATE, payload: date })
          }
          onTableColumnSelect={handleColumnSelect}
          selectedTablesColumns={selectedTableColumns}
          columnSelectOptions={columnSelectOptions}
        />
        {activeTab === "All" ? (
          <AllCompaniesTable
            isLoading={isLoading}
            selectedTableColumns={selectedTableColumns.all_table}
            data={filteredData}
            actions={allCompaniesTableActions}
            onRowClick={(record: any) => (record)}
          />
        ) : (
          <LeadTable
            selectedTableColumns={selectedTableColumns.lead_table}
            data={filteredData}
            actions={leadTableActions}
            onRowClick={(record: any) => switchToDetailsPage(record)}
          />
        )}
      </TableCard>
      <CreateCompany
        open={modals.open}
        isEdit={modals.isEdit}
        onClose={() =>
          setModals({
            ...modals,
            open: false,
            isEdit: false,
            companyToEdit: null,
          })
        }
        selectedCompany={modals.companyToEdit}
        setReload={getAllCompanies}
      />
    </div>
  );
};

export default Company;

// handles api requests for Company component above
const useAPI = () => {
  const getAllCompanies = async () => {
    try {
      const data = await CompanyAPI.getAllCompanies();
      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const deleteCompany = async (company: any) => {
    const { type, idKey } = getCompanyId(company);
    const id = company[idKey];

    try {
      let res: any = null;
      switch (type) {
        case "company":
          res = await CompanyAPI.deleteUnassignedCompany(id);
          break;
        case "customer":
          res = await BusinessAPI.deleteBusiness("customer", id);
          break;
        case "supplier":
          res = await BusinessAPI.deleteBusiness("supplier", id);
          break;
        case "lead":
          res = await LeadAPI.deleteLead(id);
          break;
        default:
          break;
      }
      if (res !== null) {
        return Promise.resolve(res);
      } else {
        throw Error("Failed to delete company");
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const getCompanyId = (data: any): { type: string; idKey: string } => {
    if (data.company_ID) return { type: "company", idKey: "company_ID" };
    if (data.customer_ID) return { type: "customer", idKey: "customer_ID" };
    if (data.supplier_ID) return { type: "supplier", idKey: "supplier_ID" };
    if (data.lead_ID) return { type: "lead", idKey: "lead_ID" };
    return { type: "", idKey: "" };
  };

  return {
    getAllCompanies,
    deleteCompany,
  };
};
