import { Col, Divider, Form, Row, message } from 'antd';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import GlobalModal from 'components/GlobalModal'
import GlobalDrawer from 'components/GlobalModal/GlobalDrawer';
import Input from 'components/Input';
import React, { useEffect } from 'react'
import { FlexWrapper, HeadingLabel, SubHeadingLabel } from 'statics/styles/StyledComponents';
import styled from 'styled-components';
import IssueAPI from '../apis/IssueAPI';
import EventAPI from '../apis/EventAPI';
import { convertDateAndTime, getSelectorOptions } from 'utilities/Functions/GlobalHelperFunctions';
import { Modal } from '@material-ui/core';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';

const { ModalLayout } = GlobalDrawer;

type Props = {
  handleModalClose: Function;
  isModalOpen: boolean;
  initializeAll?: Function;
  selectedIssue?: any;
  isEdit?: 'view' | 'edit' | 'resolve' | 'add';
  eventID?: string;
}
type AffectedCustodyTypes = {
  custody_name: string;
  owner_name: string;
  affected: boolean;
}[]

type FormTypes = {
  issueId: string;
  issue_datetime: string | null;
  priority: string;
  description: string;
  event_id: string;
  business_location: string;
  business_step: string;
  custody_owner: string;
  epc_id: string;
  epc_detail: any;
  epc_status: string;
  affected_events_id: string[];
  created_timestamp: string;
  affectedCustodies: any;
}

const LogIssueModal = (props: Props) => {
  const [logIssueForm] = Form.useForm();
  const { isModalOpen, handleModalClose, initializeAll, selectedIssue, isEdit, eventID } = props
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading);

  const affectedCustody = new Array(5).fill(0).map((item: any, i: number) => ({
    custody_name: `Custody_${i}`,
    owner_name: '',
    affected: true,
  }))

  // create empty inputs for the form
  const emptyLogIssueData: FormTypes = {
    issueId: '',
    issue_datetime: '',
    priority: '',
    description: '',
    event_id: '',
    business_location: '',
    business_step: '',
    custody_owner: '',
    epc_id: '',
    epc_detail: [],
    epc_status: "",
    affected_events_id: [],
    created_timestamp: '',
    affectedCustodies: []

  }
  const [logIssueData, setLogIssueData] = React.useState<FormTypes>({ ...emptyLogIssueData, affectedCustodies: affectedCustody });
  const [eventList, setEventList] = React.useState<any[]>([]);
  const [epcList, setEpcList] = React.useState<any[]>([]);

  useEffect(() => {
    const initialize = async () => {
      try {
        console.log('open loading')
        setIsGlobalLoading(true);
        let internalEventsList: any = []

        if (selectedIssue) {
          setLogIssueData(selectedIssue)
          logIssueForm.setFieldsValue({
            ...selectedIssue,
          })

          if (isEdit == 'edit') {
            const res = await EventAPI.getInternalEvents()
            const sortedEventList = Array.isArray(res) && res?.sort((a: any, b: any) => {
              return b.intEventID.localeCompare(a.intEventID)
            });
            internalEventsList = sortedEventList || []
            setEventList(sortedEventList || [])
          }

        } else if (isModalOpen) {
          const id = await IssueAPI.getId()

          logIssueForm.setFieldsValue({
            event_id: eventID ? eventID : undefined,
            issueId: id,
          })

          const res = await EventAPI.getInternalEvents()
          const sortedEventList = Array.isArray(res) && res?.sort((a: any, b: any) => {
            return b.intEventID.localeCompare(a.intEventID)
          });
          internalEventsList = sortedEventList || []
          setEventList(sortedEventList || [])

          handleEventChange(eventID, internalEventsList)
        }
      } finally {
        setIsGlobalLoading(false);
        console.log('close loading')
      }
    }

    initialize();
  }, [isModalOpen, eventID]);

  const disabledDateFunction = (current: any) => {
    return current && current < new Date().setHours(0, 0, 0, 0)
  };

  const onClose = () => {
    handleModalClose()
    logIssueForm.resetFields()
    setLogIssueData({ ...emptyLogIssueData, affectedCustodies: affectedCustody })
  }
  const handleConfirmed = async (values: any) => {

    console.log('handleConfirmed issue: ', values)
    // onClose()
    const payload = {
      ...values,
      issue_datetime: convertDateAndTime(values.issue_datetime)

    }
    console.log('handleConfirmed payload issue==>', payload)

    if (isEdit == 'add') {

      const res = await IssueAPI.addIssue(payload)

    } else if (isEdit == 'edit' || isEdit == 'resolve') {
      console.log('handleConfirmed payload pu logIssueData==>', logIssueData)
      payload.created_timestamp = selectedIssue.created_timestamp
      if (isEdit == 'resolve') {
        payload.status = 'resolved'
      }
      console.log('handleConfirmed payload pu callissue==>', payload)
      const res = await IssueAPI.updateIssue(payload)
    }


    message.success(`Issue ${isEdit == 'add' ? 'added' : 'updated'} successfully, thank you`)
    initializeAll && initializeAll()
    onClose()

  }

  const handleEventChange = (eventId: string | undefined, eventsList: any[] = eventList) => {
    if (!eventId) return;

    const selectedEvent = eventsList.find((event: any) => event.intEventID === eventId);

    if (selectedEvent) {
      logIssueForm.setFieldsValue({
        business_location: selectedEvent.currentbusinessLocation?.businessName || '',
        business_step: selectedEvent.businessStep || '',
        custody_owner: selectedEvent.currentbusinessLocation?.businessName || '',
        epc_id: selectedEvent.lotInfo?.[0]?.epc || '',
        epc_detail: selectedEvent.lotInfo || [],
      });
    }
  };

  const renderFooterBtns = () => (
    <FlexWrapper flexEnd key='0' gap='10px'>
      <SimpleButton
        text='Cancel'
        id='modal-btn-width-regular'
        onClick={() => {
          onClose()
        }}
        isCancel
      />
      {isEdit == 'view' ? null : isEdit == 'resolve' ? (
        <SimpleButton
          id='modal-btn-width-regular'
          form='logIssue-form'
          htmlType="submit"
          text={`${'Resolve'} `}
        />
      ) : (
        <SimpleButton
          id='modal-btn-width-regular'
          form='logIssue-form'
          htmlType="submit"
          text={`${isEdit == 'edit' ? 'Save' : 'Log Issue'} `}
        />
      )}

    </FlexWrapper>
  )


  const renderOptions = (type?: string) => {
    if (type == 'epc') {
      // check epcList has exist epc attribute for each item
      const epcListWithEpc = epcList?.filter((item: any) => item.epc) || []
      return getSelectorOptions(epcList, 'Select EPC', 'epc')
    }
    if (type == 'priority') {
      return getSelectorOptions(['High', 'Medium', 'Low'], '')
    }
    if (type == 'affectedEvent') {

      let affectedList = eventList
      if (logIssueForm.getFieldValue('event_id')) {
        affectedList = eventList.filter((item: any) => item.intEventID !== logIssueForm.getFieldValue('event_id'))
      }
      return getSelectorOptions(affectedList, '', 'intEventID', 'intEventID')
    }

    return getSelectorOptions(eventList, 'Select Event', 'intEventID')

  }

  const isDisabled = () => {
    return isEdit == 'view' || isEdit == 'resolve'
  }


  return (
    <>
      <GlobalDrawer
        open={isModalOpen}
        onClose={onClose}
        title={`Log Event Issue`}
        width={600}
        footer={renderFooterBtns()}
      >
        <>
          <Form
            form={logIssueForm}
            name='logIssue-form'
            onFinish={(values: any) => handleConfirmed(values)}
          >
            <ModalLayout>
              <ModalLayout.ModalSection title="Issue Details">
                <Row gutter={[15, 0]}>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='issueId'
                    >
                      <Input
                        label='Issue ID'
                        placeholder={`Issue ID`}
                        name="issueId"
                        type="text"
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='issue_datetime'
                    >
                      <Input
                        label='Date & Time'
                        placeholder={`Date & Time`}
                        name="issue_datetime"
                        disabledDate={disabledDateFunction}
                        type="date"
                        disabled={isDisabled()}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 24 }}>
                    <Form.Item
                      name='priority'
                    >
                      <Input
                        label='Priority'
                        placeholder={`Select Priority`}
                        name="priority"
                        type="select"
                        options={renderOptions('priority')}
                        disabled={isDisabled()}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 24 }}>
                    <Form.Item
                      name='issue_type'
                    >
                      <Input
                        label='Issue Type'
                        placeholder={`Select Issue Type`}
                        name="issue_type"
                        type="select"
                        options={[
                          { value: 'Missing Data', label: 'Missing Data' },
                        ]}
                        disabled={isDisabled()}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[15, 0]}>
                  <Col xs={{ span: 24 }}>
                    <Form.Item
                      name='description'
                    >
                      <Input
                        label='Detailed Description'
                        placeholder={`Add Description`}
                        name="description"
                        type="textarea"
                        disabled={isDisabled()}

                      />
                    </Form.Item>
                  </Col>
                </Row>
              </ModalLayout.ModalSection>
            </ModalLayout>
            <ModalLayout>
              <ModalLayout.ModalSection title="Event Details">
                <Row gutter={[15, 0]}>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='event_id'
                      rules={[{ required: true, message: 'Please select Event ID' }]}
                    >
                      {eventID ? (
                        <Input
                          label='Event ID'
                          name="event_id"
                          type="text"
                          disabled
                        />
                      ) : (
                        <Input
                          label='Event ID'
                          name="event_id"
                          type="select"
                          placeholder="Select Event ID"
                          options={eventList.map((event: any) => ({
                            label: event.intEventID,
                            value: event.intEventID
                          }))}
                          onChange={(value: string) => handleEventChange(value)}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='business_location'
                    >
                      <Input
                        label='Business Location'
                        name="business_location"
                        type="text"
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='business_step'
                    >
                      <Input
                        label='Business Step'
                        placeholder={`Business Step`}
                        name="business_step"
                        disabled
                        type="text"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='custody_owner'
                    >
                      <Input
                        label='Chain of custody'
                        placeholder={`Select Custody Owner`}
                        name="custody_owner"
                        type="text"
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </ModalLayout.ModalSection>
            </ModalLayout>
            <ModalLayout>
              <ModalLayout.ModalSection title="EPC Details">
                <Row gutter={[15, 0]}>
                  {/* <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='epc_type'
                    >
                      <Input
                        label='EPC Type'
                        placeholder={`EPC Type`}
                        name="epc_type"
                        type="select"
                      />
                    </Form.Item>
                  </Col> */}
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name='epc_id'
                    >
                      <Input
                        label='EPC'
                        placeholder={`EPC ID`}
                        name="epc_id"
                        type="select"
                        options={renderOptions('epc')}
                        disabled={isDisabled()}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </ModalLayout.ModalSection>
            </ModalLayout>
            <ModalLayout>
              <ModalLayout.ModalSection title="EPC Status">
                <Row gutter={[15, 0]}>
                  <Col xs={{ span: 24 }} md={{ span: 24 }}>
                    <Form.Item
                      name='epc_status'
                    >
                      <Input
                        label='EPC Status'
                        placeholder={`EPC Status`}
                        name="epc_status"
                        type="select"
                        options={[
                          { value: 'Shelf Life Altered', label: 'Shelf Life Altered' },
                          { value: 'Product Contaminated', label: 'Product Contaminated' },
                          { value: 'Product Damaged', label: 'Product Damaged' },
                        ]}
                        disabled={isDisabled()}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </ModalLayout.ModalSection>
            </ModalLayout>
            <ModalLayout>
              <ModalLayout.ModalSection title="Affected Events">
                <Row gutter={[15, 0]}>
                  <Col xs={{ span: 24 }} md={{ span: 24 }}>
                    <Form.Item
                      name='affected_events_id'
                    >
                      <Input
                        name="affected_events_id"
                        type="multiSelect"
                        label="Event ID"
                        placeholder="Select Event ID"
                        options={renderOptions('affectedEvent')}
                        disabled={isDisabled()}

                      // value={assetData.alerts}
                      />
                      {/* <Input
                        label='Event ID'
                        placeholder={`Select Event ID`}
                        name="event_id"
                        type="select"
                        options={renderOptions('affectedEvent')}
                      /> */}
                    </Form.Item>
                  </Col>
                </Row>
              </ModalLayout.ModalSection>
            </ModalLayout>

            {isEdit == 'resolve' && (
              <>
                <ModalLayout>
                  <ModalLayout.ModalSection title="Resolve Comment">
                    <Row gutter={[15, 0]}>
                      <Col xs={{ span: 24 }}>
                        <Form.Item
                          name='resolve_comment'
                        >
                          <Input
                            label='Comment'
                            placeholder={`Leave Comment`}
                            name="resolve_comment"
                            type="textarea"

                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </ModalLayout.ModalSection>
                </ModalLayout>
              </>
            )}

            {/* <HeadingLabel>Affected Product</HeadingLabel>
            <Row gutter={[15, 0]}>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  name='product_reference_level'
                >
                  <Input
                    label='Reference Level'
                    placeholder={`Select Reference Level`}
                    name="product_reference_level"
                    type="select"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  name='product_number'
                >
                  <Input
                    label='No.'
                    placeholder={`Enter No.`}
                    name="product_number"
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>
            <HeadingLabel>Custody & Event : Problem Source</HeadingLabel>
            <Row gutter={[15, 0]}>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  name='source_custody'
                >
                  <Input
                    label='Source Custody'
                    placeholder={`Select Reference Level`}
                    name="source_custody"
                    type="select"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  name='source_custody_owner'
                >
                  <Input
                    label='Custody Owner'
                    placeholder={`Select Custody Owner`}
                    name="source_custody_owner"
                    type="select"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  name='source_custody_location'
                >
                  <Input
                    label='Location'
                    placeholder={`Select Location`}
                    name="source_custody_location"
                    type="select"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  name='source_custody_event'
                >
                  <Input
                    label='Event'
                    placeholder={`Select Event`}
                    name="source_custody_event"
                    type="select"
                  />
                </Form.Item>
              </Col>
            </Row>
            <HeadingLabel>Custody & Event : Current</HeadingLabel>
            <Row gutter={[15, 0]}>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  name='current_custody'
                >
                  <Input
                    label='Current Custody'
                    placeholder={`Select Custody`}
                    name="current_custody"
                    type="select"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  name='current_custody_owner'
                >
                  <Input
                    label='Custody Owner'
                    placeholder={`Select Custody Owner`}
                    name="current_custody_owner"
                    type="select"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  name='current_custody_location'
                >
                  <Input
                    label='Location'
                    placeholder={`Select Location`}
                    name="current_custody_location"
                    type="select"
                  />
                </Form.Item>
              </Col>
            </Row>
            <HeadingLabel>Affected Custody</HeadingLabel>
            <Row gutter={[15, 0]}>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <SubHeadingLabel>Affected Custody</SubHeadingLabel>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <SubHeadingLabel>Custody Owner</SubHeadingLabel>
              </Col>
            </Row>
            <Form.List name="affectedCustodies"
              initialValue={logIssueData.affectedCustodies}
            >
              {(fields) => (
                <>
                  {fields.map((field, index) => (
                    <Row gutter={[15, 0]} key={index}>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item
                          name={[field.name, 'affected']}
                          valuePropName='checked'
                        >
                          <Input
                            type='checkbox'
                            name={`affected`}
                            label={`Custody ${index + 1}`}
                          // checked={logIssueData.affectedCustodies[index].affected}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item
                          name={[field.name, 'owner_name']}
                        >
                          <Input
                            placeholder={`Enter Owner`}
                            name={`owner_name`}
                            type="text"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </>
              )}
            </Form.List>
            <Row gutter={[15, 0]}>
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  name='comments'
                >
                  <Input
                    label='Comment'
                    placeholder={`Add Comment`}
                    name="comments"
                    type="textarea"
                  />
                </Form.Item>
              </Col>
            </Row> */}
          </Form>
        </>
      </GlobalDrawer>
    </>
  )
}

export default LogIssueModal
