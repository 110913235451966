import { Form, message, Row, Col, } from 'antd';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import GlobalModal from 'components/GlobalModal';
import Input from 'components/Input';
import React, { useCallback, useEffect, useState, MouseEvent } from 'react'
import { VALIDATE_MESSAGES, } from 'utilities/CONSTANTS';
import { convertDateAndTime, disabledDateBeforeTodayFunction, getSelectorOptions } from 'utilities/Functions/GlobalHelperFunctions';
import { TrackGridTwo, } from "../../../../../statics/styles/StyledComponents";


import { ALLOCATION_REQUEST_TYPES, } from '../../statics/constants';
import DropdownAPI from '../../apis/DropdownAPI';
import AllocationRequestAPI from '../../apis/AllocationRequestAPI';
import PrefillAPI from '../../apis/PrefillAPI';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';

type Props = {
    isModalOpen: any;
    setIsModalOpen: Function;
    requestData: any;
    setRequestData: Function;
    refreshPage: Function;

}

type RequestData = {
    master_data_refID: string;
    request_order_number?: string;
    datetime: string;
    asset_IDs: string[];
    storeList: string[];
    type: string,
    quantity: string,
    customer_ID: string,
    customer: string,
    store: string,
    expected_delivery_date?: string,
    expected_pickup_date?: string,
    store_address: string,
    request_comments: string,
}

const RequestModal = (props: Props) => {

    const { isModalOpen, setIsModalOpen, setRequestData, requestData, refreshPage } = props

    const [form] = Form.useForm();


    const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)


    const [customerList, setCustomerList] = useState<any[]>([]);
    const [assetPresetList, setAssetPresetList] = useState<any[]>([])
    const [deliveredRequestList, setDeliveredRequestList] = useState<any[]>([])


    const getDropdowns = async () => {
        setIsGlobalLoading(true)

        const payload = {
            "customers": true,
            "asset_presets": true,
        }
        try {

            const result = await DropdownAPI.getDropdowns(payload)
            const { customers, asset_presets } = result
            setCustomerList(customers)
            setAssetPresetList(asset_presets)
            if (isModalOpen.isNewRequest) {
                const order_number = await PrefillAPI.getAllocationRequestId()
                const finalData = {
                    ...requestData,
                    order_number
                }
                setRequestData(finalData)
                form.setFieldsValue({ ...finalData })
            } else {
                const singleCutomer = customers.find((item: any) => {
                    if (item.bus_name == requestData.customer) {
                        return item
                    }
                })
                const storeListt: any[] = []
                singleCutomer && singleCutomer.stores.map((rec: any) => {
                    storeListt.push(rec.store_name)

                })
                const finalData: any = {
                    ...requestData,
                    storeList: storeListt
                }

                setRequestData(finalData)
                form.setFieldsValue({ ...finalData })

            }



        } catch (err) {
            console.log('handleModalOpen fail...', err)

        } finally {
            setIsGlobalLoading(false)
        }



    }


    const initialize = useCallback(
        () => {
            console.log('passed in inti ', requestData)
            if (isModalOpen.isNewRequest || isModalOpen.isRequestEdit) {
                getDropdowns()
            } else {
                form.setFieldsValue({ ...requestData })
            }

        },
        [isModalOpen.isNewRequest, isModalOpen.isRequestEdit, isModalOpen.isView],
    )
    useEffect(initialize, [isModalOpen.isNewRequest, isModalOpen.isRequestEdit, isModalOpen.isView])

    const emptyRequest: RequestData = {
        type: 'request',
        request_order_number: '',
        master_data_refID: "",
        quantity: "",
        customer_ID: '',
        customer: '',
        storeList: [],
        store: '',
        datetime: "",
        expected_delivery_date: "",
        expected_pickup_date: "",
        asset_IDs: [],
        store_address: '',
        request_comments: "",

    }

    const isModalOpenInit = {
        isAllocateOpen: false,
        isRetrieveOpen: false,
        isNewRequest: false,
        isRequestEdit: false,
        isView: false,
    }


    const handleNewRequestConfirmed = async (values: any) => {
        console.log('valuees==>', values, requestData, isModalOpen.isRequestEdit)
        const { customer, customer_ID, expected_delivery_date, master_data_refID, quantity, request_comments, store, type, request_order_number, expected_pickup_date } = values
        let payload: any = {}
        if (requestData.type == ALLOCATION_REQUEST_TYPES.retrieval.value) {
            const requestInfo = deliveredRequestList.find((item: any) => item.order_number == request_order_number)
            payload = {
                order_number: requestData.order_number,
                customer,
                // expected_delivery_date: expected_delivery_date ? moment(expected_delivery_date).format(DATE_TIME_FORMAT) : '',
                expected_pickup_date: expected_pickup_date ? convertDateAndTime(expected_pickup_date, 'datetime') : '',
                request_comments,
                request_order_number,
                store,
                type,
                customer_ID: customer_ID,

                master_data_refID: requestInfo?.master_data_refID,
                quantity: requestInfo?.quantity,
            }
        } else {
            payload = {
                order_number: requestData.order_number,
                customer,
                // expected_delivery_date: expected_delivery_date ? moment(expected_delivery_date).format(DATE_TIME_FORMAT) : '',
                expected_delivery_date: expected_delivery_date ? convertDateAndTime(expected_delivery_date, 'datetime') : '',
                master_data_refID,
                quantity,
                request_comments,
                store,
                type,
                customer_ID: customer_ID,
            }
        }

        console.log('payload', payload)
        // return
        try {
            if (isModalOpen.isRequestEdit) {
                payload = {
                    ...payload,
                    datetime: requestData.datetime
                }
                await AllocationRequestAPI.updateAllocationRequest(payload)
            } else {
                await AllocationRequestAPI.addAllocationRequest(payload)
            }
            message.success(`Request ${isModalOpen.isRequestEdit ? "updated" : "added"} successfully, thank you`)
            // getTableData()
            refreshPage()
            handleModalClose()

        } catch (err) {
            message.success(`Request ${isModalOpen.isRequestEdit ? "updated" : "added"} fail, please try later`)
            console.log('get allocate request fail...', err)

        } finally {
            setIsGlobalLoading(false)
        }

    };


    const handleModalClose = (e?: MouseEvent) => {
        if (e) {
            e.preventDefault();
        }
        setIsModalOpen(isModalOpenInit);

        // reset fields for accordion
        form.resetFields()
        form.setFieldsValue({ ...emptyRequest })
        setRequestData(emptyRequest)


    };

    const renderTypeOption = (type?: string) => {
        if (type == 'customer') {
            const allcustomerList: any[] = []
            customerList.map((item: any) => {
                allcustomerList.push(item.bus_name)
            })
            return getSelectorOptions(allcustomerList, 'Select Customer')
        } else if (type == 'store') {
            return getSelectorOptions(requestData.storeList, 'Select Store')
        } else if (type == 'request_type') {
            const typeList: any[] = [{ label: 'Order New Asset(s)', value: 'request' }, { label: 'Retrieve Existing Asset(s)', value: 'retrieval' }]
            return getSelectorOptions(typeList, '', 'value', 'label')
        } else if (type == 'asset_refID') {
            console.log('asset refId', assetPresetList)
            return getSelectorOptions(assetPresetList, 'Select Asset Preset', 'preset_refID', 'preset_name')
        } else if (type == 'order_request') {
            return getSelectorOptions(deliveredRequestList, 'Select Request', 'order_number')
        } else {
            return getSelectorOptions([], 'Select')
        }
    };
    const handleSelectCustomer = (value: string) => {
        //get customer details on the basis of selected customer
        console.log('value', value)
        if (!value) {
            return
        }
        const singleCutomer = customerList.find((item: any) => {
            console.log('customerList', customerList)
            if (item.bus_name == value) {
                return item
            }
        })
        console.log('singleCutomer', singleCutomer)
        const storeListt: any[] = []
        singleCutomer && singleCutomer.stores.map((rec: any) => {
            console.log('rec==>', rec)
            storeListt.push(rec.store_name)
            // combinedAddress = `${rec.full_address ? rec.full_address : 'No available address'}`
            // console.log('combinedAddress', combinedAddress)
            // combinedAddress = `${rec.address.store_address_line_1}, ${rec.address.store_country}, ${rec.address.store_state}, ${rec.address.store_postcode}`
            // console.log('combinedAddress', combinedAddress)
        })
        console.log('storeList', storeListt)
        const finalData: any = {
            ...requestData,
            customer_ID: singleCutomer.customer_ID,
            customer: singleCutomer.bus_name,
            storeList: storeListt,
            // store_address: combinedAddress,
        };
        console.log('finalData', finalData)
        setRequestData({ ...finalData })

        form.setFieldsValue({
            ...finalData,
        })

    };
    const handleSelectRequestType = (value: string) => {
        //get customer details on the basis of selected customer
        console.log('handleSelectRequestType value', value)
        if (!value) {
            return
        }

        const finalData: any = {
            ...requestData,
            type: value,
            // store_address: combinedAddress,
        };
        // console.log('finalData', finalData)
        setRequestData({ ...finalData })

        form.setFieldsValue({
            ...finalData,
        })

    };
    const handleSelectStore = async (value: string) => {
        //get customer details on the basis of selected customer
        console.log('value', value, requestData.storeList, requestData)
        if (!value) {
            return
        }
        const singleCutomer = customerList.find((item: any) => {
            console.log('customerList', customerList)
            if (item.customer_ID == requestData.customer_ID) {
                return item
            }
        })
        let combinedAddress: string = '';
        singleCutomer && singleCutomer.stores.map((rec: any) => {
            console.log('rec==>', rec)

            if (rec.store_name == value) {
                combinedAddress = `${rec.full_address ? rec.full_address : 'No available address'}`
            }


        })

        const finalData: any = {
            ...requestData,
            ...form.getFieldsValue(),
            store_address: combinedAddress,
        };
        console.log('finalData', finalData)
        setRequestData({ ...finalData })

        form.setFieldsValue({
            ...finalData,
        })

        if (requestData.type == ALLOCATION_REQUEST_TYPES.retrieval.value) {
            const result = await AllocationRequestAPI.searchAllocationRequest(`?type=request&status=delivered&store=${value}&customer_ID=${requestData.customer_ID}`)
            setDeliveredRequestList(result)
        }

    };

    const renderFooter = () => (
        <Row className='justify-content-end'>
            <Col>
                <Form.Item style={{ marginBottom: '0' }}>
                    <SimpleButton
                        text='Cancel'
                        id='modal-btn-width-small'
                        onClick={(e: MouseEvent) => {
                            handleModalClose(e)
                        }}
                        isCancel
                    />
                    {isModalOpen.isView ? null : (
                        <SimpleButton
                            id='modal-btn-width-regular'
                            className="ms-3"
                            htmlType="submit"
                            // text="Save"
                            text={isModalOpen.isAllocateOpen ? 'Allocate' : isModalOpen.isNewRequest ? 'Confirm' : isModalOpen.isRequestEdit ? 'Save' : 'Retrieve'}
                            form="nest-messages"
                        />
                    )}

                </Form.Item>
            </Col>
        </Row>
    )



    return (
        <>
            <GlobalModal
                // title={`${isModalOpen.isNewRequest ? `New Request` : `Order Number: ${modalData.order_number}`}`}
                title={`${isModalOpen.isNewRequest ? `New Request` : `${isModalOpen.isRequestEdit ? `Edit Request` : `View Request`}`}`}
                open={isModalOpen.isNewRequest || isModalOpen.isRequestEdit || isModalOpen.isView}
                onCancel={(e: MouseEvent) => handleModalClose(e)}
                style={{ maxHeight: '50vh', overflowY: 'auto' }}
                // dark
                footer={[renderFooter()]}
            >
                <Form
                    form={form}
                    name="nest-messages"
                    onFinish={(values: any) => handleNewRequestConfirmed(values)}
                    validateMessages={VALIDATE_MESSAGES}
                    initialValues={{ ...requestData }}
                >
                    <TrackGridTwo style={{ gridColumnGap: '15px' }}>
                        <Form.Item
                            name="order_number"
                        // initialValue={requestData.order_number}
                        >
                            <Input
                                label="Request ID"
                                name="order_number"
                                type="text"
                                placeholder="Request ID"
                                // value={requestData.order_number}
                                required={true}
                                disabled={true}
                            />
                        </Form.Item>
                        <Form.Item
                            name="type"
                            // initialValue={requestData.type}
                            rules={[{ required: true, message: 'Please select a Request Type' }]}
                        >
                            <Input
                                label="Request Type"
                                name="type"
                                type="select"
                                placeholder="Request"
                                options={renderTypeOption('request_type')}
                                // value={requestData.type}
                                onChange={(value: string) => handleSelectRequestType(value)}
                                required={true}
                                disabled={isModalOpen.isView || isModalOpen.isRequestEdit ? true : false}
                            />
                        </Form.Item>
                    </TrackGridTwo>

                    <TrackGridTwo style={{ gridColumnGap: '15px' }}>
                        <Form.Item
                            name="customer"
                            // initialValue={requestData.customer}
                            rules={[{ required: true, message: 'Please select a Customer Name' }]}
                        >
                            <Input
                                label="Customer Name"
                                name="type"
                                type="select"
                                placeholder="Customer Name"
                                options={renderTypeOption('customer')}
                                // value={requestData.customer}
                                required={true}
                                onChange={(value: string) => handleSelectCustomer(value)}
                                disabled={isModalOpen.isView}
                            />
                        </Form.Item>
                        <Form.Item
                            name="customer_ID"
                        // initialValue={requestData.customer_ID}
                        >
                            <Input
                                label="Customer ID"
                                name="customer_ID"
                                type="text"
                                placeholder="Customer ID"
                                options={renderTypeOption('customer_ID')}
                                // value={requestData.customer_ID}
                                disabled={true}
                            />
                        </Form.Item>
                    </TrackGridTwo>
                    {requestData.type == 'retrieval' ? null : (
                        <TrackGridTwo style={{ gridColumnGap: '15px' }}>
                            <Form.Item
                                name="master_data_refID"
                                // initialValue={requestData.master_data_refID}
                                rules={[{ required: true, message: 'Please select a Asset Preset' }]}
                            >
                                <Input
                                    label="Asset"
                                    name="master_data_refID"
                                    type="select"
                                    placeholder="Asset RefID"
                                    options={renderTypeOption('asset_refID')}
                                    // value={requestData.master_data_refID}
                                    required={true}
                                    disabled={isModalOpen.isView}

                                />
                            </Form.Item>
                            <Form.Item
                                name="quantity"
                                // initialValue={requestData.quantity}
                                rules={[{ required: true, message: 'Please input the Quantity' }]}
                            >

                                <Input
                                    label="Quantity"
                                    name="quantity"
                                    type="number"
                                    isGreaterthanOneRequired={true}
                                    placeholder="Please input Quantity"
                                    required={true}
                                    style={{ width: '100%' }}
                                    disabled={isModalOpen.isView}
                                />
                            </Form.Item>
                        </TrackGridTwo>
                    )}

                    <TrackGridTwo style={{ gridColumnGap: '15px' }}>

                        <Form.Item
                            name="store"
                            // initialValue={requestData.store}
                            rules={[{ required: true, message: 'Please select a Store' }]}
                        >
                            <Input
                                label="Store"
                                name="store"
                                type="select"
                                placeholder="Store"
                                options={renderTypeOption('store')}
                                // value={requestData.store}
                                onChange={(value: string) => handleSelectStore(value)}
                                required={true}
                                disabled={isModalOpen.isView}
                            />
                        </Form.Item>


                        <Form.Item
                            name={`${requestData.type == 'retrieval' ? 'expected_pickup_date' : 'expected_delivery_date'}`}
                            // initialValue={requestData.expected_delivery_date}
                            rules={[{ required: true, message: 'Please select a Date' }]}
                        >
                            <Input
                                label={`${requestData.type == 'retrieval' ? "Expected Pickup Date" : "Expected Delivery Date"}`}
                                name={`${requestData.type == 'retrieval' ? 'expected_pickup_date' : 'expected_delivery_date'}`}
                                type="date"
                                placeholder="Select date"
                                // value={requestData.expected_delivery_date}
                                disabledDate={disabledDateBeforeTodayFunction}
                                required={true}
                                disabled={isModalOpen.isView}
                            />
                        </Form.Item>
                    </TrackGridTwo>

                    <Form.Item
                        name="store_address"
                        initialValue={requestData.store_address}
                    >
                        <Input
                            label="Delivery Address"
                            name="store_address"
                            type="text"
                            placeholder="Address"
                            value={requestData.store_address}
                            disabled={true}
                        />
                    </Form.Item>
                    {requestData.type == ALLOCATION_REQUEST_TYPES.retrieval.value && (
                        <Form.Item
                            name="request_order_number"
                            initialValue={requestData.request_order_number}
                            rules={[{ required: true, message: 'Please select a Request' }]}
                        >
                            <Input
                                label="Order Request"
                                name="request_order_number"
                                type="select"
                                placeholder="Order Request"
                                options={renderTypeOption('order_request')}
                                value={requestData.store}
                                // onChange={(value: string) => handleSelectStore(value)}
                                required={true}
                                disabled={isModalOpen.isView}
                            />
                        </Form.Item>
                    )}



                    <Form.Item
                        name="request_comments"
                    // initialValue={requestData.request_comments}
                    >
                        <Input
                            label="Comments"
                            name="request_comments"
                            type="textarea"
                            placeholder="Comment"
                            // value={requestData.request_comments}
                            disabled={isModalOpen.isView}
                        />
                    </Form.Item>
                </Form>
            </GlobalModal>
        </>
    );
}

export default RequestModal;
