import React, { useState, MouseEvent } from "react";
import styled from "styled-components";
import AdiTable from "components/AdiTable";
import { MoreOutlined } from "@ant-design/icons";
import moment from "moment";
import { MAINTENANCE_TYPES } from ".";
import { DATE_FORMAT, DATE_TIME_FORMAT, TABLE_CELL_CONFIG } from "utilities/CONSTANTS";
import { formatNameString, initialCellValue, initialEnumCellValue } from "utilities/Functions/FormatFunctions";
import MaintenanceForm from "./MaintenanceForm";
import { Dropdown, Menu, message } from "antd";


import MaintenanceAPI from "../../apis/MaintenanceAPI";
import { ASSET_STATUS } from "../../statics/constants";
import { useGeneralStore } from "zustand-stores";
import { GeneralStore } from "zustand-stores/useGeneralStore";
import useAuthStore, { AuthStore } from "zustand-stores/useAuthStore";
import { ActionIcon } from "components/AdiTable/ActionDropdown";

type Props = {
    type: MAINTENANCE_TYPES;
    // selectedData: any;
    // handleModalOpen: Function;
    // handleModalClose: Function;
    tableData: any[];
    // getTableData: Function;
    initializeAll: Function;
    isLoading: boolean;
};


const MaintenanceType = (props: Props) => {

    const { tableData, type, initializeAll, isLoading } = props
    const isAmReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.am.isReadOnly)

    const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<any>();

    const handleUpdateMaintenance = async (type: 'in_maintenance' | 'completed', record: any) => {
        console.log('handleUpdateMaintenance')

        const payload = { ...record, status: type }
        console.log('payload for handleUpdateMaintenance', payload)

        setIsGlobalLoading(true)

        try {
            const res = await MaintenanceAPI.updateMaintenance(payload)
            message.success('Item updated successfully!')
            initializeAll()
        } catch (error: any) {
            console.log('error', error.response)
            if (error?.response?.data && error.response?.data?.status) {
                message.error(error.response?.data?.status)
            } else {
                message.error('Failed to update item.')
            }
        } finally {
            setIsGlobalLoading(false)
        }

    }

    const columnWidth240 = 240

    const menu = (record: any) => (
        <Menu className='dropdownMenu'>

            {(record.status == 'upcoming' || record.status == 'overdue') && record.asset_status == ASSET_STATUS.UNALLOCATED && (<Menu.Item key="1">
                <div
                    onClick={
                        async (e: MouseEvent) => {
                            e.preventDefault()
                            e.stopPropagation();
                            console.log('onClick record', record)
                            handleUpdateMaintenance('in_maintenance', record)
                            //   handleEditClick(record, false)
                        }
                    }
                >In Maintenance</div>
            </Menu.Item>)}


            {record.status == 'in_maintenance' && (<Menu.Item key="1">
                <div
                    onClick={
                        (e: MouseEvent) => {
                            e.preventDefault()
                            e.stopPropagation();
                            setIsModalOpen(true)
                            setSelectedItem(record)

                        }
                    }
                >Completed</div>
            </Menu.Item>)}


        </Menu>
    );


    const columns: any = [
        {
            title: "ASSET ID",
            dataIndex: "asset_ID",
            align: "left",
            key: "asset_ID",
            ...TABLE_CELL_CONFIG,
            width: columnWidth240,
            render: initialCellValue,
            // sorter: (a: any, b: any) => a.picklist_ID?.localeCompare(b.picklist_ID)

        },
        {
            title: "ASSET NAME",
            dataIndex: "asset_name",
            align: "left",
            key: "asset_name",
            ...TABLE_CELL_CONFIG,
            width: columnWidth240,
            render: initialCellValue,

        },
        {
            title: "CURRENT LOCATION",
            dataIndex: "current_party",
            align: "left",
            key: "current_party",
            ...TABLE_CELL_CONFIG,
            width: columnWidth240,
            render: initialEnumCellValue,

        },

        {
            title: "MAINTENANCE RULE",
            dataIndex: "maintenance_name",
            align: "left",
            key: "maintenance_name",
            ...TABLE_CELL_CONFIG,
            width: columnWidth240,
            render: initialCellValue,

        },
        {
            title: "FREQUENCY",
            dataIndex: "frequency",
            align: "left",
            key: "frequency",
            ...TABLE_CELL_CONFIG,
            width: columnWidth240,
            render: initialCellValue,
            // render: (text: any, record: any, index: number) => (
            //     <>{moment(new Date(record.scheduled_datetime)).format(DATE_TIME_FORMAT)}</>
            // )

        },
        {
            title: "LAST COMPLETED ON",
            dataIndex: "last_completed_on",
            align: "left",
            key: "last_completed_on",
            ...TABLE_CELL_CONFIG,
            width: columnWidth240,
            render: (text: any, record: any, index: number) => record.last_completed_on ? (
                <>{moment(new Date(record.last_completed_on)).format(DATE_TIME_FORMAT)}</>
            ) : '--'

        },

        {
            title: "DUE DATE",
            dataIndex: "scheduled_datetime",
            align: "left",
            key: "scheduled_datetime",
            ...TABLE_CELL_CONFIG,
            width: columnWidth240,
            render: (text: any, record: any, index: number) => (
                <>{moment(new Date(record.scheduled_datetime)).format(DATE_FORMAT)}</>
            )

        },
        {
            title: "STATUS",
            dataIndex: "status",
            align: "left",
            key: "status",
            ...TABLE_CELL_CONFIG,
            width: columnWidth240,
            render: (text: any, record: any, index: number) => (
                <>{formatNameString(record.status)}</>
            )

        },
        // {
        //     title: "CUSTOMER NAME",
        //     dataIndex: "customer",
        //     align: "left",
        //     key: "customer",
        //     ...TABLE_CELL_CONFIG,
        //     width: columnWidth240,
        //     render: initialCellValue,
        //     // render: (text: any, record: any, index: number) => (
        //     //     <>{moment(new Date(record.scheduled_datetime)).format(DATE_FORMAT)}</>
        //     // )

        // },
        // {
        //     title: "CUSTOMER ID",
        //     dataIndex: "customer_ID",
        //     align: "left",
        //     key: "customer_ID",
        //     ...TABLE_CELL_CONFIG,
        //     width: columnWidth240,
        //     render: initialCellValue,
        //     // render: (text: any, record: any, index: number) => (
        //     //     <>{moment(new Date(record.scheduled_datetime)).format(DATE_FORMAT)}</>
        //     // )

        // },

        {
            title: "Action",
            dataIndex: "action",
            align: "left",
            key: "action",
            ...TABLE_CELL_CONFIG,
            width: columnWidth240,
            render: (text: any, record: any, index: number) => (
                <div>
                    <Dropdown overlay={menu(record)}>
                        <ActionIcon style={{ color: 'white', fontSize: '20px' }} onClick={(e: any) => e.stopPropagation()} />
                    </Dropdown>
                    {/* <Dropdown overlay={menu(record)} trigger={['click']}>
                        <SimpleButton
                            text={
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Action
                                    <DownOutlined style={{ color: "unset !important", marginLeft: '10px' }} />
                                </div>
                            }
                            outlined
                            onClick={(e: MouseEvent) => {
                                e.preventDefault()
                                e.stopPropagation();
                            }
                            }
                        />
                    </Dropdown> */}
                </div>)
        },
    ];

    if (props.type == 'completed') {
        const idxOfLstCompleteDate = columns.findIndex((item: any) => item.dataIndex == 'last_completed_on')

        columns[idxOfLstCompleteDate] = {
            title: "COMPLETED ON",
            dataIndex: "completion_date",
            align: "left",
            key: "completion_date",
            ...TABLE_CELL_CONFIG,
            width: columnWidth240,
            render: (text: any, record: any, index: number) => (
                <>{moment(new Date(record.completion_date)).format(DATE_TIME_FORMAT)}</>
            )

        }

        console.log('after change ', columns)
        columns.pop()
        columns.pop()

        const dueDateElement = columns.pop()
        const completeOnElement = columns.pop()

        columns.push(dueDateElement)
        columns.push(completeOnElement)

    }


    isAmReadOnly && columns.pop()

    const closeModal = () => {
        setIsModalOpen(false)
        setSelectedItem(null)
    }

    return (
        <>
            <>
                <MainDiv >
                    <AdiTable
                        loading={isLoading}
                        scroll={{ x: '100%', y: '50vh' }}
                        tableData={tableData}
                        columns={columns}
                        tableWrapperStyle={{ width: '100%', paddingRight: 0 }}
                        extraPaginationPadding
                        marginTop="0px"
                    // onRowClick={(record: any, rowIndex: number, event: MouseEvent) => {
                    //     event.preventDefault()
                    //     // handleModalOpen(event, record)
                    //     setIsModalOpen(true)
                    //     setSelectedItem(record)
                    // }}
                    />
                </MainDiv>

            </>

            {isModalOpen ? (
                <MaintenanceForm maintenanceData={selectedItem} open={isModalOpen} onCancel={closeModal} type={type} initializeAll={props.initializeAll} />
            ) : null}

        </>
    );
};

export default MaintenanceType;


const MainDiv = styled.div`
  
`;
