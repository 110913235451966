import { EditOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import AdiTable from "components/AdiTable";
import Input from "components/Input";
import React, { useCallback, useEffect, useState, MouseEvent } from "react";
import { ASSET_ALERTS_TYPES } from "utilities/CONSTANTS";
import { ActionButton, DisplayEmptyTextPrimary, DisplaySecondaryText, FlexWrapper } from "../../../../statics/styles/StyledComponents";
import noteViewImg from '../../../../statics/images/noteViewImg.png'
import disabledEditIcon from '../../../../statics/images/disabledEditIcon.svg'
import moment from "moment";
import DisplayStats from "components/Statistics/DisplayStats";
import { capitaliseFirst, formatTitle } from "utilities/Functions/FormatFunctions";
import SummaryAPI from "../apis/SummaryAPI";
import AlertAPI from "../apis/AlertAPI";
import ResolveModal from "./ResolveModal";
import AlertMapModal from "./AlertMapModal";
import { useAuthStore, useGeneralStore } from "zustand-stores";
import { AuthStore } from "zustand-stores/useAuthStore";
import { useResponsive } from "utilities/hooks";
import { GeneralStore } from "zustand-stores/useGeneralStore";
import { getSelectorOptions } from "utilities/Functions/GlobalHelperFunctions";
import DropdownAPI from "../apis/DropdownAPI";
import RouteDivertIcon from '../../../../assets/asset-management/route-divert.svg';
import TemperatureIcon from '../../../../assets/asset-management/temperature.svg';
import GeofenceIcon from '../../../../assets/asset-management/geofence.svg';
import HumidityIcon from '../../../../assets/asset-management/humidity.svg';
import OtherIcon from '../../../../assets/asset-management/other.svg';
import { Analytics } from 'components/Analytics';

type AlertTypes = {
  alert_ID: string;
  alert_name: string;
  status: string;
  resolved_comment: string;
}


export const emptyAlertData: AlertTypes = {
  alert_ID: '',
  alert_name: '',
  status: '',
  resolved_comment: '',
}

const Alerts = () => {
  const isAmReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.am.isReadOnly)
  // const mapRef = useRef<google.maps.Map | null>(null)


  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalMapOpen, setIsModalMapOpen] = useState<boolean>(false);
  const [currentRecord, setCurrentRecord] = useState<any>([]);
  const [selectedAlertType, setSelectedAlertType] = useState<string | null>(null);

  const screen = useResponsive();

  const [filters, setFilters] = useState<{ [key: string]: string }>({
    status: '',
    search: '',
    start_date: '',
    end_date: '',
  });

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const [modalData, setModalData] = useState<AlertTypes>(emptyAlertData);
  const [alertTypesList, setAlertTypesList] = useState<any[]>([])

  // create a chart and set loaded data
  const [alertCards, setAlertCards] = useState<any>([]);
  const getTableData = useCallback(
    async () => {

      try {
        setIsLoading(true)
        const result = await AlertAPI.searchAlert()
        setTableData(result || [])
        // setIsGlobalLoading(false)

      } catch (error) {
        console.log('result getTableData alert page error', error)
      } finally {
        // setIsGlobalLoading(false)
        setIsLoading(false)
      }


    },
    [],
  );
  const getSummary = useCallback(
    async () => {
      try {
        const summaryResult = await SummaryAPI.getSummary('alert')
        if (summaryResult != '') {
          const newSummary = Object.keys(ASSET_ALERTS_TYPES).map((item: string, index: number) => ({

            text: item ? item == 'LOCATION DIVERT' ? (capitaliseFirst('ROUTE DIVERT')) : (capitaliseFirst(item)) : '',
            number: summaryResult[item] || 0,
          }))

          setAlertCards(newSummary)
          console.log('result summary', summaryResult, ASSET_ALERTS_TYPES)
        } else {
          // message.info('No summary data found')
        }

      } catch (error) {
        console.log('result getSummary alert page error', error)
      }


    },
    [],
  );

  console.log('alertCards ------------------------ ', alertCards)

  const getValueByTitle = (title: string) => {

    const item = alertCards?.find((item: any) => item.text === title);
    return item ? item.number : 0;
  };

  const handleAnalyticsClick = (eventType: string) => {
    if (eventType === 'Total') {
      setSelectedAlertType(null);
    } else {
      setSelectedAlertType(eventType);
    }
  };

  const filteredEventList = selectedAlertType
    ? tableData.filter((alert: any) => alert.type === selectedAlertType)
    : tableData;

  const analyticsInfo = [
    {
      icon: <img src={RouteDivertIcon} alt="AllGridIcon" />,
      title: 'Route Divert',
      value: getValueByTitle('Route Divert'),
      onClick: () => handleAnalyticsClick('Route Divert'),
    },
    {
      icon: <img src={TemperatureIcon} alt="AllGridIcon" />,
      title: 'Temperature',
      value: getValueByTitle('Temperature'),
      onClick: () => handleAnalyticsClick('Temperature'),
    },
    {
      icon: <img src={GeofenceIcon} alt="AllGridIcon" />,
      title: 'Geofence Breach',
      value: getValueByTitle('Geofence Breach'),
      onClick: () => handleAnalyticsClick('Geofence Breach'),
    },
    {
      icon: <img src={HumidityIcon} alt="AllGridIcon" />,
      title: 'Humidity',
      value: getValueByTitle('Humidity'),
      onClick: () => handleAnalyticsClick('Humidity'),
    },
    {
      icon: <img src={OtherIcon} alt="AllGridIcon" />,
      title: 'Other',
      value: getValueByTitle('Other'),
      onClick: () => handleAnalyticsClick('Other'),
    },
  ];


  const getAlertTypes = useCallback(async () => {
    try {
      const result = await DropdownAPI.getDropdowns({
        alert_types: true
      })
      const { alert_types } = result
      setAlertTypesList(alert_types)

    } catch (error) {
      console.log('result getAlertTypes alert page error', error)
    }
  },
    [],)

  const initialize = useCallback(
    () => {
      getTableData()
      getSummary()
      getAlertTypes()
    },
    [],
  )
  useEffect(initialize, [])

  const renderOption = (type?: string) => {
    if (type == 'asset_category') {
      const list = ['Geofence', 'Temperature']
      return getSelectorOptions(list, 'Select Asset Category')
    }
    if (type == 'status') {
      const list = [{ label: 'Resolved', value: 'resolved' }]
      return getSelectorOptions(list, 'Select Status', 'value', 'label')
    }
    if (type == 'bothstatus') {
      const list = [{ label: 'Active', value: 'active' }, { label: 'Resolved', value: 'resolved' }]
      return getSelectorOptions(list, 'Select Status', 'value', 'label')
    }
  }

  const onSelectType = (e: string, selectorName: string) => {
    console.log("onSelectLevel", e, selectorName);
    const selectedItem = e;
    const latestFilters = {
      ...filters,
      [selectorName]: selectedItem
    }
    console.log('latestFilters', latestFilters)
    setFilters(latestFilters)

    // only search when selector changed
    if (selectorName !== 'search') {
      handleSearch(latestFilters)
    }
  };

  const handleModalOpen = (curRecord?: any) => {
    // setIsLoading(true);
    setIsGlobalLoading(true)
    console.log('curRecord', curRecord)
    setModalData({ ...modalData, alert_ID: curRecord.alert_ID, alert_name: curRecord.alert_name })
    setIsModalOpen(true);
    // setIsLoading(false);
    setIsGlobalLoading(false)
  }

  const handleModalMapOpen = (e: any, curRecord?: any) => {
    e.preventDefault();
    console.log('curRecord', [curRecord])
    setCurrentRecord([curRecord]);
    setIsModalMapOpen(true);

  }

  const actionColumn = isAmReadOnly ? [] : [
    // {
    //   title: "Action",
    //   dataIndex: "alert_ID",
    //   align: "left",
    //   key: "alert_ID",
    //   render: (text: any, record: any, index: number) => {
    //     return record.status == "resolved" ? (
    //       <span>
    //         <img width='17px' src={disabledEditIcon} title="Can't Edit" />
    //       </span>
    //     ) : (
    //       <>
    //         <p style={{ marginBottom: "0" }}>
    //           <ActionButton
    //             title="Edit"
    //             onClick={(e: MouseEvent) => {
    //               handleModalOpen(e, record)
    //             }}
    //           >
    //             <EditOutlined />
    //           </ActionButton>
    //         </p>

    //       </>
    //     )
    //   },
    // },
    {
      title: formatTitle('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (text: any, record: any) => {

        const items = [
          {
            label: "Edit",
            icon: <EditOutlined />,
            onClick: () => handleModalOpen(record),
          },
        ];

        if (record.status == "resolved") {
          return <AdiTable.NoActionDisplay />;
        }

        return <AdiTable.ActionDropdown menuItems={items} />;
      },

    }
  ]

  const columns: any = [
    {
      title: "Alert ID",
      dataIndex: "alert_ID",
      align: "left",
      key: "alert_ID",
    },
    {
      title: "Alert Rule",
      dataIndex: "alert_name",
      align: "left",
      key: "alert_name",
    },
    {
      title: "Asset ID",
      dataIndex: "asset_ID",
      align: "left",
      key: "asset_ID",
    },
    {
      title: "Device ID",
      dataIndex: "deviceID",
      align: "left",
      key: "deviceID",
    },
    {
      title: "Measurement",
      dataIndex: "placeholder",
      align: "left",
      key: "placeholder",
      filters: alertTypesList.map((item: any) => ({ text: item.placeholder, value: item.placeholder })),
      onFilter: (value: string, record: any) => record.placeholder === value
    },
    {
      title: "Expected Values",
      dataIndex: "dataType",
      align: "left",
      key: "dataType",
      render: (text: any, record: any, index: number) => {
        return record.issue == "GEOFENCE" ? (
          <span>{record?.geofenceName || '---'}</span>
        ) : (
          <span>{`Min: ${record?.minRule}   Max: ${record?.maxRule}`}</span>
        )
      }
    },
    {
      title: "Breached Value",
      dataIndex: "dataBreachValue",
      align: "left",
      key: "dataBreachValue",
      render: (text: any, record: any, index: number) => {
        return record.issue == "GEOFENCE" ? (
          <span><div style={{ color: '#0083B0', cursor: 'pointer' }}
            onClick={(e: MouseEvent) => {
              console.log('view map')
              handleModalMapOpen(e, record)
            }}
          >View in Map</div></span>
        ) : (
          <span>{`${record?.dataBreachValue}`}</span>
        )
      }
    },
    {
      title: "Date & Time",
      dataIndex: "datetime",
      align: "left",
      key: "datetime",
      render: (text: any, record: any, index: number) => {
        return <span>{moment(record.datetime).format('DD/MM/YYYY HH:mm:ss')}</span>
      }
    },
    {
      title: "Note",
      dataIndex: "resolved_comment",
      align: "left",
      key: "resolved_comment",
      render: (text: any, record: any, index: number) => {
        return record.resolved_comment && record.resolved_comment != "" ? (
          // <span>{`${record.resolved_comment}`}</span>
          <span><Popover overlayStyle={{
            width: "20vw"
          }} title="Note :" content={<p style={{ height: '8vw', overflow: 'scroll' }}>{record.resolved_comment}</p>}><img src={noteViewImg} /></Popover></span>
        ) : (
          <span>---</span>
        )
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "left",
      key: "status",
      render: (text: any, record: any, index: number) => {
        return record.status == "Resolved" ? (
          <span style={{ color: '#0083B0' }}>{`${record.status}`}</span>
        ) : (
          <span>{`${record.status}`}</span>
        )
      }
    },
    ...actionColumn

  ];

  const handleDateChange = (datestring: string) => {
    const latestFilters = {
      ...filters,
      start_date: datestring[0],
      end_date: datestring[1],
    }
    setFilters(latestFilters)
    if (datestring.length > 0) {
      handleSearch(latestFilters)
    }
  };

  const getFormattedDatetimeFromStartToEndDay = (dateString: string, from: 'start' | 'end') => {
    const formattedDate = moment(dateString, "DD/MM/YYYY")
    const startOrEndDate = from == 'start' ? formattedDate.startOf('day') : formattedDate.endOf('day')
    return startOrEndDate.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
  }

  const handleSearch = async (latestFilters?: any) => {
    let curFilters: any

    // for selector search
    if (latestFilters) {
      curFilters = { ...latestFilters }
    }
    // for input enter search
    else {
      curFilters = { ...filters }
    }

    let searchUrl = ``
    const searchCondition: string[] = []
    if (curFilters.search !== '') {
      searchCondition.push(`search=${curFilters.search}`)
    }
    if (curFilters.status !== '') {
      searchCondition.push(`status=${curFilters.status}`)
    }
    if (curFilters.start_date !== '') {

      const formattedStartDate = getFormattedDatetimeFromStartToEndDay(curFilters.start_date, 'start')
      searchCondition.push(`start_date=${formattedStartDate}`)
    }
    if (curFilters.end_date !== '') {
      const formattedEndDate = getFormattedDatetimeFromStartToEndDay(curFilters.end_date, 'end')
      searchCondition.push(`end_date=${formattedEndDate}`)
    }

    if (searchCondition.length > 0) {
      searchCondition.map((item: string, index: number) => {
        console.log('index', index)
        searchUrl = searchUrl + `${index == 0 ? '?' : '&'}${item}`
      })
      // console.log('searchUrl', searchUrl)
      let url = ``
      if (searchUrl) {
        url = `${searchUrl}`
      }

      try {
        setIsGlobalLoading(true)
        const result = await AlertAPI.searchAlert(url)
        setTableData(result)
        setIsGlobalLoading(false)
      } catch (error) {
        setTableData([])
        setIsGlobalLoading(false)
        console.log(' handle search fail', error)

      }


    } else {
      getTableData()
    }
  }

  return (
    <>

      {/* <div style={{ marginBottom: '20px' }}>
        <DisplayStats data={alertCards} />
      </div> */}
      <div style={{ marginBottom: '20px' }}>
        <Analytics items={analyticsInfo} />
      </div>

      <FlexWrapper column={!screen.md} alignStart={!screen.md} flexStart gap='10px' style={{ marginBottom: '2rem' }}>
        <Input
          placeholder="Search by Alert Rule"
          name="search"
          type="search"
          value={filters.alert_name}
          onChange={(e: any) => {
            console.log('e', e.target.value)
            onSelectType(e.target.value, 'search')
          }}
          allowClear
          onSearch={() => handleSearch()}
        />
        <Input placeholder="Sensor Type" name="" type="daterange"
          onChange={(date: any, datestring: string) =>
            handleDateChange(datestring)
          }
          allowClear
        />
        <Input
          placeholder="Status"
          name=""
          type="select"
          options={renderOption('bothstatus')}
          onChange={(value: string) => onSelectType(value, 'status')}
          value={filters.status}
        />
      </FlexWrapper>


      {!isLoading && Array.isArray(tableData) && tableData.length == 0 ? (
        <>
          <DisplayEmptyTextPrimary style={{ marginTop: '200px' }}>
            No Alerts  found for your Assets/Devices
            <DisplaySecondaryText>
              Please create and manage Asset maintenance schedules from Master Data &gt; Alert Preset
            </DisplaySecondaryText>
          </DisplayEmptyTextPrimary>
        </>
      ) : (
        <>

          <AdiTable
            loading={isLoading}
            tableData={filteredEventList}
            columns={columns}
          />
        </>

      )}

      <ResolveModal
        modalData={modalData}
        setModalData={setModalData}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        getTableData={getTableData}
      />

      <AlertMapModal
        isModalMapOpen={isModalMapOpen}
        setIsModalMapOpen={setIsModalMapOpen}
        currentRecord={currentRecord}

      />

    </>
  );
};

export default Alerts;

